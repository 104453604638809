import './MetricDisplay.scss';

import React, { ReactNode } from 'react';

interface MetricDisplayProps {
  title: string,
  subtitle: string,
  metricSelectionOptions?: ReactNode,
  metricDisplay: ReactNode,
  metricButtons: ReactNode,
};

function MetricDisplay({ title, subtitle, metricSelectionOptions = null, metricDisplay, metricButtons } : Readonly<MetricDisplayProps>) {
  return (
    <section className={'metric-display'}>
      <section className={'metric-display-content'}>
        <header className={'metric-display-header'}>
          <h2 className={'title'}>{title}</h2>
          <h3 className={'subtitle'}>{subtitle}</h3>
        </header>
        {metricSelectionOptions}
        {metricDisplay}
      </section>
      <footer className={'metric-display-footer'}>
        {metricButtons}
      </footer>
    </section>
  )
};

export default MetricDisplay;