import './CreateSegmentPage.scss';

import { useState } from 'react';
import fastCartesian from 'fast-cartesian';

import SpendMetricDisplay from '../displays/SpendMetricDisplay';
import FrequencyMetricDisplay from '../displays/FrequencyMetricDisplay';
import CustomMetricDisplay from '../displays/CustomMetricDisplay';
import { MetricType, CustomMetricOption, Metric, DEFAULT_METRICS, Segment } from '../SegmentsConstants';
import SegmentsDisplay from '../displays/SegmentsDisplay';
import { useNavigate } from 'react-router';
import { OptionType } from '../../../common/dropdowns/DropdownConstants';
import { useLocalStorage, LocalStorageKey } from '../../../../hooks/LocalStorageHooks';
import useAuth from '../../../../context/AuthContext';

const { LastVisitDate } = CustomMetricOption;
const { SPEND, FREQUENCY, CUSTOM, VIEW_ALL } = MetricType;

const EMPTY_CUSTOM_METRIC: Metric = {
  metricName: '',
  type: CUSTOM,
  metricOption: LastVisitDate,
  customMetric: null,
  bounds: [],
  skipped: false,
};

function generateCategories(metrics: Metric[]): OptionType<any>[] {
  const allBounds = metrics
    .filter(({ skipped }) => !skipped)
    .map(({ bounds }) => bounds.filter(({ boundName }) => boundName !== '').map(({ boundName }) => boundName))
    .filter(bounds => bounds.length > 0);
  const options = fastCartesian(allBounds).map((bounds) => ({ displayText: bounds.join(', '), value: bounds }));
  return options;
};

function CreateSegmentPage() {
  const navigate = useNavigate();
  const [cursor, setCursor] = useState(0);
  const { user } = useAuth();
  const userSegments = user && user.userSegment && user.userSegment.segments ? user.userSegment.segments : [];
  const userMetrics = user && user.userSegment && user.userSegment.metrics ? user.userSegment.metrics : DEFAULT_METRICS;
  const [metrics, setMetrics] = useLocalStorage<Metric[]>(LocalStorageKey.CreateSegmentMetricsKey, userMetrics);
  const [segments, setSegments] = useLocalStorage<Segment[]>(LocalStorageKey.CreateSegmentsKey, userSegments);
  const nextCursor = (skipped: boolean) => {
    const newMetrics = [...metrics];
    newMetrics[cursor] = { ...metrics[cursor], skipped };
    if (cursor >= metrics.length - 1) {
      setSegments([]);
    }
    setMetrics(newMetrics);
    setCursor(cursor + 1);
  }
  const next = () => nextCursor(false);
  const skip = () => nextCursor(true);
  const back = () => {
    if (cursor - 1 < 0) {
      navigate('/people/segments')
    }
    else {
      setCursor(cursor - 1);
    }
  }
  const addNewMetric = () => {
    setMetrics([...metrics, { ...EMPTY_CUSTOM_METRIC }]);
  }
  const metricType = cursor >= metrics.length ? VIEW_ALL : metrics[cursor].type;
  const setMetric = (index: number) => (metric: Metric) => {
    setMetrics(metrics.map((oldMetric, i) => i === index ? metric : oldMetric));
  }
  let display;
  if (metricType === SPEND) {
    display = <SpendMetricDisplay next={next} skip={skip} back={back} metric={metrics[cursor]} setMetric={setMetric(cursor)} />;
  }
  else if (metricType === FREQUENCY) {
    const canSkip = metrics.filter(({ skipped, type }) => type !== FREQUENCY && !skipped).length > 0;
    display = <FrequencyMetricDisplay next={next} skip={skip} addNewMetric={addNewMetric} back={back} canSkip={canSkip} metric={metrics[cursor]} setMetric={setMetric(cursor)} />;
  }
  else if (metricType === CUSTOM) {
    const { metricName, customMetric, bounds } = metrics[cursor];
    display = <CustomMetricDisplay
      addNewMetric={addNewMetric}
      next={next}
      back={back}
      metricName={metricName}
      customMetric={customMetric}
      bounds={bounds}
    />;
  }
  else if (metricType === VIEW_ALL) {
    display = <SegmentsDisplay back={back} categories={generateCategories(metrics)} setSegments={setSegments} segments={segments} metrics={metrics} />;
  }
  return (
    <article className={'create-segment-page'}>
      { display }
    </article>
  );
}

export default CreateSegmentPage;