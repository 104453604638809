import './EngagementResultsPage.scss';

import React, { useState } from 'react';

import { EngagementStatus } from '../EngagementsTypes';
import Dropdown from '../../common/dropdowns/Dropdown';
import EngagementResultsTable from '../tables/EngagementResultsTable';

const { Draft, Scheduled, Active, Complete, All, Archived } = EngagementStatus;


// TOOD: Turn into using an enum
const ENGAGEMENT_FILTER_OPTIONS = [
  {
    displayText: 'All engagements',
    value: All,
  },
  {
    displayText: 'Draft',
    value: Draft,
  },
  {
    displayText: 'Scheduled',
    value: Scheduled,
  },
  {
    displayText: 'Complete',
    value: Complete,
  },
  {
    displayText: 'Active',
    value: Active,
  },
  {
    displayText: 'Archived',
    value: Archived,
  },
];

function EngagementResultsPage() {
  const [selectedFilter, setSelectedFilter] = useState(ENGAGEMENT_FILTER_OPTIONS[0].value);
  return (
    <article className={'engagement-results-page'}>
      <header className={'engagement-results-page-header'}>
        <h2 className={'title'}>{'Results'}</h2>
        <h3 className={'subtitle'}>{'View all the results and impacts of your engagements'}</h3>
        <section className={'engagement-results-buttons'}>
          <Dropdown
            options={ENGAGEMENT_FILTER_OPTIONS}
            selected={selectedFilter}
            select={setSelectedFilter}
            additionalClassNames={['engagements-filter']}
          />
        </section>
      </header>
      <section className={'engagement-results-page-content'}>
        <EngagementResultsTable selectedStatus={selectedFilter} />
      </section>
    </article>
  );
}

export default EngagementResultsPage;