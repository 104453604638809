import { useEvent, useFlatfile, usePlugin, Workbook } from "@flatfile/react";

import PrimaryPlusButton from "../../../common/buttons/composite/PrimaryPlusButton";
import { RecipientFlatFileComponentProps, recipientWorkbook } from "../../../../utils/flatfile/FlatFileConstants";
import { recipientSubmitListener, recipientRecordFormattingListener } from "../../../../utils/flatfile/FlatFileHelpers";
import { recordHook } from "@flatfile/plugin-record-hook";
import { getRecipients, getRecipientsWithFilters } from "../../../../apis/PeopleApi";

function RecipientFlatFileButton({ setRecipients } : RecipientFlatFileComponentProps) {
  const { openPortal } = useFlatfile();

  usePlugin(
    recordHook(
      'recipients',
      recipientRecordFormattingListener
    ),
    ['status']
  )

  // Event hook for submitting recipients
  useEvent('job:ready',
    { job: 'workbook:submitRecipients' },
    async (event) => {
      await recipientSubmitListener(event);
      // setRecipients(null);
      getRecipients()
        .then(response => {
          if (response) {
            console.log(response);
            const { recipients } = response;
            setRecipients(recipients);
          } else {
            setRecipients([]);
          }
        })
        .catch(err => {
          setRecipients([]);
          console.log(err);
        })
    }
  );

  return (
    <>
      <PrimaryPlusButton content={'Bulk import'} onClick={openPortal} />
      <Workbook config={recipientWorkbook} />
    </>
  )
};

export default RecipientFlatFileButton;