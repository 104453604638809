import './OffersPage.scss';

import { useLocation, useNavigate } from 'react-router';

import OffersTable from '../tables/OffersTable';
import OutlineButton from '../../common/buttons/OutlineButton';

function OffersPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { originUrl } = state || {};
  const hasBackButton = originUrl !== undefined;
  return (
    <article className={'offers-page'}>
      <header className={'offers-page-header'}>
        <h2 className={'title'}>{'Offers'}</h2>
        <h3 className={'subtitle'}>
          {'Add, edit, and view your available engagement offers'}
          { hasBackButton && <OutlineButton content={'Back'} onClick={() => navigate(originUrl)} /> }
        </h3>
      </header>
      <section className={'offers-page-content'}>
        <OffersTable />
      </section>
    </article>
  );
}

export default OffersPage;