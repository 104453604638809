import './HandwrittenLettersPage.scss';

import { ReactElement, useState } from 'react';
import AutoComplete from 'react-google-autocomplete';
import { useNavigate } from 'react-router';

import { postCaseStudyRequest } from '../../apis/CaseStudyApi';
import Dropdown from '../common/dropdowns/Dropdown';
import PrimaryButton from '../common/buttons/PrimaryButton';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBN79DWVz6nD-htQLf37KfKv3Bnd4cbkxk';

export enum HandwrittenLetterSendingOption {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

const HandwrittenLetterSendingOptions = [
  {
    displayText: '100 letters',
    value: HandwrittenLetterSendingOption.Low,
  },
  {
    displayText: '200 letters',
    value: HandwrittenLetterSendingOption.Medium,
  },
  {
    displayText: '400 letters',
    value: HandwrittenLetterSendingOption.High,
  },
]

interface GoogleAddressObject {
  formatted_address: string,
}


function HandwrittenLettersPage(): ReactElement {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sendingOption, setSendingOption] = useState<HandwrittenLetterSendingOption | null>(null);
  const [place, setPlace] = useState('');
  const requestLetters = async () => {
    setLoading(true);
    const firstName = 'Lance';
    const businessName = 'WorkRex';
    const message = `${firstName} from ${businessName} requested Handwritten Letters for ${place} for the ${sendingOption} option.`;
    await postCaseStudyRequest(message);
    setLoading(false);
    navigate('/case-studies/home');
  }
  const onPlaceSelected = (place: GoogleAddressObject) => {
    setPlace(place.formatted_address);
  }
  const disabled = sendingOption === null || place.length === 0;
  return (
    <article className={'handwritten-letters-page'}>
      <section className={'page'}>
        <header className={'handwritten-letters-page-header'}>
          <h2 className={'title'}>{'Handwritten letters'}</h2>
          <h3 className={'subtitle'}>{'Request handwritten letters for lead generation today.'}</h3>
        </header>
        <section className={'page-content'}>
          <iframe
            className={'preview-iframe'}
            src={'https://www.youtube.com/embed/ZtYaqKAc5mA?si=ifgER9mx0h8w_7iO'}
            title={'Send handwritten letters to new residents near you'}
            allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
            referrerPolicy={'strict-origin-when-cross-origin'}
            allowFullScreen
          />
          <section className={'option-selection'}>
            <span className={'label'}>{'Select an area to dispatch letters'}</span>
            <AutoComplete apiKey={GOOGLE_MAPS_API_KEY} onPlaceSelected={onPlaceSelected} />
          </section>
          <section className={'option-selection'}>
            <span className={'label'}>{'Roughly 100 letters translates to 3 to 5 new clients a month, 200 translates to 6 to 10, etc.'}</span>
            <Dropdown
              options={HandwrittenLetterSendingOptions}
              defaultDisplayText={'Select number of letters to send'}
              select={setSendingOption}
              selected={sendingOption}
            />
          </section>
          <PrimaryButton additionalClassNames={['cta-button']} content={'Request letters'} disabled={disabled} onClick={requestLetters} loading={loading} />
        </section>
      </section>
    </article>
  );
}

export default HandwrittenLettersPage;