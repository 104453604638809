import './AddUpdateRecipientModal.scss';

import React, { useState } from 'react';

import Modal from '../../../common/modals/Modal';
import PrimaryInput from '../../../common/inputs/PrimaryInput';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import OutlineButton from '../../../common/buttons/OutlineButton';
import Dropdown from '../../../common/dropdowns/Dropdown';
import MultiselectDropdown from '../../../common/dropdowns/MultiselectDropdown';
import ModalField from './ModalField';

import { DeliverabilityOptions, DeliverabilityPreferenceOptions, Recipient, DeliverabilityPreferences } from '../../PeopleConstants';

// Need recipientId to know which recipient to update.
interface AddUpdateRecipientModalProps {
  close: () => void,
  recipientToInsertUpdate: Recipient,
  confirm: (recipient: Recipient) => void,
}

function AddUpdateRecipientModal({ close, recipientToInsertUpdate, confirm } : Readonly<AddUpdateRecipientModalProps>) {
  const [recipient, setRecipient] = useState<Recipient>(recipientToInsertUpdate);
  const { firstName, lastName, mobileNumber, emailAddress, deliverability, preferences } = recipient;
  const updateRecipient = (fieldName: string) => (fieldValue: string) => setRecipient({ ...recipient, [fieldName]: fieldValue });
  const selectPreferences = (preferences: DeliverabilityPreferences[]) => {
    setRecipient({ ...recipient, preferences })
  };
  const confirmChanges = () => {
    close();
    confirm(recipient);
  }

  const preventSubmit = !firstName || !lastName || !mobileNumber || !emailAddress || !deliverability;
  return (
    <Modal additionalClassNames={['add-recipient-modal']}>
      <header className={'modal-header'}>
        <h4 className={'modal-title'}>
          {'Add contacts'}
        </h4>
        <h5 className={'modal-subtitle'}>
          {'Add the details for new contacts.'}
        </h5>
      </header>
      <section className={'modal-body'}>
        <section className={'interaction-container'}>
          <ModalField label={'First name'}>
            <PrimaryInput id={'firstName'} placeholder={'First name'} text={firstName} setText={updateRecipient('firstName')} />
          </ModalField>
          <ModalField label={'Last name'}>
            <PrimaryInput id={'lastName'} placeholder={'Last name'} text={lastName} setText={updateRecipient('lastName')} />
          </ModalField>
          <ModalField label={'Mobile number'}>
            <PrimaryInput id={'mobileNumber'} placeholder={'Mobile number'} text={mobileNumber} setText={updateRecipient('mobileNumber')} />
          </ModalField>
          <ModalField label={'Email address'}>
            <PrimaryInput id={'emailAddress'} placeholder={'Email address'} text={emailAddress} setText={updateRecipient('emailAddress')} />
          </ModalField>
          <ModalField label={'Deliverability'}>
            <Dropdown options={DeliverabilityOptions} defaultDisplayText={'Deliverability'} selected={deliverability} select={updateRecipient('deliverability')} />
          </ModalField>
          <ModalField label={'Preferences'}>
            <MultiselectDropdown options={DeliverabilityPreferenceOptions} defaultDisplayText={'Preferences'} selected={preferences} select={selectPreferences} />
          </ModalField>
        </section>
      </section>
      <section className={'modal-buttons'}>
        <OutlineButton content={'Cancel'} onClick={close} />
        <PrimaryButton content={'Confirm'} onClick={confirmChanges} disabled={preventSubmit} />
      </section>
    </Modal>
  );
}

export default AddUpdateRecipientModal