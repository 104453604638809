import './GenericSwitch.scss';

import { useId } from 'react';

import { GenericSwitchProps } from './SwitchConstants';

function GenericSwitch({ checked, setChecked, label, id = '', additionalClassNames = [] } : Readonly<GenericSwitchProps>) {
  const onChange = () => setChecked(!checked);
  const uuid = useId();
  const uniqueId = `${id}-${uuid}`;
  const classNames = ['generic-switch', ...additionalClassNames].join(' ');
  return (
    <section className={classNames}>
      <label className={'switch'} htmlFor={uniqueId}>
        <input id={uniqueId} type={'checkbox'} onChange={onChange} checked={checked} />
        <span className={'slider round'}></span>
      </label>
     { label && <span className={'label-text'}>{label}</span> }
    </section>
  )
}

export default GenericSwitch;