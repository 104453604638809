import './ConfirmationModalContent.scss';

import { ReactNode } from 'react';

import OutlineButton from '../buttons/OutlineButton';
import PrimaryButton from '../buttons/PrimaryButton';

interface ConfirmationModalContentProps {
  title: string,
  subtitle: ReactNode,
  cancel?: (() => void) | null,
  confirm?: (() => void) | null,
  confirmText?: string,
  cancelText?: string,
  loading?: boolean,
  children?: ReactNode,
};

function ConfirmationModalContent({ title, subtitle, cancel = null, confirm = null, confirmText = 'Confirm', loading = false, cancelText = 'Cancel', children = null } : Readonly<ConfirmationModalContentProps>) {
  return (
    <section className={'confirmation-modal-content'}>
      <h4 className={'title'}>{title}</h4>
      <h5 className={'subtitle'}>{subtitle}</h5>
      {children}
      <section className={'buttons'}>
        { cancel !== null && <OutlineButton content={cancelText} onClick={cancel} /> }
        { confirm !== null && <PrimaryButton content={confirmText} onClick={confirm} loading={loading} /> }
      </section>
    </section>
  )
}

export default ConfirmationModalContent;