import './ServiceAIMappingDisplay.scss';

import { useState } from 'react';

import PaginationBar from '../../common/tables/PaginationBar';

import useAuth from '../../../context/AuthContext';
import InteractionToServicesItem from '../common/InteractionToServicesItem';
import { postUpdateInteractionWithServices } from '../../../apis/PeopleApi';

const AI_MAP_MAX_ROWS = 3;

function ServicesAIMappingDisplay() {
  const [cursor, setCursor] = useState(0);
  const { user } = useAuth();

  // TODO: Replace with a data fetch for interactions
  // TODO: Add firstName, lastName to interaction
  const allInteractions = user && user.interactions ? user.interactions : [];
  const allRecipients = user && user.recipients ? user.recipients : [];
  const allAppointmentsOrTransactions = allInteractions.filter(({ interactionType }) => interactionType === 'APPOINTMENT' || interactionType === 'TRANSACTION');
  // TODO: modify to use actual interactions
  const [interactions, setInteractions] = useState<any[]>(allAppointmentsOrTransactions);
  const interactionList = interactions
    .slice(cursor, cursor + AI_MAP_MAX_ROWS)
    .map((interaction) => {
      const customer = allRecipients.find((recipient) => recipient._id === interaction.recipientId);
      const fullName = customer ? `${customer.firstName} ${customer.lastName}` : 'Unknown';
      return { ...interaction, customerName: fullName }
    })
    .map(({ _id, interactionType, customerName, interactionDetails, scheduledDate, transactionValue, transactionCurrency, services }, index) => {
      const setServices = (services: string[]) => {
        const interaction = interactions[cursor + index];
        const newInteractions = [...interactions];
        newInteractions[cursor + index] = { ...interaction, services };
        setInteractions(newInteractions);
        postUpdateInteractionWithServices(_id, services);
      }
      return (
        <InteractionToServicesItem
          type={interactionType}
          customerName={customerName}
          interactionDetails={interactionDetails}
          scheduledDate={scheduledDate}
          transactionValue={transactionValue}
          transactionCurrency={transactionCurrency}
          services={services}
          setServices={setServices}
          key={`interaction-to-service-item-${_id}`}
        />
      );
    });
  return (
    <section className={'services-ai-mapping-display'}>
      <header className={'ai-mapping-header'}>
        <h4 className={'ai-mapping-title'}>{'AI Mapping'}</h4>
        <h5 className={'ai-mapping-subtitle'}>{'Tag your most recent interactions. Tagging the relevant products to your interactions helps us improve accuracy in our recommendations.'}</h5>
      </header>
      <section className={'interaction-to-service-list'}>
        {interactionList}
      </section>
      <section className={'pagination-bar-container'}>
        <PaginationBar
          cursor={cursor}
          setCursor={setCursor}
          maxRowsPerPage={AI_MAP_MAX_ROWS}
          totalRows={allAppointmentsOrTransactions.length}
        />
      </section>
    </section>
  )
};

export default ServicesAIMappingDisplay;