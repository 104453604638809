import './RecipientsTable.scss';

import { useState } from 'react';

import useAuth from '../../../../context/AuthContext';
import { useNavigate } from 'react-router';

import { postDeleteRecipient, postSaveOrUpdateInteraction } from '../../../../apis/PeopleApi';
import { DEFAULT_INTERACTION, DELIVERABILITY, DeliverabilityPreferenceOptions, Interaction, ModalAction } from '../../PeopleConstants';

import PaginationBar from '../../../common/tables/PaginationBar';
import ConfirmRecipientDeletionModal from '../modals/ConfirmRecipientDeletionModal';
import StatusTagManager from '../common/StatusTagManager';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import AddUpdateInteractionModal from '../modals/AddUpdateInteractionModal';
import Table, { TableHeaderProps } from '../../../common/tables/Table';
import { RecipientRow } from '../../../engagements/modals/add-recipients/RecipientSelectionTable';
import { useMediaQuery } from '../../../../hooks/MediaHooks';

const SEGMENTS_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Client ID', key: 'clientId' },
  { displayText: 'First name', key: 'firstName' },
  { displayText: 'Last name', key: 'lastName' },
  { displayText: 'Mobile number', key: 'mobileNumber' },
  { displayText: 'Email', key: 'emailAddress' },
  { displayText: 'Segments', key: 'segments' },
  { displayText: 'Status', key: 'status' },
  { displayText: 'Deliverability', key: 'deliverability' },
  { displayText: 'Preferences', key: 'preferences' },
  { displayText: 'Added Date', key: 'addedDate' },
  { displayText: 'Add Interaction', key: 'addInteraction' },
  { displayText: 'Delete', key: 'delete' },
];

const RECIPIENTS_MAX_ROWS = 5;
const RECIPIENTS_SMALL_SCREEN_MAX_ROWS = 3;

interface RecipientsTableProps {
  recipients: RecipientRow[],
  setFilteredRecipients: (recipients: RecipientRow[]) => void,
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  searchText: string,
}

const { Create, Delete } = ModalAction;

function RecipientsTable({ recipients = [], setFilteredRecipients, page, setPage, searchText } : RecipientsTableProps) {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const isTablet = useMediaQuery('(max-height: 720px)');
  const [selectedRecipientId, setSelectedRecipientId] = useState<string | null>(null);
  const [modalAction, setModalAction] = useState<ModalAction | null>(null);

  const rows = recipients
    .filter(({ firstName, lastName }) => {
      const name = `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}`;
      return name.toLowerCase().includes(searchText.toLowerCase());
    })
    .map((recipient, index) => {
      const { _id, clientId, firstName, lastName, mobileNumber, emailAddress, status, deliverability, preferences, addedDate } = recipient;
      const viewRecipient = () => navigate('/people/person', { state: { recipient, origin: '/people' } });
      const clientIdNode = <section className={'two-cell short-text'} onClick={viewRecipient}>{clientId}</section>
      const firstNameNode = <section className={'two-cell short-text'} onClick={viewRecipient}>{firstName}</section>
      const lastNameNode = <section className={'two-cell short-text'} onClick={viewRecipient}>{lastName}</section>
      const mobileNumberNode = <section className={'two-cell short-text'} onClick={viewRecipient}>{mobileNumber}</section>
      const emailAddressNode = <section className={'two-cell short-text'} onClick={viewRecipient}>{emailAddress}</section>
      const segmentNode = <section className={'two-cell short-text'} onClick={viewRecipient}>{''}</section>
      const statusNode = <section className={'one-cell short-text'} onClick={viewRecipient}><StatusTagManager status={status} /></section>
      const deliverabilityNode = <section className={'two-cell short-text'} onClick={viewRecipient}>{DELIVERABILITY[deliverability].displayText}</section>

      const preferencesText = DeliverabilityPreferenceOptions
        .filter(({ value }) => preferences.includes(value))
        .map(({ displayText }) => displayText)
        .join(', ');
      const preferencesNode = <section className={'two-cell short-text'} onClick={viewRecipient}>{preferencesText}</section>

      const formattedAddedDate = addedDate === null ? 'None' : new Date(addedDate).toLocaleDateString('en-au', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      const addedDateNode = <section className={'two-cell short-text'} onClick={viewRecipient}>{formattedAddedDate}</section>

      const openModal = (modalAction: ModalAction) => {
        console.log(_id);
        setSelectedRecipientId(_id);
        setModalAction(modalAction);
      }
      const addInteractionNode = <PrimaryButton onClick={() => openModal(Create)} content={'Add Interaction'} additionalClassNames={['button-cell']} />;
      const deleteNode = <TrashIconButton onClick={() => openModal(Delete)} additionalClassNames={['one-cell']} />;
      
      return {
        cells: [
          { key: 'clientId', value: clientIdNode },
          { key: 'firstName', value: firstNameNode },
          { key: 'lastName', value: lastNameNode },
          { key: 'mobileNumber', value: mobileNumberNode },
          { key: 'emailAddress', value: emailAddressNode },
          { key: 'segments', value: segmentNode },
          { key: 'status', value: statusNode },
          { key: 'deliverability', value: deliverabilityNode },
          { key: 'preferences', value: preferencesNode },
          { key: 'addedDate', value: addedDateNode },
          { key: 'addInteraction', value: addInteractionNode },
          { key: 'delete', value: deleteNode },
        ]
      }
    });

  console.log(rows);
  if (rows === null || rows === undefined || rows.length === 0) {
    return null;
  }

  // Display Text Modal for editing recipient fields.
  const modalOpen = selectedRecipientId !== null;

  // Delete Recipient Modal
  const deleteRecipientModalOpen = modalOpen && modalAction === Delete;
  const closeConfirmationModal = () => setSelectedRecipientId(null);
  const confirmDeleteRecipient = () => {
    if (selectedRecipientId !== null) {
      // TODO: Replace with backend delete recipient call.
      postDeleteRecipient(selectedRecipientId)
        .then(response => {
          console.log(response);
          const { success } = response;
          if (success && user !== null) {
            const updatedRecipients = recipients.filter(({ _id }) => _id !== selectedRecipientId);
            setUser({ ...user, recipients: updatedRecipients });
            setFilteredRecipients(updatedRecipients);
          }
        })
    }
    closeConfirmationModal();
  };

  // Create Interaction Modal
  const createInteractionModalOpen = modalOpen && modalAction === Create;
  const confirmCreateInteraction = async (interaction: Interaction) => {
    if (selectedRecipientId !== null) {
      const newInteraction = { ...interaction, recipientId: selectedRecipientId };
      const { success, recipient } = await postSaveOrUpdateInteraction(newInteraction);

      if (success && recipient !== null && user !== null) {
        const userRecipients = user.recipients;
        const recipientIndex = userRecipients.findIndex(userRecipient => userRecipient._id === selectedRecipientId);
        userRecipients[recipientIndex] = recipient;
        setUser({ ...user, recipients: userRecipients })
      }
    }
    closeConfirmationModal();
  };

  const totalRows = rows ? rows.length : 0;
  const maxRowsToDisplay = isTablet ? RECIPIENTS_SMALL_SCREEN_MAX_ROWS : RECIPIENTS_MAX_ROWS;
  return (
    <>
      { deleteRecipientModalOpen && <ConfirmRecipientDeletionModal cancel={closeConfirmationModal} confirm={confirmDeleteRecipient} /> }
      { createInteractionModalOpen && (
        <AddUpdateInteractionModal
          close={closeConfirmationModal}
          interactionToInsertUpdate={DEFAULT_INTERACTION}
          confirm={confirmCreateInteraction}
        />
      )}
      <section className={'recipients-table-container'}>
        <Table
          additionalClassNames={['recipients-table']}
          headers={SEGMENTS_TABLE_HEADERS}
          rows={rows}
          cursor={page}
          maxRows={maxRowsToDisplay}
        />
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={page}
            setCursor={setPage}
            maxRowsPerPage={maxRowsToDisplay}
            totalRows={totalRows}
          />
        </section>
      </section>
    </>
  )
}

export default RecipientsTable;