import Dropdown from '../../../common/dropdowns/Dropdown';
import MetricDisplay from './MetricDisplay';
import MetricTable from '../tables/MetricTable';
import { MetricAttributeType, MetricSegment, Metric, SpendOption } from '../SegmentsConstants';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import OutlineButton from '../../../common/buttons/OutlineButton';
import SubtleButton from '../../../common/buttons/SubtleButton';
import { hasValidBoundNames, hasValidBounds } from '../SegmentsHelper';

const { DoNotSegment, AverageAnnualSpend, AveragePurchaseValue, LifetimeSpend } = SpendOption;

const SPEND_OPTIONS = [
  { displayText: DoNotSegment, value: DoNotSegment },
  { displayText: AverageAnnualSpend, value: AverageAnnualSpend },
  { displayText: AveragePurchaseValue, value: AveragePurchaseValue },
  { displayText: LifetimeSpend, value: LifetimeSpend },
];

interface SpendMetricDisplayProps {
  next: () => void,
  skip: () => void,
  back: () => void,
  metric: Metric,
  setMetric: (metric: Metric) => void,
};

function SpendMetricDisplay({ next, skip, back, metric, setMetric } : Readonly<SpendMetricDisplayProps> ) {
  const { metricOption } = metric;
  const rows = metric.bounds;
  const setSpendOption = (option: SpendOption) => setMetric({ ...metric, metricOption: option });
  const setRows = (rows: MetricSegment[]) => setMetric({ ...metric, bounds: rows });
  const metricSelectionOptions = (
    <section className={'metric-selection-options'}>
      <label className={'label'}>{'Choose a spend metric'}</label>
      <Dropdown defaultDisplayText={'Spend segment'} options={SPEND_OPTIONS} select={setSpendOption} selected={metricOption as SpendOption | null} />
    </section>
  );
  const hasValidSpendBounds = hasValidBounds(rows) && hasValidBoundNames(rows);
  const disabled = rows.length <= 1 || !hasValidSpendBounds;
  const metricDisplay = <MetricTable segments={rows} setSegments={setRows} metricAttributeType={MetricAttributeType.Dollar} />;
  const metricButtons = (
    <section className={'metric-buttons'}>
      <OutlineButton content={'Back'} onClick={back} />
      <SubtleButton content={'Skip'} onClick={skip} />
      <PrimaryButton content={'Next'} onClick={next} disabled={disabled}/>
    </section>
  )
  return (
    <MetricDisplay
      title={'Create a new segment'}
      subtitle={'Define the spend boundaries for your customers in this segment'}
      metricSelectionOptions={metricSelectionOptions}
      metricDisplay={metricDisplay}
      metricButtons={metricButtons}
    />
  );
};

export default SpendMetricDisplay;