import './ServicesTable.scss';

import { useState } from 'react';

import Table, { TableHeaderProps } from '../../common/tables/Table';
import Dropdown from '../../common/dropdowns/Dropdown';
import PrimaryPlusButton from '../../common/buttons/composite/PrimaryPlusButton';
import { TextToDisplay } from '../../engagements/EngagementsConstants';
import EditTextModal from '../../common/modals/EditTextModal';
import PaginationBar from '../../common/tables/PaginationBar';
import EllipsisDropdown from '../../common/dropdowns/EllipsisDropdown';
import { Service, ServiceType, ServiceTypeOptions } from '../SettingsConstants';
import useAuth from '../../../context/AuthContext';
import { User } from '../../../types/UserTypes';
import { postSaveOrUpdateService, postDeleteService } from '../../../apis/SettingsApi';

import Tooltip from '../../common/tooltips/Tooltip';

import INFO_ICON from '../../people/segments/assets/information-icon.svg';
import CUSTOMIZE_ICON from '../../people/segments/assets/customize-icon.svg';


const SERVICES_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Service name', key: 'name' },
  { displayText: 'Type', key: 'type' },
  {
    displayText: (
      <span className={'cell-text'}>
        <span className={'cell-content'}>
          <img className={'column-icon'} src={CUSTOMIZE_ICON} alt={''} />
          {'Customise context'}
        </span>
        <Tooltip content={'Provide AI with more context on how to personalise recommendations for your services or products in your campaigns'}><img className={'info-icon'} src={INFO_ICON} alt={''} /></Tooltip>
      </span>
    ),
    key: 'customise'
  },
  {
    displayText: (
      <span className={'cell-text'}>
        <span className={'cell-content'}>
        <img className={'column-icon'} src={CUSTOMIZE_ICON} alt={''} />
        {'AI recommendations'}
        </span>
        <Tooltip content={'WorkRex has detected these service bundle opportunities from your database. You can copy and paste this to ‘Customise context’.'}><img className={'info-icon'} src={INFO_ICON} alt={''} /></Tooltip>
      </span>
    ),
    key: 'recommendations'
  },
  { displayText: 'More', key: 'more' },
];

const EMPTY_SERVICE: Service = {
  _id: null,
  name: '',
  type: ServiceType.Service,
  customise: '',
  recommendations: '',
};

const SERVICES_MAX_ROWS = 5;

function ServicesTable() {
  const { user, setUser } = useAuth();
  const initialServices = user && user.services ? user.services : [];
  const [cursor, setCursor] = useState(0);
  const [modalLoading, setModalLoading] = useState(false);
  const [textToDisplay, setTextToDisplay] = useState<TextToDisplay | null>(null);
  const [services, setServices] = useState<Service[]>(initialServices);
  const [loadingAddService, setLoadingAddService] = useState(false);
  const setService = async (service: Service, index: number) => {
    const newServices = [...services];
    newServices[index] = service;
    setServices(newServices);
    const response = await postSaveOrUpdateService(service);
    setUser({ ...user as User, services: response.services });
    newServices[index] = { ...service, _id: response.updatedServiceId };
    setServices(newServices);
  }
  const setType = (index: number) => async (type: ServiceType) => {
    const service = services[index];
    await setService({ ...service, type }, index);
  };
  const addService = async () => {
    const newService = { ...EMPTY_SERVICE };
    setLoadingAddService(true);
    const response = await postSaveOrUpdateService(newService);
    setUser({ ...user as User, services: response.services });
    setServices([...services, { ...newService, _id: response.updatedServiceId }]);
    setLoadingAddService(false);
    setCursor(services.length - (services.length % SERVICES_MAX_ROWS));
  };
  const deleteService = async (index: number) => {
    const service = services[index];
    const serviceId = service._id;
    if (serviceId) {
      const services = await postDeleteService(serviceId);
      setUser({ ...user as User, services });
    }
    setServices(services.filter((_, i) => i !== index));
  };
  const displayText = (text: string, index: number, fieldName: string) => () => setTextToDisplay({ text, index, fieldName });
  const rows = services.map(({ name, type, customise, recommendations }, index) => {
    const moreOptions = [{ displayText: 'Delete', value: index, onClick: () => deleteService(index) }]
    const nameNode = <section className={'two-cell short-text'} onClick={displayText(name, index, 'name')}>{name}</section>;
    const typeNode = <Dropdown defaultDisplayText={'Select type'} options={ServiceTypeOptions} selected={type} select={setType(index)} additionalClassNames={['two-cell']} />;
    const customiseNode = <section className={'two-cell long-text'} onClick={displayText(customise, index, 'customise')}>{customise}</section>;
    const recommendationsNode = <section className={'two-cell long-text'} onClick={displayText(recommendations, index, 'recommendations')}>{recommendations}</section>;
    const moreNode = <EllipsisDropdown options={moreOptions} additionalClassNames={['half-cell']} />;
    return {
      cells: [
        { key: 'name', value: nameNode },
        { key: 'type', value: typeNode },
        { key: 'customise', value: customiseNode },
        { key: 'recommendations', value: recommendationsNode },
        { key: 'more', value: moreNode },
      ]
    }
  });
  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  const confirmTextChanges = async (text: string) => {
    if (textToDisplay) {
      const { index, fieldName } = textToDisplay;
      const service = services[index];
      const newService = { ...service, [fieldName]: text };
      setModalLoading(true);
      await setService(newService, index);
      setModalLoading(false);
    }
    closeTextToDisplayModal();
  }
  const modalText = textToDisplay ? textToDisplay.text : '';
  const tableContainerClass = ['services-table-container', services.length === 0 ? 'empty' : ''].join(' ');
  return (
    <>
      { showingText && <EditTextModal text={modalText} confirm={confirmTextChanges} close={closeTextToDisplayModal} loading={modalLoading} /> }
      <section className={tableContainerClass}>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['services-table']}
            headers={SERVICES_TABLE_HEADERS}
            rows={rows}
            cursor={cursor}
            maxRows={SERVICES_MAX_ROWS}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={SERVICES_MAX_ROWS}
            totalRows={rows.length}
          />
        </section>
        <PrimaryPlusButton content={'Add service'} additionalClassNames={['create-service-button']} onClick={addService} loading={loadingAddService} />
      </section>
    </>
  )
}

export default ServicesTable;

