import './TrackCompetitorOffersDisplay.scss';

import React, { useState } from 'react';

import AddCompetitorOffersModal from '../modals/AddCompetitorOffersModal';
import AddCompetitorOffersConfirmationModal from '../modals/AddCompetitorOffersConfirmationModal';
import PrimaryPlusButton from '../../common/buttons/composite/PrimaryPlusButton';
import CompetitorOffersTrackingTable from '../tables/CompetitorOffersTrackingTable';
import { DUMMY_TABLE_DATA, TrackedOffer } from '../CompetitorOffersConstants';

function TrackCompetitorOffersDisplay() {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [competitors, setCompetitors] = useState(DUMMY_TABLE_DATA);
  const confirm = (competitors: TrackedOffer[]) => {
    setConfirmationModalOpen(true);
    setCompetitors(competitors)
  }
  return (
    <section className={'track-competitor-offers-display'}>
      { modalOpen && <AddCompetitorOffersModal close={() => setModalOpen(false)} competitors={competitors} confirm={confirm} /> }
      { confirmationModalOpen && <AddCompetitorOffersConfirmationModal close={() => setConfirmationModalOpen(false)} /> }
      <header className={'display-header'}>
        <h4 className={'display-title'}>
          {'Track competitor offers'}
        </h4>
        <h5 className={'display-subtitle'}>
          {'Add up to 5 URLS from competitors for us to gather offers from. If offers are found they can be seen in the ‘view offers’ tab.'}
        </h5>
      </header>
      <PrimaryPlusButton content={'Add competitors'} onClick={() => setModalOpen(true)} additionalClassNames={['add-competitors-button']} />
      <CompetitorOffersTrackingTable rows={competitors} setRows={setCompetitors} openModal={() => setModalOpen(true)} />
    </section>
  );
};

export default TrackCompetitorOffersDisplay;