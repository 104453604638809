import './NavigationSidebar.scss';

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import Dropdown from '../../common/dropdowns/Dropdown';
import { OptionType } from '../../common/dropdowns/DropdownConstants';
import Sidebar from './Sidebar';
import { SIDEBAR_NAVIGATION_ITEMS, NavigationItemKey, SubNavigationItemKey, NavigationItem } from './NavigationSidebarConstants';
import AccordionButton from '../../common/buttons/AccordionButton';
import NavItemContent from './NavItemContent';
import NavSubItem from './NavSubItem';
import { User } from '../../../types/UserTypes';
import useAuth from '../../../context/AuthContext';
import { EngagementStatus } from '../../engagements/EngagementsTypes';

const { Active, Complete } = EngagementStatus;

interface NavigationSidebarProps {
  sidebarNavigationItems?: NavigationItem[];
  showSidebarHeader?: boolean;
}


const DUMMY_GROUPS : OptionType<string>[] = [
  { displayText: 'All groups', value: 'all' },
  { displayText: 'Cosmos Sydney', value: 'group1' },
  { displayText: 'Cosmos Melbourne', value: 'group2' },
  { displayText: 'Cosmos Brisbane', value: 'group3' },
];

const EngagementStatusesWithResults = [Active, Complete];

function getCounts(key: NavigationItemKey, user: User | null): number | null {
  switch (key) {
    case NavigationItemKey.ENGAGEMENTS:
      return user?.engagements.length || null
    case NavigationItemKey.PEOPLE:
      return user?.recipients.length || null;
    case NavigationItemKey.INBOX:
      return null;
    case NavigationItemKey.SETTINGS:
      return null;
    case NavigationItemKey.INTEGRATIONS:
      return null;
    default:
      return 0;
  }
}

function getSubCounts(key: SubNavigationItemKey, user: User | null): number | null {
  switch (key) {
    case SubNavigationItemKey.ALL_ENGAGEMENTS:
      return user?.engagements.length || null
    case SubNavigationItemKey.VIEW_RESULTS: {
      const activeEngagements = user?.engagements.filter(({ status }) => EngagementStatusesWithResults.includes(status)) || []
      return activeEngagements.length;
    }
    case SubNavigationItemKey.SEGMENTS:
      // TODO
      return null;
    case SubNavigationItemKey.ALL_PEOPLE:
      return user?.recipients.length || null;
    case SubNavigationItemKey.SERVICES:
      return user?.services.length || null;
    case SubNavigationItemKey.OFFERS:
      return user?.offers.length || null;
    case SubNavigationItemKey.COMPETITORS:
      // TODO
      return null;
    case SubNavigationItemKey.LEADS:
      return 2;
    case SubNavigationItemKey.CLIENTS:
      return 1;
    default:
      return null;
  }
}


function NavigationSidebar({ sidebarNavigationItems = SIDEBAR_NAVIGATION_ITEMS, showSidebarHeader = true } : Readonly<NavigationSidebarProps>) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const selectedNavOption = sidebarNavigationItems.find(({ prefix }) => pathname.startsWith(prefix));
  const selectedSubNavItem = selectedNavOption ? selectedNavOption.subItems.find(({ href }) => pathname === href) : null;
  const selectedNavKey = selectedNavOption ? selectedNavOption.key : null;
  const selectedSubNavKey = selectedSubNavItem ? selectedSubNavItem.key : null;
  const [selectedGroup, setSelectedGroup] = useState(DUMMY_GROUPS[0].value);  
  const navItems = sidebarNavigationItems.map(({ key, displayText, icon, selectedIcon, href, subItems }) => {
    const onClick = () => {
      navigate(href);
    }
    const count = getCounts(key, user);
    const selected = selectedNavKey === key;
    const buttonContent = <NavItemContent icon={icon} selectedIcon={selectedIcon} text={displayText} selected={selected} count={count} />;
    const navSubItems = subItems.map(({ key, displayText, href }) => {
      const onClick = () => {
        navigate(href);
      }
      const count = getSubCounts(key, user);
      const selected = selectedSubNavKey === key;
      return <NavSubItem key={key} text={displayText} selected={selected} onClick={onClick} count={count} />
    });
    return (
      <AccordionButton key={key} buttonContent={buttonContent} onClick={onClick} open={selected}>
        {navSubItems}
      </AccordionButton>
    )
  })
  return (
    <Sidebar additionalClassNames={['navigation-sidebar']}>
      { showSidebarHeader && (
        <section className={'sidebar-header'}>
          <Dropdown
            options={DUMMY_GROUPS}
            defaultDisplayText={'Select group'}
            selected={selectedGroup}
            select={setSelectedGroup}
          />
        </section>
      )}
      <section className={'sidebar-body'}>
        {navItems}
      </section>
    </Sidebar>
  )
};

export default NavigationSidebar;