import './RecipientViewRow.scss';

import React from 'react';

import RecipientRowInformation from './RecipientRowInformation';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';

interface RecipientViewRowProps {
  name: string,
  emailAddress: string,
  mobileNumber: string,
  removeRow: () => void,
};

function RecipientViewRow({ name, emailAddress, mobileNumber, removeRow } : Readonly<RecipientViewRowProps>) {
  return (
    <section className={'recipient-view-row'}>
      <RecipientRowInformation name={name} emailAddress={emailAddress} mobileNumber={mobileNumber} />
      <TrashIconButton onClick={removeRow} />
    </section>
  );
}

export default RecipientViewRow;