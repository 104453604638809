import { VALUE_TYPES } from '../../../../Constants';
import { OptionType } from '../../dropdowns/DropdownConstants';
import { ConditionKey, ConditionText } from '../MultiSelectRowConstants';
import {
  stringEquals,
  stringNotEquals,
  numberEquals,
  numberNotEquals,
  lessThan,
  greaterThan,
  contains,
  notContains,
  isEmpty,
  isNotEmpty,
  dateEquals,
  dateNotEquals,
} from '../../../engagements/FilterHelpers';

const { ARRAY, STRING, NUMBER, DATE, DROPDOWN } = VALUE_TYPES;

// For Recipient Filtering
export enum FilterAttributeText {
  FirstName = 'First Name',
  LastName = 'Last Name',
  Mobile = 'Mobile',
  Email = 'Email',
  LastInteractionDate = 'Last Interaction Date',
  LastAppointmentDate = 'Last Appointment Date',
  LastTransactionDate = 'Last Transaction Date',
  CountInteractions = 'Number of Interactions',
  CountTransactions = 'Number of Transactions',
  CountAppointments = 'Number of Appointments',
};

export enum FilterAttributeKey {
  FirstNameKey = 'firstName',
  LastNameKey = 'lastName',
  MobileKey = 'mobileNumber',
  EmailKey = 'emailAddress',
  LastInteractionDateKey = 'lastInteractionDate',
  LastAppointmentDateKey = 'lastAppointmentDate',
  LastTransactionDateKey = 'lastTransactionDate',
  CountInteractionsKey = 'countInteractions',
  CountTransactionsKey = 'countTransactions',
  CountAppointmentsKey = 'countAppointments',
}

const {
  FirstName,
  LastName,
  Mobile,
  Email,
  LastInteractionDate,
  LastAppointmentDate,
  LastTransactionDate,
  CountInteractions,
  CountAppointments,
  CountTransactions
} = FilterAttributeText;

const {
  FirstNameKey,
  LastNameKey,
  MobileKey,
  EmailKey,
  LastInteractionDateKey,
  LastAppointmentDateKey,
  LastTransactionDateKey,
  CountInteractionsKey,
  CountAppointmentsKey,
  CountTransactionsKey,
} = FilterAttributeKey;



const {
  IsExactly,
  IsExactlyNot,
  Contains,
  DoesNotContain,
  IsEmpty,
  IsNotEmpty,
  IsGreaterThan,
  IsLessThan,
  IsEqual,
  IsNotEqual
} = ConditionText;

const {
  IsExactlyKey,
  IsExactlyNotKey,
  ContainsKey,
  DoesNotContainKey,
  IsEmptyKey,
  IsNotEmptyKey,
  IsGreaterThanKey,
  IsLessThanKey,
  IsEqualKey,
  IsNotEqualKey
} = ConditionKey;

export const FILTER_ATTRIBUTES = [
  { displayText: FirstName, value: FirstNameKey },
  { displayText: LastName, value: LastNameKey },
  { displayText: Mobile, value: MobileKey },
  { displayText: Email, value: EmailKey },
  { displayText: LastInteractionDate, value: LastInteractionDateKey },
  { displayText: LastAppointmentDate, value: LastAppointmentDateKey },
  { displayText: LastTransactionDate, value: LastTransactionDateKey },
  { displayText: CountInteractions, value: CountInteractionsKey },
  { displayText: CountAppointments, value: CountAppointmentsKey },
  { displayText: CountTransactions, value: CountTransactionsKey },
];

export type FILTER_OPTIONS_MAP = Record<string, OptionType<ConditionKey>[]>;

// Used to display the available filter options based on attribute type for a field
export const CONDITION_OPTIONS_MAPPING : FILTER_OPTIONS_MAP = {
  [STRING]: [
    { displayText: IsExactly, value: IsExactlyKey },
    { displayText: IsExactlyNot, value: IsExactlyNotKey },
    { displayText: Contains, value: ContainsKey },
    { displayText: DoesNotContain, value: DoesNotContainKey },
    { displayText: IsEmpty, value: IsEmptyKey },
    { displayText: IsNotEmpty, value: IsNotEmptyKey },
  ],
  [NUMBER]: [
    { displayText: IsExactly, value: IsExactlyKey },
    { displayText: IsExactlyNot, value: IsExactlyNotKey },
    { displayText: IsGreaterThan, value: IsGreaterThanKey },
    { displayText: IsLessThan, value: IsLessThanKey },
    { displayText: IsEmpty, value: IsEmptyKey },
    { displayText: IsNotEmpty, value: IsNotEmptyKey },
  ],
  [DATE]: [
    { displayText: IsEqual, value: IsEqualKey },
    { displayText: IsNotEqual, value: IsNotEqualKey },
    { displayText: IsGreaterThan, value: IsGreaterThanKey },
    { displayText: IsLessThan, value: IsLessThanKey },
    { displayText: IsEmpty, value: IsEmptyKey },
    { displayText: IsNotEmpty, value: IsNotEmptyKey },
  ]
};

export type Filter = {
  attribute: FilterAttributeKey | null,
  condition: ConditionKey | null,
  input: string | number,
};

// Used to get the attribute type.
export const FILTER_ATTRIBUTES_MAP = {
  firstName: {
    key: 'firstName',
    displayText: 'First Name',
    type: STRING
  },
  lastName: {
    key: 'lastName',
    displayText: 'Last Name',
    type: STRING
  },
  mobileNumber: {
    key: 'mobileNumber',
    displayText: 'Mobile',
    type: STRING
  },
  emailAddress: {
    key: 'emailAddress',
    displayText: 'Email',
    type: STRING
  },
  groups: {
    key: 'groups',
    displayText: 'Group',
    type: ARRAY
  },
  birthday: {
    key: 'birthday',
    displayText: 'Birthday',
    type: DATE
  },
  lastInteractionDate: {
    key: 'lastInteractionDate',
    displayText: 'Last Interaction Date',
    type: DATE
  },
  lastAppointmentDate: {
    key: 'lastAppointmentDate',
    displayText: 'Last Appointment Date',
    type: DATE
  },
  lastCampaignDate: {
    key: 'lastCampaignDate',
    displayText: 'Last Campaign Date',
    type: DATE
  },
  lastTransactionDate: {
    key: 'lastTransactionDate',
    displayText: 'Last Transaction Date',
    type: DATE
  },
  countInteractions: {
    key: 'countInteractions',
    displayText: 'Number of Interactions',
    type: NUMBER
  },
  countAppointments: {
    key: 'countAppointments',
    displayText: 'Number of Appointments',
    type: NUMBER
  },
  countTransactions: {
    key: 'countTransactions',
    displayText: 'Number of Transactions',
    type: NUMBER
  },
  contactType: {
    key: 'contactType',
    displayText: 'Contact Type',
    type: DROPDOWN
  },
  lifeTimeValue: {
    key: 'lifeTimeValue',
    displayText: 'Lifetime Value',
    type: NUMBER
  },
  customerSegmentCategory: {
    key: 'customerSegmentCategory',
    displayText: 'Customer Segment',
    type: STRING
  },
};

interface FilterCondition<T> {
  key: ConditionKey,
  displayText: string,
  valueType: VALUE_TYPES,
  valueTypeTitle: string,
  filterFunction: (a: T, b: T) => boolean
}

type TYPE_CONDITION = Record<string, FilterCondition<string | number | null | undefined>>;

export type MAP_TYPE_CONDITION = Record<string, TYPE_CONDITION>;

// Used to get the filter condition type.
export const FILTER_CONDITIONS_MAP: MAP_TYPE_CONDITION = {
  [NUMBER]: {
    isEqual: {
      key: IsEqualKey,
      displayText: 'Equal to',
      valueType: NUMBER,
      valueTypeTitle: 'isEqual',
      filterFunction: numberEquals
    },
    isNotEqual: {
      key: IsNotEqualKey,
      displayText: 'Not Equal to',
      valueType: NUMBER,
      valueTypeTitle: 'isNotEqual',
      filterFunction: numberNotEquals
    },
    isLessThan: {
      key: IsLessThanKey,
      displayText: 'Is Less Than',
      valueType: NUMBER,
      valueTypeTitle: 'isLessThan',
      filterFunction: lessThan
    },
    isGreaterThan: {
      key: IsGreaterThanKey,
      displayText: 'Is Greater Than',
      valueType: NUMBER,
      valueTypeTitle: 'isGreaterThan',
      filterFunction: greaterThan
    },
    isEmpty: {
      key: IsEmptyKey,
      displayText: 'Is empty',
      valueType: NUMBER,
      valueTypeTitle: 'empty',
      filterFunction: isEmpty
    },
    isNotEmpty: {
      key: IsNotEmptyKey,
      displayText: 'Is not empty',
      valueType: NUMBER,
      valueTypeTitle: 'notEmpty',
      filterFunction: isNotEmpty
    },
  },
  [STRING]: {
    isExactly: {
      key: IsExactlyKey,
      displayText: 'Is exactly',
      valueType: STRING,
      valueTypeTitle: 'isExactly',
      filterFunction: stringEquals
    },
    isExactlyNot: {
      key: IsExactlyNotKey,
      displayText: 'Is exactly not',
      valueType: STRING,
      valueTypeTitle: 'isExactlyNot',
      filterFunction: stringNotEquals
    },
    contains: {
      key: ContainsKey,
      displayText: 'Contains',
      valueType: STRING,
      valueTypeTitle: 'contains',
      filterFunction: contains
    },
    doesNotContain: {
      key: DoesNotContainKey,
      displayText: 'Does not contain',
      valueType: STRING,
      valueTypeTitle: 'doesNotContain',
      filterFunction: notContains
    },
    isEmpty: {
      key: IsEmptyKey,
      displayText: 'Is empty',
      valueType: STRING,
      valueTypeTitle: 'isEmpty',
      filterFunction: isEmpty
    },
    isNotEmpty: {
      key: IsNotEmptyKey,
      displayText: 'Is not empty',
      valueType: STRING,
      valueTypeTitle: 'isNotEmpty',
      filterFunction: isNotEmpty
    },
  },
  [DATE]: {
    // inMonth: {
    //   key: 'inMonth',
    //   displayText: 'In this month',
    //   valueType: DROPDOWN,
    //   valueTypeTitle: 'Select Month',
    //   filterFunction: inMonth
    // },
    isEqual: {
      key: IsEqualKey,
      displayText: 'Is equal to',
      valueType: DATE,
      valueTypeTitle: 'isEqual',
      filterFunction: dateEquals
    },
    isNotEqual: {
      key: IsNotEqualKey,
      displayText: 'Not equal to',
      valueType: DATE,
      valueTypeTitle: 'isNotEqual',
      filterFunction: dateNotEquals
    },
    isLessThan: {
      key: IsLessThanKey,
      displayText: 'Is Before',
      valueType: DATE,
      valueTypeTitle: 'isLessThan',
      filterFunction: lessThan
    },
    isGreaterThan: {
      key: IsGreaterThanKey,
      displayText: 'Is After',
      valueType: DATE,
      valueTypeTitle: 'isGreaterThan',
      filterFunction: greaterThan
    },
    isEmpty: {
      key: IsEmptyKey,
      displayText: 'Is empty',
      valueType: DATE,
      valueTypeTitle: 'isEmpty',
      filterFunction: isEmpty
    },
    isNotEmpty: {
      key: IsNotEmptyKey,
      displayText: 'Is not empty',
      valueType: DATE,
      valueTypeTitle: 'isNotEmpty',
      filterFunction: isNotEmpty
    },
  },
  [DROPDOWN]: {},
  [ARRAY]: {},
}