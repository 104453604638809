import './CreateEngagementModal.scss';

import { useState } from 'react';

import Modal from '../../common/modals/Modal';
import ConfirmationModalContent from '../../common/modals/ConfirmationModalContent';
import FormItem from '../forms/FormItem';
import DateTimeInput from '../../common/inputs/datetime/DateTimeInput';
import PrimaryInput from '../../common/inputs/PrimaryInput';

interface CreateEngagementModalProps {
  cancel: () => void,
  confirm: (setLoading: (loading: boolean) => void) => void,
  date: number | null,
  setDate: (input: number) => void,
  batchSend: string,
  setBatchSend: (batchSend: string) => void,
};

function CreateEngagementModal({ cancel, confirm, date, setDate, batchSend, setBatchSend } : Readonly<CreateEngagementModalProps>) {
  const [loading, setLoading] = useState(false);
  const selectedDate = date ? new Date(date) : new Date();
  return (
    <Modal additionalClassNames={['create-engagement-modal']}>
      <ConfirmationModalContent
        title={'Launch your engagement'}
        subtitle={'Almost there! Set up the scheduled send date and time, and we’ll do the rest.'}
        cancel={cancel}
        confirm={() => confirm(setLoading)}
        cancelText={'Cancel'}
        confirmText={'Launch'}
        loading={loading}
      >
        <section className={'date-time-selection'}>
          <FormItem label={'Select a date'}>
            <DateTimeInput minDate={new Date()} selectedDate={selectedDate} setDate={setDate} />
          </FormItem>
          <FormItem label={'Batch send'}>
            <PrimaryInput placeholder={'Enter number of batches to send'} setText={setBatchSend} text={batchSend} />
          </FormItem>
        </section>
      </ConfirmationModalContent>
    </Modal>
  )
};

export default CreateEngagementModal;