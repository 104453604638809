import './SegmentsTable.scss';

import { useState } from 'react';

import Table, { TableHeaderProps } from '../../../common/tables/Table';
import TextViewerModal from '../../../common/modals/TextViewerModal';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import OutlineButton from '../../../common/buttons/OutlineButton';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';
import PaginationBar from '../../../common/tables/PaginationBar';
import ConfirmSegmentDeletionModal from '../modals/ConfirmSegmentDeletionModal';
import Tooltip from '../../../common/tooltips/Tooltip';
import CUSTOMIZE_ICON from '../assets/customize-icon.svg';
import INFO_ICON from '../assets/information-icon.svg';
import useAuth from '../../../../context/AuthContext';
import { filterRecipientsWithBounds, getBoundsWithMetricOptions } from '../SegmentsHelper';
import { useNavigate } from 'react-router';

const SEGMENTS_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Segment name', key: 'segmentName' },
  { displayText: 'Description', key: 'description' },
  { displayText: 'Customers', key: 'numberOfCustomers' },
  { 
    displayText: (
      <span className={'cell-text'}>
        <span className={'cell-content'}>
          <img className={'column-icon'} src={CUSTOMIZE_ICON} alt={''} />
          {'Customise'}
        </span>
        <Tooltip content={'Provide AI with context to better personalise messages to this customer segment'}><img className={'info-icon'} src={INFO_ICON} alt={''} /></Tooltip>
      </span>
    ),
    key: 'customise' 
  },
  { displayText: 'Engage', key: 'engageSegment' },
  { displayText: 'Modify', key: 'modifySegment' },
  { displayText: 'Delete', key: 'deleteSegment' },
];

const SEGMENTS_MAX_ROWS = 5;

function SegmentsTable() {
  const navigate = useNavigate();
  const [cursor, setCursor] = useState(0);
  const { user } = useAuth();
  const userSegments = user && user.userSegment && user.userSegment.segments ? user.userSegment.segments : [];
  const metrics = user && user.userSegment && user.userSegment.metrics ? user.userSegment.metrics : [];
  const recipients = user && user.recipients ? user.recipients : [];
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<number | null>(null);
  const [textToDisplay, setTextToDisplay] = useState<string | null>(null);
  const [segments, setSegments] = useState(userSegments);
  const displayText = (text: string) => () => setTextToDisplay(text);
  const modifySegment = () => navigate('/people/segments/create');
  const rows = segments.map(({ segmentName, description, bounds, customiseMessage }, index) => {
    const boundSetWithMetricOption = getBoundsWithMetricOptions(bounds ? bounds : [], metrics);
    const numberOfCustomers = filterRecipientsWithBounds(recipients, boundSetWithMetricOption).length;
    const segmentNameNode = <section className={'two-cell center short-text'} onClick={displayText(segmentName)}>{segmentName}</section>
    const descriptionNode = <section className={'two-cell long-text'} onClick={displayText(description)}>{description}</section>
    const numberOfCustomersNode = <section className={'one-cell'}>{numberOfCustomers}</section>
    const engageSegmentNode = <PrimaryButton content={'Engage segment'} additionalClassNames={['button-cell']} />;
    const customiseNode = <section className={'two-cell center long-text'} onClick={displayText(customiseMessage)}>{customiseMessage}</section>;
    const modifySegmentNode = <OutlineButton content={'Modify segment'} additionalClassNames={['button-cell']} onClick={modifySegment} />;
    const deleteSegmentNode = <TrashIconButton onClick={() => setSelectedSegmentIndex(index)} additionalClassNames={['one-cell']} />;
    return {
      cells: [
        { key: 'segmentName', value: segmentNameNode },
        { key: 'description', value: descriptionNode },
        { key: 'numberOfCustomers', value: numberOfCustomersNode },
        { key: 'customise', value: customiseNode },
        { key: 'engageSegment', value: engageSegmentNode },
        { key: 'modifySegment', value: modifySegmentNode },
        { key: 'deleteSegment', value: deleteSegmentNode },
      ]
    }
  });
  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  const modalOpen = selectedSegmentIndex !== null;
  const closeConfirmationModal = () => setSelectedSegmentIndex(null);
  const deleteSegment = (index: number) => setSegments(segments.filter((_: any, i: number) => i !== index));
  const confirmDeleteSegment = () => {
    if (selectedSegmentIndex !== null) {
      deleteSegment(selectedSegmentIndex);
    }
    closeConfirmationModal();
  };
  return (
    <>
      { showingText && <TextViewerModal text={textToDisplay} close={closeTextToDisplayModal} /> }
      { modalOpen && <ConfirmSegmentDeletionModal cancel={closeConfirmationModal} confirm={confirmDeleteSegment} /> }
      <section className={'segments-table-container'}>
        <Table
          additionalClassNames={['segments-table']}
          headers={SEGMENTS_TABLE_HEADERS}
          rows={rows}
          cursor={cursor}
          maxRows={SEGMENTS_MAX_ROWS}
        />
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={SEGMENTS_MAX_ROWS}
            totalRows={segments.length}
          />
        </section>
      </section>
    </>
  )
}

export default SegmentsTable;

