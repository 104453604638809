import './LoadingRingPage.scss';

import LoadingRing from '../loading/LoadingRing';

interface LoadingRingPageProps {
  color: string
}

function LoadingRingPage({ color = '#FFFFFF' } : LoadingRingPageProps) {
  return (
    <section className={'loading-ring-page-content'}>
      <LoadingRing color={color} />
    </section>
  )
}

export default LoadingRingPage;