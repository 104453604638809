import './MultiSelectRow.scss';

import { ReactNode } from 'react';

import TrashIconButton from '../buttons/composite/TrashIconButton';

interface MultiSelectRowProps {
  children: ReactNode,
  deleteRow: () => void,
}

function MultiSelectRow({
  children,
  deleteRow
} : Readonly<MultiSelectRowProps>) {
  return (
    <section className={'multi-select-row'}>
      {children}
      <TrashIconButton onClick={deleteRow} />
    </section>
  );
}

export default MultiSelectRow;