import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import LoginInput from '../common/LoginInput';

import { postSubmitMobileLogin } from '../../../apis/LoginApi';

interface LoginInputContainerProps {
  loginDetail: string,
  setLoginDetail: React.Dispatch<React.SetStateAction<string>>,
  setShowingVerifyPage: React.Dispatch<React.SetStateAction<boolean>>,
}

function MobileLoginInput({ loginDetail, setLoginDetail, setShowingVerifyPage } : LoginInputContainerProps) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const hasError = error.length > 0;
  const hasInput = loginDetail.length > 0;

  const proceedToVerify = async () => {
    if (loading) {
      return;
    }
    if (loginDetail.length < 8) {
      setError('Please enter a valid Mobile Number.')
      setLoading(false);
      return;
    }
    const success = await postSubmitMobileLogin(loginDetail);
    if (!success) {
      setError('Unable to find an account.');
      setLoading(false);
      return;
    }
    setShowingVerifyPage(true);
  }

  return (
    <LoginInput error={error} inputLabel={'Mobile Number'} onSubmit={proceedToVerify}>
      <PhoneInput
        placeholder={''}
        country={'au'}
        onlyCountries={['au', 'nz']}
        value={loginDetail}
        onChange={(sms: string) => setLoginDetail(sms)}
        onEnterKeyPress={proceedToVerify}
        containerClass={`${hasInput ? 'has-input' : ''} ${hasError ? 'has-error' : ''}`}
      />
    </LoginInput>
  )
}

export default MobileLoginInput;