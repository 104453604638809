import './TextViewerModal.scss';

import Modal from '../../common/modals/Modal';
import OutlineButton from '../../common/buttons/OutlineButton';

interface TextViewerModalProps {
  text?: string
  close: () => void,
};

function TextViewerModal({ text = '', close } : Readonly<TextViewerModalProps>) {
  return (
    <Modal additionalClassNames={['text-viewer-modal']}>
      <p className={'text'}>
        {text}
      </p>
      <OutlineButton content={'Close'} onClick={close} />
    </Modal>
  )
};

export default TextViewerModal;