import './CompetitorOffersTrackingTable.scss';

import React, { useState, ReactNode } from 'react';
import { useNavigate } from 'react-router';

import Table, { TableHeaderProps, TableRow } from '../../common/tables/Table';
import TrashIconButton from '../../common/buttons/composite/TrashIconButton';
import PaginationBar from '../../common/tables/PaginationBar';
import EditTextModal from '../../common/modals/EditTextModal';
import { TextToDisplay } from '../../engagements/EngagementsConstants';
import { TrackingStatus } from '../CompetitorOffersConstants';
import DarkPurpleTag from '../../common/tags/DarkPurpleTag';
import RedTag from '../../common/tags/RedTag';
import LoadingRing from '../../common/loading/LoadingRing';
import GreenTag from '../../common/tags/GreenTag';
import OutlineButton from '../../common/buttons/OutlineButton';
import { TrackedOffer } from '../CompetitorOffersConstants';

const { Searching, Found, NotFound } = TrackingStatus;

const TRACKING_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Competitor name', key: 'competitorName' },
  { displayText: 'URL', key: 'url' },
  { displayText: 'Status', key: 'status' },
  { displayText: 'Action', key: 'action' },
  { displayText: 'Delete', key: 'delete' },
];

const TRACKING_MAX_ROWS = 5;

function getStatusNode(status: TrackingStatus): ReactNode {
  switch (status) {
    case NotFound:
      return <RedTag content={status} />;
    case Found:
      return <GreenTag content={status} />;
    case Searching: {
      const content = (
        <section className={'tag-content'}>
          <LoadingRing color={'#FFFFFF'} />
          <span className={'tag-text'}>{status}</span>
        </section>
      );
      return <DarkPurpleTag content={content} />;
    }
    default:
      return null;
  }
};

interface CompetitorOffersTrackingTableProps {
  rows: TrackedOffer[],
  setRows: (offers: TrackedOffer[]) => void,
  openModal: () => void,
}

function CompetitorOffersTrackingTable({ rows, setRows, openModal } : Readonly<CompetitorOffersTrackingTableProps>) {
  const navigate = useNavigate();
  const [textToDisplay, setTextToDisplay] = useState<TextToDisplay | null>(null);
  const [cursor, setCursor] = useState(0);
  const setTrackedOffer = (trackedOffer: TrackedOffer, index: number) => {
    const newTrackedOffers = [...rows];
    newTrackedOffers[index] = trackedOffer;
    setRows(newTrackedOffers);
  }
  const displayText = (text: string, index: number, fieldName: string) => () => setTextToDisplay({ text, index, fieldName });
  const deleteRow = (index: number) => setRows(rows.filter((_, i) => i !== index));
  function getActionNode(status: TrackingStatus): ReactNode {
    switch (status) {
      case NotFound:
        return <OutlineButton content={'Track another'} onClick={openModal} />;
      case Found:
        return <OutlineButton content={'View offers'} onClick={() => navigate('/settings/competitors/view')} />;
      case Searching:
        return <section className={'coming-soon'}>{'Check back soon...'}</section>
      default:
        return null;
    }
  }
  const tableRows: TableRow[] = rows.map(({ competitorName, url, status } : TrackedOffer, index: number) => {
    const competitorNameNode = <section className={'one-and-half-cell center short-text'} onClick={displayText(competitorName, index, 'competitorName')}>{competitorName}</section>;
    const urlNode = <section className={'two-cell center short-text'} onClick={displayText(url, index, 'url')}>{url}</section>;
    const statusNode = <section className={'status-cell'}>{getStatusNode(status)}</section>;
    const actionNode = <section className={'button-cell'}>{getActionNode(status)}</section>;
    const deleteNode = <TrashIconButton onClick={() => deleteRow(index)} additionalClassNames={['one-cell']} />;
    return {
      cells: [
        { key: 'competitiorName', value: competitorNameNode },
        { key: 'urlNode', value: urlNode },
        { key: 'statusNode', value: statusNode },
        { key: 'actionNode', value: actionNode },
        { key: 'delete', value: deleteNode },
      ]
    };
  });
  if (rows.length === 0) {
    return null;
  }

  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  const confirmTextChanges = (text: string) => {
    if (textToDisplay) {
      const { index, fieldName } = textToDisplay;
      setTrackedOffer({ ...rows[index], [fieldName]: text }, index);
    }
    closeTextToDisplayModal();
  }
  const modalText = textToDisplay ? textToDisplay.text : '';
  return (
    <>
      { showingText && <EditTextModal text={modalText} confirm={confirmTextChanges} close={closeTextToDisplayModal} /> }
      <section className={'competitor-offers-tracking-table-container'}>
        <Table
          additionalClassNames={['competitor-offers-tracking-table']}
          headers={TRACKING_TABLE_HEADERS}
          rows={tableRows}
          cursor={cursor}
          maxRows={TRACKING_MAX_ROWS}
        />
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={TRACKING_MAX_ROWS}
            totalRows={tableRows.length}
          />
        </section>
      </section>
    </>
  )
}

export default CompetitorOffersTrackingTable;

