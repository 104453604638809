import './VerificationCodeDigits.scss';

interface VerificationCodeDigitProps {

  codeDigit: string,
  selected: boolean,
  filled: boolean,
  focused: boolean,
};

function VerificationCodeDigit({ codeDigit, selected, filled, focused } : VerificationCodeDigitProps) {
  const isInFocus = (selected || filled) && focused;

  return (
    <section className={'verification-digit'}>
      {codeDigit}
      {isInFocus && <div className="shadows" />}
    </section>
  )
}

export default VerificationCodeDigit;