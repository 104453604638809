import './ConfirmVariantDeletionModal.scss';

import Modal from '../../common/modals/Modal';
import ConfirmationModalContent from '../../common/modals/ConfirmationModalContent';

interface ConfirmVariantDeletionModalProps {
  cancel: () => void,
  confirm: () => void,
};

function ConfirmVariantDeletionModal({ cancel, confirm } : Readonly<ConfirmVariantDeletionModalProps>) {
  return (
    <Modal additionalClassNames={['confirm-variant-deletion-modal']}>
      <ConfirmationModalContent
        title={'Are you absolutely sure?'}
        subtitle={'This action cannot be undone. This will delete this variant from your engagement.'}
        cancel={cancel}
        confirm={confirm}
      />
    </Modal>
  )
};

export default ConfirmVariantDeletionModal;