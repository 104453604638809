import './VariantResultsTable.scss';

import React, { useState } from 'react';

import Table, { TableHeaderProps } from '../../common/tables/Table';
import { EngagementStatus } from '../EngagementsTypes';
import PaginationBar from '../../common/tables/PaginationBar';
import TextViewerModal from '../../common/modals/TextViewerModal';
import GreenTag from '../../common/tags/GreenTag';
import EngagementPerformance from '../common/EngagementPerformance';
import OutlineButton from '../../common/buttons/OutlineButton';
import Tooltip from '../../common/tooltips/Tooltip';

import INFO_ICON from '../../people/segments/assets/information-icon.svg';
import { useNavigate } from 'react-router';

const { Active } = EngagementStatus;

const VARIANT_RESULTS_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Variant name', key: 'name' },
  { displayText: 'Date active', key: 'date' },
  { displayText: 'Status', key: 'status' },
  { displayText: 'Edit', key: 'editVariant' },
  { 
    displayText: (
      <span className={'cell-text'}>
        <span className={'cell-content'}>
          {'Performance'}
        </span>
        <Tooltip content={'This score is calculated by WorkRex'}><img className={'info-icon'} src={INFO_ICON} alt={''} /></Tooltip>
      </span>
    ),
    key: 'performance'
  },
  { displayText: 'Total send', key: 'totalSend' },
  { displayText: 'Total read', key: 'totalRead' },
  { displayText: 'Total replies', key: 'totalReplies' },
  { displayText: 'Total opt-outs', key: 'totalOptOuts' },
  { displayText: 'Total visits', key: 'totalVisits' },
  { displayText: 'Revenue', key: 'revenue' },
  { displayText: 'Costs', key: 'costs' },
  { displayText: 'Export results', key: 'exportResults'},
];

const DUMMY_TABLE_DATA = [
  {
    variantName: 'SMS reactivations',
    date: '19/08/24',
    status: Active,
    performance: 3,
    totalSend: 54,
    totalRead: 48,
    totalReplies: 34,
    totalOptOuts: 7,
    totalVisits: 24,
    revenue: '1,508',
    costs: '352',
  },
  {
    variantName: 'Email reactivations',
    date: '19/08/24',
    status: Active,
    performance: 2,
    totalSend: 154,
    totalRead: 141,
    totalReplies: 14,
    totalOptOuts: 2,
    totalVisits: 14,
    revenue: '895',
    costs: '150',
  },
];

const VARIANT_RESULTS_MAX_ROWS = 5;

function VariantResultsTable() {
  const navigate = useNavigate();
  const [cursor, setCursor] = useState(0);
  const [textToDisplay, setTextToDisplay] = useState<string | null>(null);
  const displayText = (text: string) => () => setTextToDisplay(text);
  const rows = DUMMY_TABLE_DATA.map(({ variantName, date, status, performance, totalSend, totalRead, totalReplies, totalOptOuts, totalVisits, revenue, costs }) => {
    const nameNode = <section className={'two-cell center short-text'} onClick={displayText(variantName)}>{variantName}</section>
    const dateNode = <section className={'one-cell'}>{date}</section>;
    const edit = () => navigate('/engagements/edit');
    const statusNode = <GreenTag content={status} additionalClassNames={['tag-cell']} />;
    const editVariantNode = <OutlineButton content={'Edit'} additionalClassNames={['button-cell']} onClick={edit} />;
    const performanceNode = <section className={'one-cell center'}><EngagementPerformance performance={performance} showTooltip={false} /></section>;
    const totalSendNode = <section className={'one-cell'}>{totalSend}</section>;
    const totalReadNode = <section className={'one-cell'}>{`${totalRead}/${totalSend}`}</section>;
    const totalRepliesNode = <section className={'one-cell'}>{`${totalReplies}/${totalSend}`}</section>;
    const totalOptOutsNode = <section className={'one-cell'}>{`${totalOptOuts}/${totalSend}`}</section>;
    const totalVisitsNode = <section className={'one-cell'}>{`${totalVisits}/${totalSend}`}</section>;
    const revenueNode = <section className={'one-cell'}>{`$${revenue}`}</section>;
    const costsNode = <section className={'one-cell'}>{`$${costs}`}</section>;
    const exportResultsNode = <OutlineButton content={'Export'} additionalClassNames={['half-button-cell']} />;
    return {
      cells: [
        { key: 'name', value: nameNode },
        { key: 'date', value: dateNode },
        { key: 'status', value: statusNode },
        { key: 'editVariant', value: editVariantNode },
        { key: 'performance', value: performanceNode },
        { key: 'totalSend', value: totalSendNode },
        { key: 'totalRead', value: totalReadNode },
        { key: 'totalReplies', value: totalRepliesNode },
        { key: 'totalOptOuts', value: totalOptOutsNode },
        { key: 'totalVisits', value: totalVisitsNode },
        { key: 'revenue', value: revenueNode },
        { key: 'costs', value: costsNode },
        { key: 'exportResults', value: exportResultsNode },
      ]
    }
  });
  if (rows.length === 0) {
    return null;
  }
  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  return (
    <>
      { showingText && <TextViewerModal text={textToDisplay} close={closeTextToDisplayModal} /> }
      <section className={'variant-results-table-container'}>
        <Table
          additionalClassNames={['variant-results-table']}
          headers={VARIANT_RESULTS_TABLE_HEADERS}
          rows={rows}
          cursor={cursor}
          maxRows={VARIANT_RESULTS_MAX_ROWS}
        />
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={VARIANT_RESULTS_MAX_ROWS}
            totalRows={DUMMY_TABLE_DATA.length}
          />
        </section>
      </section>
    </>
  )
}

export default VariantResultsTable;

