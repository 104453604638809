import './TimeOption.scss';

import { HOUR, MINUTE } from '../InputConstants';

interface TimeOptionProps {
  selectedTime: number,
  time: HOUR | MINUTE,
  setTime: (time: number) => void,
};

function TimeOption({ selectedTime, time, setTime } : TimeOptionProps) {
  const selected = selectedTime === time.key;
  return (
    <button className={`time-option ${selected ? 'selected' : ''}`} onClick={() => setTime(time.key)} type={'button'}>
      {time.text}
    </button>
  )
}

export default TimeOption;