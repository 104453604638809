import './AddRecipientsModal.scss';

import { useState } from 'react';

import Modal from '../../../common/modals/Modal';
import RecipientSelectionBar from './RecipientSelectionBar';
import RecipientSearchBar from './RecipientSearchBar';
import RecipientSelectionTable from './RecipientSelectionTable';
import RecipientViewTable from './RecipientViewTable';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import { RecipientRow } from './RecipientSelectionTable';
import AddFilterModal from '../add-filter/AddFilterModal';
import { Filter } from '../../../common/multi-select-rows/filters/FilterConstants';
import { filterRecipients } from '../../../common/multi-select-rows/filters/FilterHelpers';

interface AddRecipientsModalProps {
  close: () => void,
  recipients: RecipientRow[],
  confirm: (recipients: string[]) => void,
  initialSelectedRecipients: string[]
};

// What happens if you select 10 recipients, then apply a filter that removes 5 of them from the select list.
// Do we also remove them from the selected recipients list?
function AddRecipientsModal({ close, recipients, initialSelectedRecipients, confirm } : AddRecipientsModalProps) {
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState<Filter[]>([]);
  const [selectedRecipients, setSelectedRecipients] = useState<string[]>(initialSelectedRecipients);
  const [filteredRecipients, setFilteredRecipients] = useState(recipients);
  const selectedRows = recipients.filter(({ _id }) => selectedRecipients.includes(_id));
  const quickSelect = (numRecipients: number) => {
    return () => {
      const availableRecipients = recipients
        .filter(({ _id, alreadySelected }) => !alreadySelected && !selectedRecipients.includes(_id))
        .map(({ _id }) => _id)
        .slice(0, numRecipients);
      setSelectedRecipients([...selectedRecipients, ...availableRecipients])
    }
  }
  const confirmRecipients = () => confirm(selectedRecipients);
  const openFilterModal = () => setFilterOpen(true);

  const confirmFilters = (filters: any) => {
    const populatedFilters = filters.filter((filter: Filter) => filter.condition);
    setFilters(populatedFilters);
    const recipientsToDisplay = filterRecipients(recipients, populatedFilters);
    setFilteredRecipients(recipientsToDisplay);
  };

  const resetFilters = () => setFilters([]);

  if (filterOpen) {
    return (
      <AddFilterModal parentFilters={filters} close={() => setFilterOpen(false)} confirm={confirmFilters} resetFilters={resetFilters} />
    );
  }
  console.log(filters);
  console.log(filteredRecipients);
  return (
    <>
      <Modal additionalClassNames={['add-recipients-modal']}>
        <section className={'modal-container'}>
          <header className={'header'}>
            <h5 className={'title'}>{'Add your recipients'}</h5>
            <p className={'subtitle'}>{'Select at least 30 recipients for this engagement variation for statistical accuracy'}</p>
            <RecipientSearchBar searchText={searchText} setSearchText={setSearchText} />
            <RecipientSelectionBar quickSelect={quickSelect} openFilterModal={openFilterModal} />
          </header>
          <section className={'recipients-container'}>
            <section className={'selection-container'}>
              <RecipientSelectionTable
                rows={filteredRecipients}
                selectedRows={selectedRecipients}
                setSelectedRows={setSelectedRecipients}
                searchText={searchText}
              />
            </section>
            <section className={'selection-container'}>
              <RecipientViewTable rows={selectedRows} setSelectedRows={setSelectedRecipients} />
            </section>
          </section>
          <section className={'buttons'}>
            <OutlineButton content={'Cancel'} onClick={close} />
            <PrimaryButton content={'Confirm'} onClick={confirmRecipients} />
        </section>
        </section>
      </Modal>
    </>
  );
}

export default AddRecipientsModal;