import './EditEngagementTable.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router';

import Table, { TableHeaderProps } from '../../common/tables/Table';
import { CHANNEL_OPTIONS } from '../../../Constants';
import Dropdown from '../../common/dropdowns/Dropdown';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import PrimaryPlusButton from '../../common/buttons/composite/PrimaryPlusButton';
import EngagementPerformance from '../common/EngagementPerformance';
import SubtleButton from '../../common/buttons/SubtleButton';
import PaginationBar from '../../common/tables/PaginationBar';
import { LiveVariant, TextToDisplay } from '../EngagementsConstants';
import EditTextModal from '../../common/modals/EditTextModal';


const TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Variant name', key: 'name' },
  { displayText: 'Description', key: 'description' },
  { displayText: 'Performance', key: 'performance' },
  { displayText: 'Edit offer', key: 'editOffer' },
  { displayText: 'Edit channel', key: 'editChannel' },
  { displayText: 'Edit recipients', key: 'editRecipients' },
  { displayText: 'Recipients', key: 'recipients' },
  { displayText: 'Generate', key: 'generate' },
  { displayText: 'Message', key: 'message' },
];

function getEmptyLiveVariant(index: number) {
  return {
    name: `Variant ${index + 1}`,
    description: '',
    performance: 0,
    offer: null,
    channel: null,
    numberOfRecipientsSent: 0,
    totalNumberOfRecipients: 0,
    enabled: true,
    message: '',
  }
}

interface EditEngagementTableProps {
  variants: LiveVariant[],
  setVariants: (variants: LiveVariant[]) => void,
}

const EDIT_ENGAGEMENTS_MAX_ROWS = 5;

function EditEngagementTable({ variants, setVariants } : EditEngagementTableProps) {
  const navigate = useNavigate();
  const [cursor, setCursor] = useState(0);
  const [textToDisplay, setTextToDisplay] = useState<TextToDisplay | null>(null);
  const setVariant = (variant: any, index: number) => {
    const newVariants = [...variants];
    newVariants[index] = variant;
    setVariants(newVariants);
  }
  const addVariant = () => setVariants([...variants, getEmptyLiveVariant(variants.length)]);
  const setOffer = (index: number) => (offer: string) => setVariant({ ...variants[index], offer }, index);
  const setChannel = (index: number) => (channel: string) => setVariant({ ...variants[index], channel }, index);
  const displayText = (text: string, index: number, fieldName: string) => () => setTextToDisplay({ text, index, fieldName });
  const rows = variants.map(({ name, description, performance, offer, channel, numberOfRecipientsSent, totalNumberOfRecipients, message } : LiveVariant, index: number) => {
    const nameNode = <section className={'two-cell short-text'} onClick={displayText(name, index, 'name')}>{name}</section>
    const descriptionNode = <section className={'two-cell long-text'} onClick={displayText(description, index, 'description')}>{description}</section>
    const performanceNode = <section className={'one-cell'}><EngagementPerformance performance={performance} /></section>
    const addOffer = () => navigate('/settings/offers');
    const addOfferButton = { content: 'Add new offer', onClick: addOffer };
    const offerNode = <Dropdown options={[ { displayText: '$50 in-store credit', value: '$50 in-store credit' }]} selected={offer} select={setOffer(index)} additionalClassNames={['two-cell']} additionalOption={addOfferButton} />;
    const channelNode = <Dropdown options={CHANNEL_OPTIONS} selected={channel} select={setChannel(index)} additionalClassNames={['two-cell']} />;
    const editRecipientsNode = <SubtleButton content={'Edit'} additionalClassNames={['button-cell']} />;
    const recipientsNode = <section className={'one-cell'}>{`${numberOfRecipientsSent}/${totalNumberOfRecipients}`}</section>;
    const generateNode = <PrimaryButton content={'Generate'} additionalClassNames={['button-cell']} />;
    const messageNode = <section className={'two-cell long-text'} onClick={displayText(message, index, 'message')}>{message}</section>;
    return {
      cells: [
        { key: 'name', value: nameNode },
        { key: 'description', value: descriptionNode },
        { key: 'performance', value: performanceNode },
        { key: 'editOffer', value: offerNode },
        { key: 'editChannel', value: channelNode },
        { key: 'editRecipients', value: editRecipientsNode },
        { key: 'recipients', value: recipientsNode },
        { key: 'generate', value: generateNode },
        { key: 'message', value: messageNode },
      ]
    }
  });
  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  const confirmTextChanges = (text: string) => {
    if (textToDisplay) {
      const { index, fieldName } = textToDisplay;
      setVariant({ ...variants[index], [fieldName]: text }, index);
    }
    closeTextToDisplayModal();
  }
  const modalText = textToDisplay ? textToDisplay.text : '';
  return (
    <>
      { showingText && <EditTextModal text={modalText} confirm={confirmTextChanges} close={closeTextToDisplayModal} /> }
      <section className={'edit-engagement-table-container'}>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['edit-engagement-table']}
            headers={TABLE_HEADERS}
            rows={rows}
            cursor={cursor}
            maxRows={EDIT_ENGAGEMENTS_MAX_ROWS}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={EDIT_ENGAGEMENTS_MAX_ROWS}
            totalRows={rows.length}
          />
        </section>
        <PrimaryPlusButton content={'Add variant'} additionalClassNames={['create-variant-button']} onClick={addVariant} />
      </section>
    </>
  )
}

export default EditEngagementTable;

