import { postData } from './Api';

import { Service, Offer } from '../components/settings/SettingsConstants';

const PostSettingsUrls = {
  SaveOrUpdateServiceUrl: '/api/settings/service/saveOrUpdate',
  DeleteServiceUrl: '/api/settings/service/delete',
  SaveOrUpdateOfferUrl: '/api/settings/offer/saveOrUpdate',
  DeleteOfferUrl: '/api/settings/offer/delete',
  GenerateBaseOfferUrl: '/api/settings/offer/generate/baseOffer',
};

const {
  SaveOrUpdateServiceUrl,
  DeleteServiceUrl,
  SaveOrUpdateOfferUrl,
  DeleteOfferUrl,
  GenerateBaseOfferUrl
} = PostSettingsUrls;

interface SaveOrUpdateServiceRequest {
  service: Service
};

interface SaveOrUpdateServiceResponse {
  services: Service[],
  updatedServiceId: string,
};

interface SaveOrUpdateOfferRequest {
  offer: Offer
};

interface SaveOrUpdateOfferResponse {
  updatedOfferId: string,
  offers: Offer[],
};

interface GenerateBaseOfferResponse {
  baseOffer: string,
  offers: Offer[],
}


/**
 * Saves or updates a service. Returns a promise with the list of user's services and an updated service ID
 * for the saved or updated service.
 */
export async function postSaveOrUpdateService(service: Service): Promise<SaveOrUpdateServiceResponse> {
  const { updatedServiceId, services } = await postData<SaveOrUpdateServiceRequest, SaveOrUpdateServiceResponse>(
    SaveOrUpdateServiceUrl, { service }
  );
  return { updatedServiceId, services };
};

/**
 * Deletes a service. Returns a promise with the list of user's services.
 */
export async function postDeleteService(serviceId: string): Promise<Service[]> {
  const { services } = await postData<{ serviceId: string }, { services: Service[] }>(
    DeleteServiceUrl, { serviceId }
  );
  return services;
};

/**
 * Saves or updates an offer. Returns a promise with the list of a user's offers and an updated offer ID
 * for the saved or updated offer.
 */
export async function postSaveOrUpdateOffer(offer: Offer): Promise<SaveOrUpdateOfferResponse> {
  console.log({ offer });
  const { updatedOfferId, offers } = await postData<SaveOrUpdateOfferRequest, SaveOrUpdateOfferResponse>(
    SaveOrUpdateOfferUrl, { offer }
  );
  return { updatedOfferId, offers };
};

/**
 * Deletes a offer. Returns a promise with the list of user's offers.
 */
export async function postDeleteOffer(offerId: string): Promise<Offer[]> {
  const { offers } = await postData<{ offerId: string }, { offers: Offer[] }>(
    DeleteOfferUrl, { offerId }
  );
  return offers;
};

/**
 * Generates the base offer from the offer details using AI. Saves the generated base offer to the offer in
 * the database. Returns a promise with the new offer details.
 */
export async function postGenerateBaseOffer(offer: Offer): Promise<GenerateBaseOfferResponse> {
  const { baseOffer, offers } = await postData<SaveOrUpdateOfferRequest, GenerateBaseOfferResponse>(
    GenerateBaseOfferUrl, { offer }
  );
  return { baseOffer, offers };
}
