import './RecipientSearchBar.scss';

import React, { ChangeEvent } from 'react';

import SEARCH_ICON from '../../assets/search-icon.svg';

interface RecipientSearchBarProps {
  searchText: string,
  setSearchText: (text: string) => void,
}

function RecipientSearchBar({ searchText, setSearchText } : Readonly<RecipientSearchBarProps>) {
  const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => setSearchText(target.value);
  return (
    <section className={'recipient-search-bar'}>
      <img className={'search-icon'} src={SEARCH_ICON} alt={''} />
      <input className={'search-input'} placeholder={'Type recipient name to search'} value={searchText} onChange={onChange} />
    </section>
  );
}

export default RecipientSearchBar;