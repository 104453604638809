import './AddMetricModal.scss';

import React, { useState } from 'react';

import Modal from '../../../common/modals/Modal';
import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import { Metric, MetricAttributeKey } from '../../../common/multi-select-rows/metrics/MetricConstants';
import SubtleButton from '../../../common/buttons/SubtleButton';
import MetricRow from '../../../common/multi-select-rows/metrics/MetricRow';
import { ConditionKey } from '../../../common/multi-select-rows/MultiSelectRowConstants';

const EMPTY_METRIC = {
  attribute: null,
  condition: null,
  input: '',
};

interface AddMetricModalProps {
  parentMetrics: Metric[],
  close: () => void,
  confirm: (metrics: Metric[]) => void,
}

function AddMetricModal({ parentMetrics, close, confirm } : Readonly<AddMetricModalProps>) {
  const [metrics, setMetrics] = useState<Metric[]>(parentMetrics);
  const confirmMetrics = () => {
    const populatedMetrics = metrics.filter(metric => !metric.attribute || !metric.condition);
    confirm(populatedMetrics);
    close();
  };
  const addMetric = () => {
    const newMetrics = [...metrics, { ...EMPTY_METRIC }];
    setMetrics(newMetrics);
  }
  const setMetric = (index: number) => (fieldName: string, value: string | number) => {
    const newMetrics = [...metrics];
    newMetrics[index] = { ...newMetrics[index], [fieldName]: value };
    setMetrics(newMetrics);
  }

  const deleteMetric = (index: number) => {
    const newMetrics = metrics.filter((_, i) => i !== index);
    setMetrics(newMetrics);
  }

  const metricList = metrics.map(({ attribute, condition, input }, index) => {
    const setAttribute = (attribute: MetricAttributeKey) => setMetric(index)('attribute', attribute);
    const setCondition = (condition: ConditionKey) => setMetric(index)('condition', condition);
    const setInput = (input: string | number) => setMetric(index)('input', input);
    return (
      <MetricRow
        key={`metric-${index}`}
        attribute={attribute}
        setAttribute={setAttribute}
        condition={condition}
        setCondition={setCondition}
        input={input}
        setInput={setInput}
        deleteRow={() => deleteMetric(index)}
      />
    );
  });
  return (
    <Modal additionalClassNames={['add-metric-modal']}>
      <header className={'add-metric-modal-header'}>
        <h4 className={'add-metric-title'}>
          {'Add conversion metrics'}
        </h4>
        <h5 className={'add-metric-subtitle'}>
          {'Only revenue from customers that meet conversion metrics are attributed to this campaign'}
        </h5>
      </header>
      <section className={'add-metric-content'}>
        <section className={'metrics'}>
          {metricList}
        </section>
      </section>
      <section className={'add-metric-buttons'}>
        <OutlineButton content={'Cancel'} onClick={close} />
        <SubtleButton content={'Add another'} onClick={addMetric} />
        <PrimaryButton content={'Confirm'} onClick={confirmMetrics} />
      </section>
    </Modal>
  );
}

export default AddMetricModal;