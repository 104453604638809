import './EditTextModal.scss';

import { ChangeEvent, useState } from 'react';

import Modal from '../../common/modals/Modal';
import OutlineButton from '../../common/buttons/OutlineButton';
import PrimaryButton from '../buttons/PrimaryButton';

interface EditTextModalProps {
  text: string,
  placeholder?: string,
  close: () => void,
  confirm: (text: string) => void,
  loading?: boolean,
  validate?: (text: string) => boolean,
};

function EditTextModal({ text, placeholder = '', close, confirm, loading = false, validate } : Readonly<EditTextModalProps>) {
  const [currentText, setCurrentText] = useState(text);
  const onChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
    if (validate && !validate(value)) return;
    setCurrentText(value)
  };
  const confirmChanges = () => confirm(currentText);
  return (
    <Modal additionalClassNames={['edit-text-modal']}>
      <textarea className={'text'} value={currentText} onChange={onChange} placeholder={placeholder} />
      <section className={'buttons'}>
        <OutlineButton content={'Cancel'} onClick={close} />
        <PrimaryButton content={'Confirm'} onClick={confirmChanges} loading={loading} />
      </section>
    </Modal>
  )
};

export default EditTextModal;