import { useState } from 'react';

import PrimaryButton from '../../../common/buttons/PrimaryButton';
import Dropdown from '../../../common/dropdowns/Dropdown';
import OutlineButton from '../../../common/buttons/OutlineButton';
import MetricDisplay from './MetricDisplay';
import SubtlePlusButton from '../../../common/buttons/composite/SubtlePlusButton';
import MetricTable from '../tables/MetricTable';
import { MetricSegment, CustomMetricOptions, CustomMetricOption, MetricAttributeType } from '../SegmentsConstants';
import PrimaryInput from '../../../common/inputs/PrimaryInput';

interface CustomMetricDisplayProps {
  next: () => void,
  addNewMetric: () => void,
  back: () => void,
  metricName: string,
  customMetric: CustomMetricOption | null,
  bounds: MetricSegment[],
};

function CustomMetricDisplay({ next, addNewMetric, back, metricName, customMetric, bounds } : Readonly<CustomMetricDisplayProps>) {
  const [metric, setMetric] = useState<string | null>(customMetric);
  const [name, setName] = useState(metricName);
  const [rows, setRows] = useState(bounds);
  const metricSelectionOptions = (
    <section className={'metric-selection-options'}>
      <label className={'label'}>{'Customise metric name'}</label>
      <PrimaryInput placeholder={'Metric name'} text={name} setText={setName} />
      <Dropdown defaultDisplayText={'Select metric'} options={CustomMetricOptions} select={setMetric} selected={metric} />
    </section>
  );
  const metricDisplay = <MetricTable segments={rows} setSegments={setRows} metricAttributeType={MetricAttributeType.Date} />;
  const metricButtons = (
    <section className={'metric-buttons'}>
      <OutlineButton content={'Back'} onClick={back} />
      <SubtlePlusButton content={'Add new metric'} onClick={addNewMetric} />
      <PrimaryButton content={'Next'} onClick={next} />
    </section>
  );
  return (
    <MetricDisplay
      title={'Create a new segment'}
      subtitle={'Create and define a new boundary for your segment'}
      metricSelectionOptions={metricSelectionOptions}
      metricDisplay={metricDisplay}
      metricButtons={metricButtons}
    />
  );
}

export default CustomMetricDisplay;