import './NavSubItem.scss';

import React from 'react';

import Button from '../../common/buttons/Button';

interface NavSubItemProps {
  text: string,
  selected: boolean,
  onClick: () => any,
  count?: number | null,
}

function NavSubItem({ text, selected, onClick, count = null } : NavSubItemProps) {
  const classNames = ['nav-sub-item', selected ? 'selected' : ''];
  const content = (
    <section className={'button-content'}>
      <span className={'text'}>{text}</span>
      <span className={'count'}>{count}</span>
    </section>
  )
  return (
    <Button content={content} additionalClassNames={classNames} onClick={onClick} />
  );
}

export default NavSubItem;
