import './VerificationCodeInput.scss';

import { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction, useRef, useState } from 'react';

import { useMediaQuery } from '../../hooks/MediaHooks';
import VerificationCodeDigit from './common/VerificationCodeDigit';

const CODE_LENGTH = 6;
const MOBILE_INPUT_INCREMENTS = 42;
const WEB_INPUT_INCREMENTS = 66;

const MOBILE_WIDTH = '24px';
const WEB_WIDTH = '32px';

interface VerifyPageProps {
  setError: (error: string) => void,
  verificationCode: string,
  setVerificationCode: Dispatch<SetStateAction<string>>,
  validateCode: (code: string) => void,
}

function VerificationCodeInput({ setError, verificationCode, setVerificationCode, validateCode }: VerifyPageProps) {
  const isMobile = useMediaQuery('(max-width: 480px)');

  // TODO: Add a loading UI state

  const digitRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState(false);
  const codeDigits = verificationCode.split('');
  const hideInput = !(codeDigits.length < CODE_LENGTH);

  const handleClick = () => {
    if (digitRef && digitRef.current) {
      digitRef.current.focus();
    }
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false)
  };

  const handleKeyUp = () => {
    if (verificationCode.length === CODE_LENGTH) {
      validateCode(verificationCode);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
  
    setVerificationCode((prevState) => {
      if (prevState.length >= CODE_LENGTH) {
        return prevState;
      }
      setError('');
      return prevState + value;
    })
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace") {
      setVerificationCode(prevState => prevState.slice(0, prevState.length - 1))
    }
  };

  // TODO: Add a loading state
  const selectedIndex = codeDigits.length < CODE_LENGTH ? codeDigits.length : CODE_LENGTH - 1;
  const verificationCodeDigits = [0, 1, 2, 3, 4, 5].map((index) => {
    const selected = codeDigits.length === index;
    const filled = codeDigits.length === CODE_LENGTH && index === CODE_LENGTH - 1;
    return (
      <VerificationCodeDigit
        key={`verification-digit-${index}`}
        codeDigit={codeDigits[index]}
        selected={selected}
        filled={filled}
        focused={focused}
      />
    );
  });

  // To achieve the effect seen when filling in the verification code, we increase the left-alignment increments on the input field
  // so it lines up with the input boxes. This increment changes based on mobile/web view.
  const inputLeftAlignment = selectedIndex * (isMobile ? MOBILE_INPUT_INCREMENTS : WEB_INPUT_INCREMENTS);

  return (
    <section className={'verification-code-container'} onClick={handleClick}>
      {verificationCodeDigits}
      <input
        value={''}
        ref={digitRef}
        className={'code-input'}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        style={{
          width: (isMobile ? MOBILE_WIDTH : WEB_WIDTH),
          top: '0px',
          bottom: '0px',
          left: `${inputLeftAlignment}px`,
          opacity: hideInput ? 0 : 1,
        }}
      />
    </section>
  )
}

export default VerificationCodeInput;
