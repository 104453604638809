import './EngagementsTable.scss';

import { useState, ReactNode } from 'react';
import { useNavigate } from 'react-router';

import Table, { TableHeaderProps } from '../../common/tables/Table';
import { EngagementStatus, Engagement, Variant } from '../EngagementsTypes';
import GreyButton from '../../common/buttons/composite/GreyButton';
import LightPurpleTag from '../../common/tags/LightPurpleTag';
import ChannelsCell from '../common/ChannelsCell';
import EllipsisDropdown from '../../common/dropdowns/EllipsisDropdown';
import PaginationBar from '../../common/tables/PaginationBar';
import TextViewerModal from '../../common/modals/TextViewerModal';
import { OptionType } from '../../common/dropdowns/DropdownConstants';
import { statusFilter } from '../helpers/EngagementHelpers';
import GreenTag from '../../common/tags/GreenTag';
import GreyTag from '../../common/tags/GreyTag';
import DarkPurpleTag from '../../common/tags/DarkPurpleTag';
import useAuth from '../../../context/AuthContext';
import OutlineTag from '../../common/tags/OutlineTag';
import LoadingRing from '../../common/loading/LoadingRing';
import PrimaryButton from '../../common/buttons/PrimaryButton';

const { Draft, Scheduled, Active, Complete, Archived, Generating, Review } = EngagementStatus;

enum EngagementMoreOption {
  Duplicate = 'Duplicate',
  Archive = 'Archive',
  Restore = 'Restore',
};

const { Restore, Duplicate, Archive } = EngagementMoreOption;

const ENGAGEMENTS_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Name', key: 'name' },
  { displayText: 'Description', key: 'description' },
  { displayText: 'Status', key: 'status' },
  { displayText: 'Edit', key: 'edit' },
  { displayText: 'Date', key: 'date' },
  { displayText: 'Channel', key: 'channel' },
  { displayText: 'Recipients', key: 'recipients' },
  { displayText: 'Variants', key: 'variants' },
  { displayText: 'More', key: 'more' },
];

function getEngagementStatus(status: EngagementStatus, archivedDate?: number | null): EngagementStatus {
  if (archivedDate) {
    return Archived;
  }
  return status;
};

function getEditText(status: EngagementStatus): string {
  switch (status) {
    case Draft:
      return 'Resume';
    case Scheduled:
    case Active:
      return 'Edit';
    case Complete:
    case Archived:
      return 'Results';
    case Generating:
    case Review:
      return 'Review';
    default:
      return 'Resume';
  }
};

function getMoreOptions(status: EngagementStatus): OptionType<EngagementMoreOption>[] {
  switch (status) {
    case Draft:
    case Scheduled:
    case Active:
    case Complete:
      return [
        { displayText: Duplicate, value: Duplicate },
        { displayText: Archive, value: Archive },
      ];
    case Archived:
      return [
        { displayText: Duplicate, value: Duplicate },
        { displayText: Restore, value: Restore },
      ];
    default:
      return [];
  }
};

function getStatusNode(status: EngagementStatus): ReactNode {
  switch (status) {
    case Draft:
    case Scheduled:
      return <LightPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    case Active:
      return <GreenTag content={status} additionalClassNames={['tag-cell']} />;
    case Complete:
      return <DarkPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    case Generating: {
      const content = (
        <section className={'tag-content'}>
          <LoadingRing color={'#000000'} />
          <span className={'tag-text'}>{'Loading'}</span>
        </section>
      );
      return <OutlineTag content={content} additionalClassNames={['tag-cell']} />;
    }
    case Review:
      return <LightPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    case Archived:
      return <GreyTag content={status} additionalClassNames={['tag-cell']} />;
    default:
      return null;
  }
}

const ENGAGEMENTS_MAX_ROWS = 5;

interface EngagementsTableProps {
  selectedStatus: EngagementStatus,
  engagements: Engagement[],
  setEngagements: (engagements: Engagement[]) => void,
  selectEngagement: (engagement: Engagement) => void,
};

function EngagementsTable({ selectedStatus, engagements = [], setEngagements, selectEngagement } : Readonly<EngagementsTableProps>) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [cursor, setCursor] = useState(0);
  const [textToDisplay, setTextToDisplay] = useState<string | null>(null);
  const displayText = (text: string) => () => setTextToDisplay(text);
  const updateEngagement = (engagementId: string | number, fieldName: string, value: any) => {
    const oldEngagement = engagements.find(({ _id }) => _id === engagementId);
    const index =  engagements.findIndex(({ _id }) => _id === engagementId);

    // TODO: Send back a request
    if (oldEngagement && index !== -1) {
      const newEngagements = [...engagements];
      newEngagements[index] = { ...oldEngagement, [fieldName]: value };
      setEngagements(newEngagements);
    }
    return;
  }
  const archiveEngagement = (engagementId: string) => updateEngagement(engagementId, 'archivedDate', Date.now());
  const restoreEngagement = (engagementId: string) => updateEngagement(engagementId, 'archivedDate', null);
  function getMoreOnClick(moreOption: string | number | string[] | number[], engagementId: string | null) {
    if (!engagementId) {
      return;
    }
    switch (moreOption) {
      case Duplicate:
        return () => {
          const engagement = user?.engagements.find(({ _id }) => _id === engagementId);
          if (engagement) {
            const { variants } = engagement;
            const newVariants = variants.map((variant: Variant) => ({ ...variant, _id: null, messages: [], recipients: [] }));
            selectEngagement({ ...engagement, status: Draft, _id: null, variants: newVariants });
            navigate('/engagements/create');
          }
        }
      case Archive:
        return () => archiveEngagement(engagementId);
      case Restore:
        return () => restoreEngagement(engagementId);
      default:
        return () => {}
    }
  }

  function getEditNode(engagementStatus: EngagementStatus, onClick: () => void) {
    switch (engagementStatus) {
      case Generating:
        return <PrimaryButton content={getEditText(engagementStatus)} additionalClassNames={['button-cell']} disabled={true} />
      default:
        return <GreyButton content={getEditText(engagementStatus)} additionalClassNames={['button-cell']} onClick={onClick} />
    }
  }
  const edit = (index: number, isReview: boolean) => {
    selectEngagement(engagements[index]);
    console.log({ engagement: engagements[index] });
    if (isReview) {
      navigate('/engagements/preview');
    }
    else {
      navigate('/engagements/create');
    }
  };
  const rows = engagements
    // TODO: handle filtering in the parent
    .filter((({ status, archivedDate }) => statusFilter(selectedStatus, getEngagementStatus(status, archivedDate))))
    .map(({ _id, name, description, status, date, channels, totalRecipients, totalVariants, archivedDate }, index) => {
      const engagementStatus = getEngagementStatus(status, archivedDate);
      const nameNode = <section className={'two-cell center short-text'} onClick={displayText(name)}>{name}</section>
      const descriptionNode = <section className={'two-cell long-text'} onClick={displayText(description)}>{description}</section>
      const statusNode = getStatusNode(engagementStatus);
      const editNode = getEditNode(engagementStatus, () => edit(index, status === Review));
      const dateText = date ? new Date(date).toLocaleDateString() : '';
      const dateNode = <section className={'one-cell'}>{dateText}</section>;
      const channelsNode = <ChannelsCell channels={channels} additionalClassNames={['one-cell']} />;
      const recipientsNode = <section className={'one-cell'}>{totalRecipients}</section>;
      const variantsNode = <section className={'one-cell'}>{totalVariants}</section>;
      const engagementMoreOptions = getMoreOptions(engagementStatus).map((option) => ({ ...option, onClick: getMoreOnClick(option.value, _id) }));
      const moreNode = <EllipsisDropdown options={engagementMoreOptions} additionalClassNames={['half-cell']} />;
      return {
        cells: [
          { key: 'name', value: nameNode },
          { key: 'description', value: descriptionNode },
          { key: 'status', value: statusNode },
          { key: 'edit', value: editNode },
          { key: 'date', value: dateNode },
          { key: 'channel', value: channelsNode },
          { key: 'recipients', value: recipientsNode }, 
          { key: 'variants', value: variantsNode },
          { key: 'more', value: moreNode },
        ]
      }
    });
  if (rows.length === 0) {
    return null;
  }
  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  return (
    <>
      { showingText && <TextViewerModal text={textToDisplay} close={closeTextToDisplayModal} /> }
      <section className={'engagements-table-container'}>
        <Table
          additionalClassNames={['engagements-table']}
          headers={ENGAGEMENTS_TABLE_HEADERS}
          rows={rows}
          cursor={cursor}
          maxRows={ENGAGEMENTS_MAX_ROWS}
        />
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={ENGAGEMENTS_MAX_ROWS}
            totalRows={rows.length}
          />
        </section>
      </section>
    </>
  )
}

export default EngagementsTable;

