import './DualButtonToggle.scss';

import { ReactNode } from 'react';

import Button from '../Button';

interface DualButtonToggleProps {
  additionalClassNames?: string[],
  leftButtonContent: string | ReactNode,
  rightButtonContent: string | ReactNode,
  leftButtonOnClick: () => void,
  rightButtonOnClick: () => void,
  leftSelected: boolean,
  rightSelected: boolean,
}


function DualButtonToggle({
  additionalClassNames = [],
  leftButtonContent,
  rightButtonContent,
  leftButtonOnClick,
  rightButtonOnClick,
  leftSelected,
  rightSelected,
} : Readonly<DualButtonToggleProps>) {
  return (
    <section className={['dual-button-toggle', ...additionalClassNames].join(' ')}>
      <Button content={leftButtonContent} onClick={leftButtonOnClick} additionalClassNames={leftSelected ? ['selected'] : []} />
      <Button content={rightButtonContent} onClick={rightButtonOnClick} additionalClassNames={rightSelected ? ['selected'] : []} />
    </section>
  )
}

export default DualButtonToggle