import './GenericCheckbox.scss';

import { useId, ReactNode } from 'react';

export interface GenericCheckboxProps {
  checked: boolean,
  setChecked: (checked: boolean) => void,
  label?: string | ReactNode | null,
  checkIcon: string,
  additionalClassNames?: string[],
  id?: string,
  disabled?: boolean,
};

function GenericCheckbox({ checked, setChecked, label = null, checkIcon, additionalClassNames = [], id = '', disabled = false } : Readonly<GenericCheckboxProps>) {
  const onChange = () => setChecked(!checked);
  const uuid = useId();
  const uniqueId = `${id}-${uuid}`;
  const classNames = ['generic-checkbox', checked ? 'checked' : '', disabled ? 'disabled' : '', ...additionalClassNames];
  return (
    <label className={classNames.join(' ')} htmlFor={uniqueId}>
      <input id={uniqueId} type={'checkbox'} checked={checked} onChange={onChange} disabled={disabled} />
      <div className={'checkbox-container'}>
        { checked && <img className={'icon'} src={checkIcon} alt={''} />}
      </div>
      { label !== null && <span className={'label-text'}>{label}</span> }
    </label>
  );
}

export default GenericCheckbox;