import './CaseStudyCard.scss';

import { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import PrimaryButton from '../common/buttons/PrimaryButton';

interface CaseStudyCardProps {
  title: string,
  previewUrl: string,
  navigationUrl: string,
}

function CaseStudyCard({ title, previewUrl, navigationUrl } : Readonly<CaseStudyCardProps>) : ReactElement<CaseStudyCardProps> {
  const navigate = useNavigate();
  const viewMore = () => navigate(navigationUrl);
  return (
    <section className={'case-study-card'}>
      <header className={'case-study-card-header'}>
        <h4 className={'title'}>{title}</h4>
      </header>
      <iframe
        className={'preview-iframe'}
        src={previewUrl}
        title={title}
        allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
        referrerPolicy={'strict-origin-when-cross-origin'}
        allowFullScreen
      />
      <PrimaryButton additionalClassNames={['view-more-button']} onClick={viewMore} content={'View more'} />
    </section>
  );
}

export default CaseStudyCard;