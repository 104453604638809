import './InteractionToServicesItem.scss';

import RemovableTag from '../../common/tags/composite/RemovableTag';
import OutlineTag from '../../common/tags/OutlineTag';

import useAuth from '../../../context/AuthContext';

import NEW_ICON from './assets/blue-dot-icon.svg';
import MultiselectDropdown from '../../common/dropdowns/MultiselectDropdown';

export interface InteractionToServices {
  _id: string,
  type: string,
  customerName: string,
  scheduledDate: number,
  transactionValue: number,
  transactionCurrency: string,
  interactionDetails: string,
  services: string[],
}


export interface InteractionToServicesItemProps {
  type: string,
  customerName: string,
  scheduledDate: number,
  transactionValue: number,
  transactionCurrency: string,
  interactionDetails: string,
  services: string[],
  setServices: (services: string[]) => void,
}

function InteractionToServicesItem({ type, customerName, scheduledDate, transactionValue, transactionCurrency, interactionDetails, services, setServices } : Readonly<InteractionToServicesItemProps>) {
  const { user } = useAuth();
  const availableServices = user?.services || [];
  const serviceOptions = availableServices.filter(({ name }) => name.length > 0).map(({ name, _id }) => ({ displayText: name, value: _id! }));
  const typeText = type === 'TRANSACTION' ? 'Transaction' : 'Appointment';
  const removeTag = (serviceId: string) => {
    setServices(services.filter(id => serviceId !== id));
  }
  const mappedServices = services
    .map((id) => {
      const service = availableServices.find(({ _id }) => _id === id);
      if (service) {
        return <RemovableTag content={service.name} removeTag={() => removeTag(id)} key={id} />;
      }
      return null;
    });
  const tagText = `${typeText}${interactionDetails ? `: ${interactionDetails}` : ''}`;
  const dateText = new Date(scheduledDate).toLocaleDateString();
  const hasTransactionValue = transactionValue !== null && transactionValue > 0;
  const customerNameText = `${dateText} ${customerName} ${hasTransactionValue ? `$${transactionValue} ${transactionCurrency}` : ''}`;

  // TODO: Define what a new interaction to service means
  const showNewIcon = false;
  return (
    <section className={'interaction-to-service-item'}>
      <section className={'interaction-to-service-header'}>
        <span className={'interaction-name'}>
          { showNewIcon && <img className={'new-icon'} src={NEW_ICON} alt={''} /> }
          <OutlineTag content={tagText} />
        </span>
        <span className={'customer-name'}>{customerNameText}</span>
      </section>
      <section className={'services-container'}>
        <MultiselectDropdown defaultDisplayText={'Tag services'} options={serviceOptions} selected={services} select={setServices} />
        {mappedServices}
      </section>
      <hr className={'divider'} />
    </section>
  )
};

export default InteractionToServicesItem;