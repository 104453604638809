import './ButtonContent.scss';

import LoadingRing from '../../loading/LoadingRing';

function BlackLoadingContent() {
  return (
    <section className={'button-content'}>
      <LoadingRing color={'#0F172A'} />
      {'Loading'}
    </section>
  )
}

export default BlackLoadingContent;