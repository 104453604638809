import api from "@flatfile/api";
import dayjs from 'dayjs';
import { TPrimitive, TRecordDataWithLinks } from "@flatfile/hooks";
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  DEFAULT_RECIPIENT,
  GLOBAL_DATE_FORMATS,
  Recipient,
  RecipientBulkImportResponse,
  DeliverabilityPreferences,
  BooleanPicklist,
  Status,
  InteractionSync,
  Interaction,
  DEFAULT_INTERACTION,
  DEFAULT_INTERACTION_SYNC
} from "../../components/people/PeopleConstants";
import { postSaveInteractions, postSaveOrUpdateRecipients, postSyncInteractions } from "../../apis/PeopleApi";
import { FlatfileRecord } from "@flatfile/plugin-record-hook";
import { RecordsWithLinks, RecordWithLinks } from "@flatfile/api/api";

interface contextKeys {
  jobId: string,
  workbookId: string,
}

interface submitListenerArguments {
  context: contextKeys
}

type recordFormattingListener = FlatfileRecord<TRecordDataWithLinks<TPrimitive>>;
type flatFileRecordType = TPrimitive | TPrimitive[];
type SheetRecordValue = Record<string, string>;
type SheetRecordValues = Record<string, SheetRecordValue>

dayjs.extend(customParseFormat);
const BATCH_SIZE = 200;
const FLATFILE_SET_RECORD_BATCH_SIZE = 4500;

const { SMS, EMAIL } = DeliverabilityPreferences;

export const recipientRecordFormattingListener = async (record : recordFormattingListener) => {
  const status : flatFileRecordType = record.get('status');
  if (status === null) {
    record.set('status', Status.ACTIVE);
  }
};

const deliverabilityPreferenceCalculator = (acceptsSMSMarketing: BooleanPicklist | undefined, acceptsEmailMarketing: BooleanPicklist | undefined) : DeliverabilityPreferences[] => {
  const preferences = [];
  if (acceptsSMSMarketing === BooleanPicklist.TRUE) {
    preferences.push(SMS);
  }
  if (acceptsEmailMarketing === BooleanPicklist.TRUE) {
    preferences.push(EMAIL);
  }
  return preferences;
};

/**
 * On submission of the sheet, retrieve all rows, reformat certain fields and submit in batches of BATCH_SIZE
 * @param param
 */
export const recipientSubmitListener = async ({ context: { jobId, workbookId }} : submitListenerArguments) => {
  const { data: workbookSheets } = await api.sheets.list({ workbookId });

  // Extract recipients from sheet into clean { key: value } format rather than default deeply nested object
  const recipients : (Recipient)[] = [];
  for (const sheet of workbookSheets) {
    const { data: records } = await api.records.get(sheet.id);
    records.records.forEach(({ values }) => {
      const formattedRow : Recipient = Object.keys(values)
        .reduce((rowValues, valueKey) => ({ 
          ...rowValues, 
          [valueKey]: values[valueKey].value
        }), DEFAULT_RECIPIENT);
      recipients.push(formattedRow);
    });
  }
  
  if (recipients.length === 0) {
    return;
  }

  // Do additional formatting on certain fields.
  const formattedRecipients = await Promise.all(recipients.map(async (recipient : Recipient) => {
    const { addedDate, mobileNumber, source, acceptsSMSMarketing, acceptsEmailMarketing } = recipient;
    // TODO: Do we need some sort of country or US identifier on businesses to determine which formats to check?
    return {
      ...recipient,
      addedDate: dayjs(addedDate, [...GLOBAL_DATE_FORMATS ]).toDate().getTime(),
      mobileNumber: mobileNumber.replace(/\s/g,''),
      referralSource: typeof source !== 'string' ? '' : source,
      preferences: deliverabilityPreferenceCalculator(acceptsSMSMarketing, acceptsEmailMarketing)
    }
  }));

  try {
    await api.jobs.ack(jobId, {
      info: 'Starting job to submit recipients to database.',
      progress: 0,
    });

    // Run upload in batches to avoid payload too large errors.
    const batchCount = Math.ceil(formattedRecipients.length / BATCH_SIZE);
    const responses : RecipientBulkImportResponse = {};
    for (let i = 0; i < batchCount; i++) {
      const from = i * BATCH_SIZE;
      const to = (i + 1) * BATCH_SIZE;

      responses[i] = await postSaveOrUpdateRecipients(formattedRecipients.slice(from, to));

      await api.jobs.ack(jobId, {
        info: `Processed ${(batchCount - 1) === i ? formattedRecipients.length : BATCH_SIZE * (i + 1)} recipients.`,
        progress: (100 / batchCount) * (i + 1),
      });
    }
    const failedBatches = Object.keys(responses).filter((responseKey : string) => !responses[responseKey].success);
    if (failedBatches.length === 0) {
      await api.jobs.complete(jobId, {
        outcome: {
          message: `Data successfully inserted into database.`,
        },
      });
    } else {
      throw new Error('Failed to submit data to database.');
    }
  }
  catch (error) {
    console.log(`database[error]: ${error}`);
    await api.jobs.fail(jobId, {
      outcome: {
        message: 'Failed to create recipients. Please try again later.'
      }
    })
  } 
};

/** INTERACTION LISTENERS **/
export const interactionSyncListener = async ({ context: { jobId, workbookId }} : submitListenerArguments) => {
  const { data: workbookSheets } = await api.sheets.list({ workbookId });
  
  // dataRecords are the flatfile records which have their unique flatfile row id. This is used later to update the "recipientIds"
  // field on the UI so the user can see which interactions have matching recipients as only those will be submitted to the database.
  const interactionSyncRecords : InteractionSync[] = [];
  let dataRecords : RecordsWithLinks = [];
  let sheetId : string = '';

  // Extract interactions from sheet, saving a copy of the original data records and also a version to format and send to the backend
  // to search for recipients to match to the interactions.
  for (const sheet of workbookSheets) {
    sheetId = sheet.id;
    const { data: records } = await api.records.get(sheet.id);
    dataRecords = records.records;
    records.records.forEach(({ id, values }) => {
      // sheets.push(record);
      const formattedRow : InteractionSync = Object.keys(values)
        .reduce((rowValues, valueKey) => ({ 
          ...rowValues, 
          [valueKey]: values[valueKey].value
        }), DEFAULT_INTERACTION_SYNC);
        interactionSyncRecords.push({ ...formattedRow, id });
    });
  }

  try {
    await api.jobs.ack(jobId, {
      info: 'Starting job to sync interactions with database.',
      progress: 0,
    });

    // Run upload in batches to avoid payload too large errors.
    const batchCount = Math.ceil(interactionSyncRecords.length / BATCH_SIZE);
    let numUpdatedRows = 0;
    let interactionsToSync : InteractionSync[] = [];
    for (let i = 0; i < batchCount; i++) {
      const from = i * BATCH_SIZE;
      const to = (i + 1) * BATCH_SIZE;

      // Sync interactions and retrieve the recipient matches
      const response = await postSyncInteractions(interactionSyncRecords.slice(from, to));
      const { syncedInteractions } = response;

      // Build up the list of interactions from each batch.
      interactionsToSync = [ ...interactionsToSync, ...syncedInteractions ];

      // Update progress bar.
      await api.jobs.ack(jobId, {
        info: `Processed ${(batchCount - 1) === i ? interactionSyncRecords.length : BATCH_SIZE * (i + 1)} interactions.`,
        progress: (100 / batchCount) * (i + 1),
      });
    }

    // Using dataRecords that was saved earlier, update all the records with the new recipientId in FlatFile
    // so that submit job can properly filter the interactions required for insertion.
    const updatedRecords = dataRecords.map((record : RecordWithLinks) => {
      const recipient = interactionsToSync.find((recipient : InteractionSync) => recipient.id === record.id);
      if (recipient) {
        numUpdatedRows += 1;
        record.values.recipientId.value = recipient.recipientId;
      }
      return record;
    });

    // Based on testing, api.records.update can only update a max of 5000 rows before it fails to update the remainder.
    // Therefore we need to batch it (using 4500 as the batch size to give a 500 record buffer just in case).
    const updateBatchCount = Math.ceil(updatedRecords.length / FLATFILE_SET_RECORD_BATCH_SIZE)
    for (let i = 0; i < updateBatchCount; i++ ) {
      const from = i * FLATFILE_SET_RECORD_BATCH_SIZE;
      const to = (i + 1) * FLATFILE_SET_RECORD_BATCH_SIZE;
      await api.records.update(sheetId, updatedRecords.slice(from, to));
    }
    
    await api.jobs.complete(jobId, {
      outcome: {
        message: `Successfully synced ${numUpdatedRows} rows with database. Check out recipient id column for successfully synced fields.`,
      },
    }); 
  }
  catch (error) {
    console.log(`database[error]: ${error}`);
    await api.jobs.fail(jobId, {
      outcome: {
        message: 'Failed to sync interactions. Please try again later.'
      }
    })
  } 
}

export const interactionSubmitListener = async ({ context: { jobId, workbookId }} : submitListenerArguments) => {
  const { data: workbookSheets } = await api.sheets.list({ workbookId });

  // dataRecords are the flatfile records which have their unique flatfile row id. This is used later to update the "done" field
  // on the UI so the user can see which interactions have matching recipients as only those will be submitted to the database.
  const sheets : Interaction[] = [];
  let dataRecords : RecordsWithLinks = [];
  let sheetId : string = '';

  // Extract interactions from sheet, saving a copy of the original data records and also a version to format and send to the backend
  // to search for recipients to match to the interactions.
  for (const sheet of workbookSheets) {
    sheetId = sheet.id;
    const { data: records } = await api.records.get(sheet.id);
    dataRecords = records.records;
    records.records.forEach(({id, values}) => {
      // sheets.push(interaction)
      const formattedRow : Interaction = Object.keys(values)
        .reduce((rowValues, valueKey) => ({ 
          ...rowValues, 
          [valueKey]: values[valueKey].value
        }), DEFAULT_INTERACTION);
      sheets.push({ ...formattedRow, id });
    });
  }

  // Format the interaction data to prepare for submission and filter out the interactions without recipientId field populated.
  const interactions = sheets.map((interaction : Interaction) => {
    const { interactionDetails, mobileNumber, scheduledDate } = interaction;
    const formattedMobile = mobileNumber ? mobileNumber.replace(/\s/g,'') : mobileNumber;
    const formattedScheduledDate = scheduledDate ? dayjs(scheduledDate, [ ...GLOBAL_DATE_FORMATS ]).toDate().getTime() : scheduledDate;
    return {
      ...interaction,
      mobileNumber: formattedMobile,
      interactionDetails: interactionDetails ? interactionDetails : '',
      scheduledDate: formattedScheduledDate,
    };
  })
    .filter(({ done, recipientId } : { done: boolean, recipientId: string }) => done !== true && recipientId);

  try {
    await api.jobs.ack(jobId, {
      info: 'Starting job to submit interactions with database.',
      progress: 0,
    });

    // Run upload in batches to avoid payload too large errors.
    const batchCount = Math.ceil(interactions.length / BATCH_SIZE);
    let numUpdatedRows = 0;
    let numDuplicatesFound = 0;
    let interactionsToInsert : string[] = [];
    for (let i = 0; i < batchCount; i++) {
      const from = i * BATCH_SIZE;
      const to = (i + 1) * BATCH_SIZE;

      const interactionBatch = interactions.slice(from, to);
      const response = await postSaveInteractions(interactionBatch);
      const { savedInteractions } = response;
      interactionsToInsert = [ ...interactionsToInsert, ...savedInteractions ];

      await api.jobs.ack(jobId, {
        info: `Processed ${(batchCount - 1) === i ? interactions.length : BATCH_SIZE * (i + 1)} interactions.`,
        progress: (100 / batchCount) * (i + 1),
      });
    }

    const updatedRecords = dataRecords.map((record: RecordWithLinks) => {
      const interaction = interactionsToInsert.find((interactionFlatFileId: string) => interactionFlatFileId === record.id);
      if (interaction) {
        numUpdatedRows += 1;
        record.values.done.value = true;
      }
      else {
        numDuplicatesFound += 1;
        record.values.done.value = false;
      }
      return record;
    });

    // Based on testing, api.records.update can only update a max of 5000 rows before it fails to update the remainder.
    // Therefore we need to batch it (using 4500 as the batch size to give a 500 record buffer just in case).
    const updateBatchCount = Math.ceil(updatedRecords.length / FLATFILE_SET_RECORD_BATCH_SIZE)
    for (let i = 0; i < updateBatchCount; i++ ) {
      const from = i * FLATFILE_SET_RECORD_BATCH_SIZE;
      const to = (i + 1) * FLATFILE_SET_RECORD_BATCH_SIZE;
      await api.records.update(sheetId, updatedRecords.slice(from, to));
    }

    await api.jobs.complete(jobId, {
      outcome: {
        message: `Successfully submitted ${numUpdatedRows}/${interactions.length} rows to the database. ${numDuplicatesFound} rows were skipped due to being duplicates based on Ref #. Check out the done column for successfully submitted rows.`,
      },
    }); 
  }
  catch (err) {
    console.error(`database[error]: ${err}`);
    await api.jobs.fail(jobId, {
      outcome: {
        message:
          'Failed to submit interactions. Please try again later.',
      },
    });
  }
};