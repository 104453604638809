import './PersonPage.scss';

import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';

import useAuth from '../../../../context/AuthContext';
import { postSaveOrUpdateInteraction, postSaveOrUpdateRecipients } from '../../../../apis/PeopleApi';
import { PERSON_DETAIL_FIELDS, PersonPageToggleOptions, Recipient, PersonPageToggle, DEFAULT_INTERACTION, Interaction, InteractionTypeText } from '../../PeopleConstants';

import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import PersonDetailField from '../common/PersonDetailField';
import AddUpdateRecipientModal from '../modals/AddUpdateRecipientModal';
import MultiOptionSwitch from '../../../common/switches/MultiOptionSwitch';
import AddUpdateInteractionModal from '../modals/AddUpdateInteractionModal';
import PrimaryPlusButton from '../../../common/buttons/composite/PrimaryPlusButton';
import InteractionListDisplay from '../displays/InteractionListDisplay';
import { getUser } from '../../../../apis/UserApi';

const { Interactions } = PersonPageToggle;

// Either a person comes in through URL, or through state.
function PersonPage() {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [person, setPerson] = useState(state && state.recipient ? state.recipient : null);

  const [editRecipientModalOpen, setEditRecipientModalOpen] = useState(false);
  const [messageInteractionToggle, setMessageInteractionToggle] = useState('Messages');
  const [createInteractionModalOpen, setCreateInteractionModalOpen] = useState(false);
  const closeModal = () => setCreateInteractionModalOpen(false);

  if (!person) {
    return <Navigate to={'/login'} replace={true} state={{ path: '/people' }} />;
  }

  const currentTime = Date.now();
  const interactions: Interaction[] = person && person.interactions ? person.interactions : [];
  const pastInteractions = interactions
    .filter(interaction => interaction.scheduledDate <= currentTime)
    .sort((a, b) => b.scheduledDate - a.scheduledDate);

  const lastInteraction = pastInteractions && pastInteractions.length > 0
    ? pastInteractions[0]
    : null;

  const lastInteractionText = lastInteraction
    ? `${InteractionTypeText[lastInteraction.interactionType]} on ${new Date(lastInteraction.scheduledDate).toLocaleDateString('en-au', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })}`
    : 'None';

  const headerText = !!person ? `${person.firstName} ${person.lastName}` : 'Something went wrong';
  const subtitleText = !!person ? `Last interaction: ${lastInteractionText}` : 'We could not find this person. Please go back and try again.';

  const back = () => {
    const returnUrl = state && state.origin ? state.origin : '/people';
    navigate(returnUrl);
  }

  const updateRecipient = async (recipient: Recipient) => {
    const response = await postSaveOrUpdateRecipients([recipient]);
    console.log(response)
    const { success } = response;
    if (success) {
      setPerson(recipient);

      if (user !== null) {
        const userRecipients = user.recipients;
        const recipientIndex = userRecipients.findIndex(userRecipient => userRecipient._id === person._id);
        userRecipients[recipientIndex] = recipient;
        setUser({ ...user, recipients: userRecipients })
      }
    }
  }

  const createInteraction = async (interaction: Interaction) => {
    if (person._id !== null) {
      const newInteraction = { ...interaction, recipientId: person._id };

      const { success, recipient } = await postSaveOrUpdateInteraction(newInteraction);
      if (success && recipient !== null) {
        setPerson(recipient);

        if (user !== null) {
          const userRecipients = user.recipients;
          const recipientIndex = userRecipients.findIndex(userRecipient => userRecipient._id === person._id);
          userRecipients[recipientIndex] = recipient;
          setUser({ ...user, recipients: userRecipients })
        }
      }
    }
    closeModal();
  };

  const detailFields = PERSON_DETAIL_FIELDS.map(field => <PersonDetailField label={field.label} value={person[field.key as keyof Recipient]} />)
  const showInteractions = messageInteractionToggle === Interactions;

  const contentDisplay = showInteractions
    ? <InteractionListDisplay interactions={person.interactions} personId={person._id} setPerson={setPerson} />
    : <section></section>
  return (
    <article className={'person-page'}>
      <header className={'person-page-header'}>
        <section className={'header-title-section'}>
          <h2 className={'title'}>{headerText}</h2>
          <h3 className={'subtitle'}>{subtitleText}</h3>
        </section>
        <OutlineButton onClick={back} content={'Back'} />
      </header>
      <section className={'person-page-content'}>
        <section className={'content-header-section'}>
          <span>{'Details'}</span>
        </section>
        <section className={'content-button-section'}>
          <MultiOptionSwitch selectedOption={messageInteractionToggle} options={PersonPageToggleOptions} select={setMessageInteractionToggle} />
          {showInteractions && <PrimaryPlusButton onClick={() => setCreateInteractionModalOpen(true)} content={'Add new interaction'} additionalClassNames={['button-cell']} /> }
          {createInteractionModalOpen && (
            <AddUpdateInteractionModal
              close={closeModal}
              interactionToInsertUpdate={DEFAULT_INTERACTION}
              confirm={createInteraction}
            />
          )}
        </section>
        <section className={'content-detail-section'}>
          {detailFields}
          <PrimaryButton content={'Edit data fields'} onClick={() => setEditRecipientModalOpen(true)} />
          {editRecipientModalOpen && <AddUpdateRecipientModal close={() => setEditRecipientModalOpen(false)} recipientToInsertUpdate={person} confirm={updateRecipient} />}
        </section>
        <section className={'content-section'}>
          {contentDisplay}
        </section>
      </section>
    </article>
  );
}

export default PersonPage;