import './Table.scss';

import { ReactNode } from 'react';

interface TableCell {
  key: string,
  value: string | number | ReactNode,
};

export interface TableRow {
  cells: TableCell[],
};

export interface TableHeaderProps {
  displayText: string | ReactNode,
  key: string,
  className?: string,
};

interface TableProps {
  additionalClassNames?: string[],
  headers: TableHeaderProps[],
  rows: TableRow[],
  emptyTableDisplay?: string | ReactNode,
  tableName?: string,
  maxRows?: number | null,
  cursor?: number,
  freezeFirstColumn?: boolean,
};

function Table({
  additionalClassNames = [],
  headers = [],
  rows = [],
  emptyTableDisplay = null,
  tableName = '',
  maxRows = null,
  cursor = 0,
  freezeFirstColumn = true,
} : Readonly<TableProps>) {
  const classNames = ['table', freezeFirstColumn ? 'freeze-first-column' : '', ...additionalClassNames].join(' ');
  const tableDisplay = rows
    .slice(cursor, maxRows ? maxRows + cursor : rows.length)
    .map(({ cells }, index) => {
      const cellNodes = cells.map(({ key, value }, index) => <td key={`${tableName}-row-${key}-${index}`}>{value}</td>);
      return (
        <tr key={`${tableName}-row-${index}`}>
          {cellNodes}
        </tr>
      );
    });
  if (rows.length === 0 && emptyTableDisplay === null) {
    return null;
  }
  return (
    <table className={classNames}>
      <thead className={'table-header'}>
        <tr>
          {headers.map(({ key, displayText, className = '' }) => <th key={key} className={className}>{displayText}</th>)}
        </tr>
      </thead>
      <tbody className={'table-body'}>
        { rows.length === 0 ? emptyTableDisplay : tableDisplay }
      </tbody>
    </table>
  )
};

export default Table;