import './RecipientSelectionRow.scss';

import React from 'react';

import RecipientRowInformation from './RecipientRowInformation';
import BlackCheckbox from '../../../common/checkboxes/BlackCheckbox';

interface RecipientSelectionRowProps {
  name: string,
  emailAddress: string,
  mobileNumber: string,
  selected: boolean,
  setSelected: () => void,
  disabled: boolean,
};

function RecipientSelectionRow({ name, emailAddress, mobileNumber, selected, setSelected, disabled = false } : Readonly<RecipientSelectionRowProps>) {
  const label = <RecipientRowInformation name={name} emailAddress={emailAddress} mobileNumber={mobileNumber} />;
  return (
    <section className={`recipient-selection-row ${disabled ? 'disabled' : ''}`}>
      <BlackCheckbox checked={selected} setChecked={setSelected} disabled={disabled} label={label} />
    </section>
  );
}

export default RecipientSelectionRow;