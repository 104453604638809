import './VerifyPage.scss';

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LoginMethod } from './LoginConstants';
import { postSubmitMobileLogin, postSubmitEmailLogin, postVerifyEmailLogin, postVerifyMobileLogin } from '../../apis/LoginApi';
import useAuth from '../../context/AuthContext';
import LoginWrapper from './common/LoginWrapper';
import LoginHeader from './common/LoginHeader';
import VerificationCodeInput from './VerificationCodeInput';

const getCensoredText = (loginDetail: string, loginMethod: LoginMethod) => {
  if  (loginMethod === LoginMethod.SMS) {
    return `${'*'.repeat(loginDetail.length - 3)}${loginDetail.substring(loginDetail.length - 3, loginDetail.length)}`;
  }
  else {
    const emailSplit = loginDetail.split('@');
    const emailStart = emailSplit[0];
    const asterixes = '*'.repeat(emailStart.length - 1);
    return `${emailStart[0]}${asterixes}@${emailSplit[1]}`;
  }
}

interface VerifyPageProps {
  setShowingVerifyPage: (displayVerification: boolean) => void,
  loginDetail: string,
  loginMethod: LoginMethod,
}

function VerifyPage({ loginDetail, setShowingVerifyPage, loginMethod } : VerifyPageProps) {
  const [searchParams] = useSearchParams();
  const { login } = useAuth();
  const skipVerificationCode = !!searchParams.get('skip_verification_code') || false;
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const censoredText = getCensoredText(loginDetail, loginMethod);
  const isSMS = loginMethod === LoginMethod.SMS;
  const verificationSubtitle = isSMS
    ? `We've just sent a text message with a fresh verification code to the phone number ending in ${censoredText}.`
    : `We've just sent an email with a fresh verification code to the email address at ${censoredText}.`

  const verificationTitle = 'Enter the verification code.';
  const resendVerificationCode = async () => {
    setLoading(true);
    const postLogin = loginMethod === LoginMethod.SMS ? postSubmitMobileLogin : postSubmitEmailLogin;
    try {
      const success = await postLogin(loginDetail, skipVerificationCode);
      if (success) {
        setError('');
        setVerificationCode('');
      }
      setLoading(false);
    }
    catch (err) {
      console.error(err);
    }
  }

  const validateCode = async (code: string) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const postVerifyCode = loginMethod === LoginMethod.SMS ? postVerifyMobileLogin : postVerifyEmailLogin;
    try {
      const { success, user, token } = await postVerifyCode(loginDetail, code);
      if (success) {
        setError('');
        setVerificationCode('');
        login(user, token);
      }
      else {
        setError('Invalid verification code. Please try again.');
      }
      setLoading(false);
    }
    catch (err) {
      console.error(err);
      setError('Invalid verification code. Please try again.');
    }
  };

  const returnToLoginPage = () => setShowingVerifyPage(false);
  return (
    <LoginWrapper>
      <LoginHeader title={verificationTitle} subtitle={verificationSubtitle} />
      <section className={'verify-container'}>
        <p className={'sms-validation-error'}>{error}</p>
        <VerificationCodeInput
          setError={setError}
          verificationCode={verificationCode}
          setVerificationCode={setVerificationCode}
          validateCode={validateCode}
        />
        <button className={'resend-button'} onClick={resendVerificationCode} type={'button'} disabled={loading}>{'Click here to resend...'}</button>
        <button className={'new-verification-method'} onClick={returnToLoginPage} type={'button'}>{'Choose another method'}</button>
      </section>
    </LoginWrapper>
  )
}

export default VerifyPage;
