import './AddUpdateInteractionModal.scss';

import React, { useState } from 'react';

import Modal from '../../../common/modals/Modal';
import PrimaryInput from '../../../common/inputs/PrimaryInput';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import OutlineButton from '../../../common/buttons/OutlineButton';
import Dropdown from '../../../common/dropdowns/Dropdown';
import DateInput from '../../../common/inputs/DateInput';
import ModalField from './ModalField';

import { DEFAULT_INTERACTION, Interaction, InteractionOptions, InteractionType } from '../../PeopleConstants';

// Need recipientId to know which recipient to update.
interface AddUpdateInteractionModalProps {
  close: () => void,
  interactionToInsertUpdate: Interaction,
  confirm: (interaction: Interaction) => void,
}

function AddUpdateInteractionModal({ close, interactionToInsertUpdate, confirm } : Readonly<AddUpdateInteractionModalProps>) {
  const [interaction, setInteraction] = useState<Interaction>(interactionToInsertUpdate);
  const { interactionDetails, interactionType, scheduledDate } = interaction;
  const setInteractionType = (interactionType: InteractionType) => setInteraction({ ...interaction, interactionType });
  const setInteractionDetails = (interactionDetails: string) => setInteraction({ ...interaction, interactionDetails });
  const setScheduledDate = (scheduledDate: number) => setInteraction({ ...interaction, scheduledDate: scheduledDate });
  const confirmChanges = () => {
    close();
    confirm(interaction);
  }

  const formattedDate = scheduledDate ? new Date(scheduledDate) : new Date();
  const preventSubmit = !interactionType || !scheduledDate;
  return (
    <Modal additionalClassNames={['add-interaction-modal']}>
      <header className={'modal-header'}>
        <h4 className={'modal-title'}>
          {'Add interaction'}
        </h4>
        <h5 className={'modal-subtitle'}>
          {'Add the details for new interaction.'}
        </h5>
      </header>
      <section className={'modal-body'}>
        <section className={'interaction-container'}>
          <ModalField label={'Interaction type'} additionalClassNames={['interaction-type-field']}>
            <Dropdown options={InteractionOptions} defaultDisplayText={'Interaction type'} selected={interactionType} select={setInteractionType} />
          </ModalField>
          <ModalField label={'Select a date'}>
            <DateInput
              selectedDate={formattedDate}
              setDate={setScheduledDate}
            />
          </ModalField>
          <ModalField label={'Comments'}>
            <PrimaryInput id={'interaction'} placeholder={'Add comment'} text={interactionDetails} setText={setInteractionDetails} additionalClassNames={['interaction']} />
          </ModalField>
          {/* <Dropdown /> */}
        </section>
      </section>
      <section className={'modal-buttons'}>
        <OutlineButton content={'Cancel'} onClick={close} />
        <PrimaryButton content={'Confirm'} onClick={confirmChanges} disabled={preventSubmit} />
      </section>
    </Modal>
  );
}

export default AddUpdateInteractionModal