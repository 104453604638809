import './EngagementsPage.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router';

import PrimaryButton from '../../common/buttons/PrimaryButton';
import Dropdown from '../../common/dropdowns/Dropdown';
import { ENGAGEMENT_FILTER_OPTIONS } from '../EngagementsConstants';
import EngagementsTable from '../tables/EngagementsTable';
import { useLocalStorage, LocalStorageKey } from '../../../hooks/LocalStorageHooks';
import { Engagement } from '../EngagementsTypes';
import useAuth from '../../../context/AuthContext';
import { getEmptyEngagement } from '../helpers/EngagementHelpers';

const { CreateEngagementKey } = LocalStorageKey;

function EngagementsPage() {
  const { user } = useAuth();
  const emptyEngagement = getEmptyEngagement(user?.firstName, user?.business?.name);
  const initialEngagements = user && user.engagements ? user.engagements : [];
  const [engagements, setEngagements] = useState<Engagement[]>(initialEngagements);
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSelectedEngagement, clearSelectedEngagement] = useLocalStorage<Engagement>(CreateEngagementKey, emptyEngagement);
  const [selectedFilter, setSelectedFilter] = useState(ENGAGEMENT_FILTER_OPTIONS[0].value);
  const navigate = useNavigate();
  const createNewEngagement = () => {
    clearSelectedEngagement();
    navigate('/engagements/create');
  }
  return (
    <article className={'engagements-page'}>
      <header className={'engagements-page-header'}>
        <h2 className={'title'}>{'Engagements'}</h2>
        <h3 className={'subtitle'}>{'View, optimise, or create a new engagement campaign'}</h3>
        <section className={'engagements-buttons'}>
          <PrimaryButton content={'Create new'} onClick={createNewEngagement} />
          { engagements.length > 0 && (
              <Dropdown
                options={ENGAGEMENT_FILTER_OPTIONS}
                selected={selectedFilter}
                select={setSelectedFilter}
                additionalClassNames={['engagements-filter']}
              />
            )
          }
        </section>
      </header>
      <section className={'engagements-page-content'}>
        <EngagementsTable selectedStatus={selectedFilter} engagements={engagements} setEngagements={setEngagements} selectEngagement={setSelectedEngagement} />
      </section>
    </article>
  );
}

export default EngagementsPage;