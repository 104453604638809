import './SelectRecipientsTable.scss';

import { useState } from 'react';

import Table, { TableHeaderProps } from '../../common/tables/Table';
import SubtleButton from '../../common/buttons/SubtleButton';
import TrashIconButton from '../../common/buttons/composite/TrashIconButton'
import GreyButton from '../../common/buttons/composite/GreyButton';
import PaginationBar from '../../common/tables/PaginationBar';
import AddRecipientsModal from '../modals/add-recipients/AddRecipientsModal';
import { Variant } from '../EngagementsTypes';
import GenericSwitch from '../../common/switches/GenericSwitch';
import useAuth from '../../../context/AuthContext';
import _ from 'lodash';
import ConfirmVariantDeletionModal from '../modals/ConfirmVariantDeletionModal';
import Tooltip from '../../common/tooltips/Tooltip';

import RED_ALERT_ICON from '../assets/red-alert-icon.svg';

const TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Name', key: 'name' },
  { displayText: 'Recipients', key: 'recipients' },
  { displayText: 'No. of recipients', key: 'numberOfRecipients' },
  { displayText: 'View', key: 'view' },
  { displayText: 'Delete', key: 'delete' },
];

interface SelectRecipientTableProps {
  variants: Variant[],
  setVariants: (variants: Variant[]) => void,
}

const SELECT_RECIPIENTS_MAX_ROWS = 5;

const MINIMUM_NUMBER_OF_RECIPIENTS_FOR_GOOD_RESULTS = 30;

const ALL_VARIANTS = 'ALL_VARIANTS';

function SelectRecipientsTable({ variants, setVariants } : Readonly<SelectRecipientTableProps>) {
  const [cursor, setCursor] = useState(0);
  const [selectedVariantDeletionNumber, setSelectedVariantDeletionNumber] = useState<number | null>(null);
  const [selectedVariant, setSelectedVariant] = useState<string | number | null>(null);
  const [randomlyAssignRecipients, setRandomlyAssignRecipients] = useState(false);
  const { user } = useAuth();
  const userRecipients = user && user.recipients ? user.recipients : [];
  const recipients = userRecipients
    .map((recipient) => {
      const alreadySelectedRecipients = variants
        .filter((_, index) => selectedVariant === null || selectedVariant !== index)
        .flatMap(({ recipients }) => recipients);
      return {
        ...recipient,
        alreadySelected: alreadySelectedRecipients.includes(recipient._id),
      };
    });
  const setVariant = (variant: any, index: number) => {
    const newVariants = [...variants];
    newVariants[index] = variant;
    setVariants(newVariants);
  }
  const deleteRecipientSelection = (index: number) => setVariants(variants.filter((_ : Variant, i: number) => i !== index));
  const rows = variants.map(({ name, recipients } : Variant, index: number) => {
    const numRecipients = recipients.length;
    const selectRecipients = () => setSelectedVariant(index);
    const viewRecipients = () => setSelectedVariant(index);
    const nameNode = <section className={'two-cell'}>{name}</section>
    const recipientsNode = <SubtleButton content={'Add your recipients'} additionalClassNames={['button-cell']} onClick={selectRecipients} />;
    const showRecipientWarning = recipients.length < MINIMUM_NUMBER_OF_RECIPIENTS_FOR_GOOD_RESULTS;
    const numberOfRecipientsNode = (
      <section className={'two-cell warning-cell'}>
        <span className={'num-recipients'}>{numRecipients}</span>
        { showRecipientWarning && (
          <Tooltip content={'Less than 30 recipients in campaigns may lead to skewed results, we recommend having at least 30 per variant'}>
            <img className={'warning-icon'} src={RED_ALERT_ICON} alt={'Less than 30 recipients in campaigns may lead to skewed results, we recommend having at least 30 per variant'} />
          </Tooltip>
        )}
      </section>
    );
    const viewNode = <GreyButton content={'View'} additionalClassNames={['one-cell']} onClick={viewRecipients} />;
    const deleteNode = <TrashIconButton onClick={() => setSelectedVariantDeletionNumber(index)} additionalClassNames={['half-cell']} />;
    return {
      cells: [
        { key: 'name', value: nameNode },
        { key: 'recipients', value: recipientsNode },
        { key: 'numberOfRecipients', value: numberOfRecipientsNode },
        { key: 'view', value: viewNode },
        { key: 'delete', value: deleteNode },
      ]
    };
  });
  const showingVariant = selectedVariant !== null;
  const isDeletionModalOpen = selectedVariantDeletionNumber !== null;
  const closeModal = () => {
    setSelectedVariant(null);
  };
  const confirmRecipients = (selectedRecipients: string[]) => {
    if (showingVariant && typeof selectedVariant === 'number') {
      const variant = { ...variants[selectedVariant], recipients: selectedRecipients };
      setVariant(variant, selectedVariant);
    }
    else if (selectedVariant === ALL_VARIANTS) {
      // handle randomly assigning recipients
      const randomizedRecipients = _.shuffle(selectedRecipients);
      const chunkSize = Math.ceil(randomizedRecipients.length / variants.length);
      const newVariants = variants.map((variant, index) => {
        const randomRecipientSample = randomizedRecipients.slice(chunkSize * index, chunkSize * index + chunkSize);
        const newRecipients = [...variant.recipients, ...randomRecipientSample];
        return { ...variant, recipients: newRecipients }
      });
      setVariants(newVariants);
    }
    closeModal();
  };
  const initialSelectedRecipients = showingVariant && typeof selectedVariant === 'number' ? variants[selectedVariant].recipients : [];
  const checkRandomlyAssignRecipients = (shouldRandomlyAssignRecipients: boolean) => {
    setRandomlyAssignRecipients(shouldRandomlyAssignRecipients);
    if (shouldRandomlyAssignRecipients) {
      setSelectedVariant(ALL_VARIANTS);
    }
  };
  const closeDeletionModal = () => setSelectedVariantDeletionNumber(null);
  const deleteVariant = () => {
    if (isDeletionModalOpen) {
      deleteRecipientSelection(selectedVariantDeletionNumber);
      closeDeletionModal();
    }
  }
  return (
    <>
      <section className={'button-headers'}>
        <GenericSwitch label={'Randomly assign recipients'} checked={randomlyAssignRecipients} setChecked={checkRandomlyAssignRecipients} />
      </section>
      { showingVariant && (
          <AddRecipientsModal
            close={closeModal}
            recipients={recipients}
            confirm={confirmRecipients}
            initialSelectedRecipients={initialSelectedRecipients}
          />
        ) 
      }
      {
        isDeletionModalOpen && (
          <ConfirmVariantDeletionModal cancel={closeDeletionModal} confirm={deleteVariant} />
        )
      }
      <section className={'select-recipients-table-container'}>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['select-recipients-table']}
            headers={TABLE_HEADERS}
            rows={rows}
            cursor={cursor}
            maxRows={SELECT_RECIPIENTS_MAX_ROWS}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={SELECT_RECIPIENTS_MAX_ROWS}
            totalRows={rows.length}
          />
          </section>
      </section>
    </>
  );
}

export default SelectRecipientsTable;

