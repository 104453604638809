import { Filter, FILTER_ATTRIBUTES_MAP, FILTER_CONDITIONS_MAP } from "./FilterConstants";
import { VALUE_TYPES } from "../../../../Constants";
import { RecipientRow } from "../../../engagements/modals/add-recipients/RecipientSelectionTable";
import { Recipient } from "../../../people/PeopleConstants";

const { STRING, NUMBER, DATE } = VALUE_TYPES;

export function filterRecipients(recipients: RecipientRow[] | null, filters: Filter[]) {
  if (recipients) {
    return recipients.filter((recipient: RecipientRow | Recipient) => {
      // Only compute filters with conditions
      const populatedFilters = filters.filter((filter: Filter) => filter.condition);
      let validRecipient = true;
  
      for (const filter of populatedFilters) {
        const { attribute, condition, input } = filter;
        if (!attribute || !condition) {
          return false;
        }

        let recordVal = Object.hasOwn(recipient, attribute)
          ? recipient[attribute]
          : null;
  
        const attributeType = FILTER_ATTRIBUTES_MAP[attribute].type;
        const conditionObject = FILTER_CONDITIONS_MAP[attributeType][condition];
  
        if (!conditionObject) return false;
  
        let filterResult = false;
  
        const comparedValueTypes = [STRING, NUMBER, DATE];
        if (comparedValueTypes.includes(attributeType)) {
          filterResult = conditionObject.filterFunction(recordVal, input);
        }
  
        if (!filterResult) {
          return false;
        }
      }
  
      return validRecipient;
    })
  }
  return [];
}