import './CreateEngagementPage.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router';

import { useLocalStorage, LocalStorageKey } from '../../../hooks/LocalStorageHooks';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import CreateEngagementTable from '../tables/CreateEngagementTable';
import OutlineButton from '../../common/buttons/OutlineButton';
import { Engagement } from '../EngagementsTypes';
import GenericSwitch from '../../common/switches/GenericSwitch';
import SubtleButton from '../../common/buttons/SubtleButton';
import { postSaveDraftEngagement } from '../../../apis/EngagementApi';
import { getEmptyEngagement } from '../helpers/EngagementHelpers';
import useAuth from '../../../context/AuthContext';
import { User } from '../../../types/UserTypes';

const { CreateEngagementKey } = LocalStorageKey;

function CreateEngagementPage() {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const emptyEngagement = getEmptyEngagement(user?.firstName, user?.business?.name);
  const [loadingDraftButton, setLoadingDraftButton] = useState(false);
  const [engagement, setEngagement, clearEngagement] = useLocalStorage<Engagement>(CreateEngagementKey, emptyEngagement);
  const { hyperpersonalise } = engagement;
  const setHyperpersonalise = (hyperpersonalise: boolean) => setEngagement({ ...engagement, hyperpersonalise });
  const saveDraft = async () => {
    setLoadingDraftButton(true);
    const { engagements } = await postSaveDraftEngagement(engagement);
    setUser({ ...user as User, engagements });
    clearEngagement();
    setLoadingDraftButton(false);
    navigate('/engagements');
  };
  const next = () => {
    navigate('/engagements/recipients');
  }
  const back = () => {
    navigate('/engagements');
  }
  return (
    <article className={'create-engagement-page'}>
      <section className={'page'}>
        <header className={'create-engagement-page-header'}>
          <h2 className={'title'}>{'Create your engagement campaign'}</h2>
          <h3 className={'subtitle'}>{'Populate details to create each campaign message and add variants'}</h3>
        </header>
        <section className={'button-headers'}>
          <GenericSwitch label={'Hyper-personalisation'} checked={hyperpersonalise} setChecked={setHyperpersonalise} />
        </section>
        <section className={'create-engagement-page-content'}>
          <CreateEngagementTable engagement={engagement} setEngagement={setEngagement} />
        </section>
      </section>
      <footer className={'footer'}>
        <section className={'footer-left'}>
          <OutlineButton content={'Back'} onClick={back} />
          <SubtleButton content={'Save as draft'} onClick={saveDraft} loading={loadingDraftButton} />
          <PrimaryButton content={'Next'} onClick={next} disabled={engagement.variants.length === 0} />
        </section>
        <section className={'footer-right'}>{'1/4 steps'}</section>
      </footer>
    </article>
  );
}

export default CreateEngagementPage;