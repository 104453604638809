import { useState } from 'react';

import OutlineButton from '../../../common/buttons/OutlineButton';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import MetricDisplay from './MetricDisplay';
import { Segment, Metric } from '../SegmentsConstants';
import CreateSegmentsTable from '../tables/CreateSegmentsTable';
import { OptionType } from '../../../common/dropdowns/DropdownConstants';
import { useNavigate } from 'react-router';
import { saveOrUpdateSegments } from '../../../../apis/PeopleApi';
import useAuth from '../../../../context/AuthContext';
import { User } from '../../../../types/UserTypes';

interface SegmentsDisplayProps {
  back: () => void,
  categories: OptionType<string[]>[],
  segments: Segment[],
  setSegments: (segments: Segment[]) => void,
  metrics: Metric[],
};


function SegmentsDisplay({ back, categories, segments, setSegments, metrics } : Readonly<SegmentsDisplayProps>) {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false); 
  const createSegments = async () => {
    setLoading(true);
    await saveOrUpdateSegments(segments, metrics);
    setUser({ ...user as User, userSegment: { segments, metrics }});
    setLoading(false);
    navigate('/people/segments')
  };
  const metricDisplay = <CreateSegmentsTable segments={segments} setSegments={setSegments} categories={categories} metrics={metrics} />;
  const metricButtons = (
    <section className={'metric-buttons'}>
      <OutlineButton content={'Back'} onClick={back} />
      <PrimaryButton content={'Save segments'} onClick={createSegments} loading={loading} />
    </section>
  );
  return (
    <MetricDisplay
      title={'View all new segments'}
      subtitle={'Define and name your customer segments'}
      metricSelectionOptions={null}
      metricDisplay={metricDisplay}
      metricButtons={metricButtons}
    />
  );
}

export default SegmentsDisplay;