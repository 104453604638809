import React from 'react';

import OutlineButton from '../OutlineButton';

import { ButtonProps } from '../ButtonConstants';

import PLUS_ICON from './assets/black-plus-icon.svg';

function OutlinePlusButton({ additionalClassNames = [], onClick, disabled, content, loading, loadingContent } : Readonly<ButtonProps>) {
  const classNames = ['outline-plus-button', ...additionalClassNames];
  const buttonContent = (
    <span className={'button-content'}>
      <img className={'icon'} src={PLUS_ICON} alt={''} />
      {content}
    </span>
  )
  return (
    <OutlineButton
      additionalClassNames={classNames}
      onClick={onClick}
      content={buttonContent}
      disabled={disabled}
      loading={loading}
      loadingContent={loadingContent}
    />
  )
}

export default OutlinePlusButton;