import './HyperpersonaliseBanner.scss';

import React from 'react';

import HYPERPERSONALISE_ICON from '../assets/hypersonalise-icon.svg';

function HyperpersonaliseBanner() {
  return (
    <section className={'hyperpersonalise-banner'}>
      <span className={'banner-text'}>{'This message is hyper-personalised'}</span>
      <img className={'banner-icon'} src={HYPERPERSONALISE_ICON} alt={''} />
    </section>
  );
}

export default HyperpersonaliseBanner;