import './BlackCheckbox.scss';

import { CheckboxProps } from './CheckboxConstants';
import GenericCheckbox from './GenericCheckbox';

import WHITE_CHECK_MARK_ICON from './assets/white-checkmark-icon.svg';

function BlackCheckbox({ checked, setChecked, label = null, id = '', disabled = false } : CheckboxProps) {
  return (
    <GenericCheckbox
      checked={checked}
      setChecked={setChecked}
      label={label}
      checkIcon={WHITE_CHECK_MARK_ICON}
      additionalClassNames={['black']}
      disabled={disabled}
      id={id}
    />
  );
}

export default BlackCheckbox;