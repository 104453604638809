import './AutoresponseForm.scss';

import React from 'react';

import FormItem from './FormItem';
import PrimaryInput from '../../common/inputs/PrimaryInput';
import TrashIconButton from '../../common/buttons/composite/TrashIconButton';

interface AutoresponseFormProps {
  reply: string,
  response: string,
  setReply: (reply: string) => void,
  setResponse: (response: string) => void,
  removeResponse: () => void,
};

function AutoresponseForm({ reply, response, setReply, setResponse, removeResponse } : Readonly<AutoresponseFormProps>) {
  return (
    <section className={'autoresponse-form'}>
      <FormItem label={'Customer reply'}>
        <PrimaryInput placeholder={'Add reply'} text={reply} setText={setReply} />
      </FormItem>
      <FormItem label={'Example response'}>
        <PrimaryInput placeholder={'Add example'} text={response} setText={setResponse} />
      </FormItem>
      <TrashIconButton onClick={removeResponse} />
    </section>
  );
}

export default AutoresponseForm;