import './DateTimeInput.scss';

import { useState } from 'react';

import { HOUR_MAP } from '../InputConstants';

import TimeSelector from '../time/TimeSelector';
import DateInput from '../DateInput';

interface DateTimeInputProps {
  minDate?: Date | undefined,
  selectedDate: Date,
  setDate: (input: number) => void,
}

// TODO: Update date formats for locality US vs Global
function DateTimeInput({ minDate = undefined, selectedDate, setDate } : DateTimeInputProps) {
  const [time, setTime] = useState({ hour: 9, minute: 0, am: true });

  const toggleAmPm = () => {
    const scheduledTime = new Date(selectedDate);
    const formattedHour = !time.am ? HOUR_MAP[time.hour].value : HOUR_MAP[time.hour].value + 12;
    scheduledTime.setHours(formattedHour, time.minute, 0, 0);
    setDate(scheduledTime.getTime());
    setTime({ ...time, am: !time.am });
  };

  const selectHour = (hour: number) => {
    const scheduledTime = new Date(selectedDate);
    const formattedHour = time.am ? HOUR_MAP[hour].value : HOUR_MAP[hour].value + 12;
    scheduledTime.setHours(formattedHour, time.minute, 0, 0);
    setDate(scheduledTime.getTime());
    setTime({ ...time, hour });
  }

  const selectMinute = (minute: number) => {
    const scheduledTime = new Date(selectedDate);
    const formattedHour = time.am ? HOUR_MAP[time.hour].value : HOUR_MAP[time.hour].value + 12;
    scheduledTime.setHours(formattedHour, minute, 0, 0);
    setDate(scheduledTime.getTime());
    setTime({ ...time, minute });
  }

  return (
    <section className={'date-time-picker'}>
      <DateInput minDate={minDate} selectedDate={selectedDate} setDate={setDate} />
      <TimeSelector time={time} selectHour={selectHour} selectMinute={selectMinute} toggleAmPm={toggleAmPm} />
    </section>
  );
}

export default DateTimeInput;