import React from 'react';

import CompetitorOffersTable from '../tables/CompetitorOffersTable';

function ViewCompetitorOffersDisplay() {
  return (
    <section className={'view-competitor-offers-display'}>
      <CompetitorOffersTable />
    </section>
  );
}

export default ViewCompetitorOffersDisplay;