import './CaseStudiesPage.scss';

import { ReactElement } from 'react';

import CaseStudyCard from './CaseStudyCard';

export enum CaseStudyType {
  FindLeads = 'Find Leads',
  RetainClients = 'Retain Clients',
};

interface CaseStudiesPageProps {
  type: CaseStudyType | null;
}

const caseStudies = [
  {
    key: 1,
    title: 'Send handwritten letters to new residents near you',
    previewUrl: 'https://www.youtube.com/embed/ZtYaqKAc5mA?si=ifgER9mx0h8w_7iO',
    navigationUrl: '/case-studies/handwritten-letters',
    type: CaseStudyType.FindLeads,
  },
  {
    key: 2,
    title: 'Offer a perks program for local business employees',
    previewUrl: 'https://www.youtube.com/embed/bRN7VOrrYiU?si=IyxtaxFXUjWY9fLI',
    navigationUrl: '/case-studies/perks-program',
    type: CaseStudyType.FindLeads,
  },
  {
    key: 3,
    title: 'Bring back your lost clients',
    previewUrl: 'https://www.youtube.com/embed/-KjeI0Gdxhg?si=pZA89YM--TU1Meeu',
    navigationUrl: '/case-studies/lost-clients',
    type: CaseStudyType.RetainClients,
  }
];


function CaseStudiesPage({ type } : CaseStudiesPageProps): ReactElement<CaseStudiesPageProps> {
  const caseStudyCards = caseStudies
    .filter(caseStudy => !type || caseStudy.type === type)
    .map(({ key, title, previewUrl, navigationUrl }) => 
      <CaseStudyCard key={key} title={title} previewUrl={previewUrl} navigationUrl={navigationUrl} />);
  return (
    <article className={'case-studies-page'}>
      <section className={'page'}>
        <header className={'case-studies-page-header'}>
          <h2 className={'title'}>{'Case Studies'}</h2>
          <h3 className={'subtitle'}>{'Choose a case study to implement.'}</h3>
        </header>
        <section className={'page-content'}>
          {caseStudyCards}
        </section>
      </section>
    </article>
  );
}

export default CaseStudiesPage;