import ENGAGEMENTS_ICON from './assets/engagements-icon.svg';
import PEOPLE_ICON from './assets/people-icon.svg';
import INBOX_ICON from './assets/inbox-icon.svg';
import SETTINGS_ICON from './assets/settings-icon.svg';
import INTEGRATIONS_ICON from './assets/integrations-icon.svg';

import SELECTED_ENGAGEMENTS_ICON from './assets/engagements-icon-selected.svg';
import SELECTED_PEOPLE_ICON from './assets/people-icon-selected.svg';
import SELECTED_INBOX_ICON from './assets/inbox-icon-selected.svg';
import SELECTED_SETTINGS_ICON from './assets/settings-icon-selected.svg';
import SELECTED_INTEGRATIONS_ICON from './assets/integrations-icon-selected.svg';

export enum SubNavigationItemKey {
  ALL_ENGAGEMENTS,
  VIEW_RESULTS,
  // TODO: Restore when recommended feature is implemented RECOMMENDED,
  ALL_PEOPLE,
  SEGMENTS,
  SERVICES,
  OFFERS,
  COMPETITORS,
  LEADS,
  CLIENTS,
};

export enum NavigationItemKey {
  ENGAGEMENTS,
  PEOPLE,
  INBOX,
  SETTINGS,
  INTEGRATIONS,
  HOME,
}

type NavigationSubItem = {
  key: SubNavigationItemKey,
  displayText: string,
  href: string,
}

export type NavigationItem = {
  key: NavigationItemKey,
  displayText: string,
  icon: string,
  selectedIcon: string,
  prefix: string,
  href: string,
  subItems: NavigationSubItem[],
}

export const CASE_STUDIES_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    key: NavigationItemKey.HOME,
    displayText: 'Home',
    icon: ENGAGEMENTS_ICON,
    selectedIcon: SELECTED_ENGAGEMENTS_ICON,
    prefix: '/case-studies/home',
    href: '/case-studies/home',
    subItems: [
      {
        key: SubNavigationItemKey.LEADS,
        displayText: 'Find leads',
        href: '/case-studies/home/leads',
      },
      {
        key: SubNavigationItemKey.CLIENTS,
        displayText: 'Retain clients',
        href: '/case-studies/home/clients',
      },
    ]
  },
]

export const SIDEBAR_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    key: NavigationItemKey.ENGAGEMENTS,
    displayText: 'Engagements',
    icon: ENGAGEMENTS_ICON,
    selectedIcon: SELECTED_ENGAGEMENTS_ICON,
    prefix: '/engagements',
    href: '/engagements',
    subItems: [
      {
        key: SubNavigationItemKey.ALL_ENGAGEMENTS,
        displayText: 'All engagements',
        href: '/engagements',
      },
      {
        key: SubNavigationItemKey.VIEW_RESULTS,
        displayText: 'View results',
        href: '/engagements/results',
      },
      /** TODO: Restore when recommended feature is implemented
      {
        key: SubNavigationItemKey.RECOMMENDED,
        displayText: 'Recommended',
        href: '/engagements/recommended',
      },
      */
    ]
  },
  {
    key: NavigationItemKey.PEOPLE,
    displayText: 'People',
    icon: PEOPLE_ICON,
    selectedIcon: SELECTED_PEOPLE_ICON,
    prefix: '/people',
    href: '/people/segments',
    subItems: [
      {
        key: SubNavigationItemKey.SEGMENTS,
        displayText: 'Segments',
        href: '/people/segments',
      },
      {
        key: SubNavigationItemKey.ALL_PEOPLE,
        displayText: 'All people',
        href: '/people',
      },
    ]
  },
  {
    key: NavigationItemKey.INBOX,
    displayText: 'Inbox',
    icon: INBOX_ICON,
    selectedIcon: SELECTED_INBOX_ICON,
    prefix: '/inbox',
    href: '/inbox',
    subItems: [],
  },
  {
    key: NavigationItemKey.SETTINGS,
    displayText: 'Settings',
    prefix: '/settings',
    href: '/settings/services',
    icon: SETTINGS_ICON,
    selectedIcon: SELECTED_SETTINGS_ICON,
    subItems: [
      {
        key: SubNavigationItemKey.SERVICES,
        displayText: 'Services',
        href: '/settings/services',
      },
      {
        key: SubNavigationItemKey.OFFERS,
        displayText: 'Offers',
        href: '/settings/offers',
      },
      {
        key: SubNavigationItemKey.COMPETITORS,
        displayText: 'Competitor offers',
        href: '/settings/competitors',
      },
    ]
  },
  {
    key: NavigationItemKey.INTEGRATIONS,
    displayText: 'Integrations',
    icon: INTEGRATIONS_ICON,
    selectedIcon: SELECTED_INTEGRATIONS_ICON,
    prefix: '/integrations',
    href: '/integrations',
    subItems: [],
  },
];