import { useEvent, useFlatfile, Workbook } from "@flatfile/react";

import LightPurplePlusButton from "../../../common/buttons/composite/LightPurplePlusButton";
import { interactionWorkbook } from "../../../../utils/flatfile/FlatFileConstants";
import { interactionSubmitListener, interactionSyncListener } from "../../../../utils/flatfile/FlatFileHelpers";

function InteractionFlatFileButton() {
  const { openPortal } = useFlatfile();

  useEvent('job:ready',
    { job: 'workbook:syncInteractions' },
    interactionSyncListener
  );

  useEvent('job:ready',
    { job: 'workbook:submitInteractions' },
    interactionSubmitListener
  );

  return (
    <>
      <LightPurplePlusButton content={'Bulk add interactions'} onClick={openPortal} />
      <Workbook config={interactionWorkbook} />
    </>
  )
};

export default InteractionFlatFileButton;