import './RecipientSelectionTable.scss';

import React, { useState } from 'react';

import RecipientSelectionRow from './RecipientSelectionRow';
import BlackCheckbox from '../../../common/checkboxes/BlackCheckbox';
import PaginationBar from '../../../common/tables/PaginationBar';
import { useWindowSize } from '../../../../hooks/WindowHooks';
import { Recipient } from '../../../people/PeopleConstants';

export interface RecipientRow extends Recipient {
  _id: string,
  alreadySelected: boolean,
}

interface RecipientSelectionTableProps {
  rows: RecipientRow[],
  selectedRows: string[],
  setSelectedRows: (rows: string[]) => void,
  searchText: string,
}

function RecipientSelectionTable({ rows = [], selectedRows, setSelectedRows, searchText } : Readonly<RecipientSelectionTableProps>) {
  const [cursor, setCursor] = useState(0);
  const availableRows = rows.filter(({ alreadySelected }) => !alreadySelected);
  const allSelected = selectedRows.length === availableRows.length && selectedRows.length !== 0;
  const setAllSelected = () => setSelectedRows(allSelected ? [] : availableRows.map(({ _id }) => _id.toString()));
  const { height } = useWindowSize();
  let MAX_RECIPIENTS_PER_PAGE = 10;
  if (height > 650 && height <= 720) {
    MAX_RECIPIENTS_PER_PAGE = 8;
  }
  else if (height <= 650) {
    MAX_RECIPIENTS_PER_PAGE = 6;
  }
  const rowList = rows
    .filter(({ firstName, lastName }) => {
      const name = `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}`;
      return name.toLowerCase().includes(searchText.toLowerCase());
    })
    .slice(cursor, cursor + MAX_RECIPIENTS_PER_PAGE)
    .map(({ _id, firstName, lastName, emailAddress, mobileNumber, alreadySelected }) => {
      const name = `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}`;
      const selected = selectedRows.includes(_id);
      const selectRow = () => setSelectedRows(selected ? selectedRows.filter(rowId => rowId !== _id) : [...selectedRows, _id]);
      return (
        <RecipientSelectionRow
          key={_id}
          name={name}
          emailAddress={emailAddress}
          mobileNumber={mobileNumber}
          disabled={alreadySelected}
          selected={selected || alreadySelected}
          setSelected={selectRow}
        />
      )
    });
  return (
    <section className={'recipient-selection-table'}>
      <section className={'select-all-container'}>
        <BlackCheckbox checked={allSelected} setChecked={setAllSelected} label={`Select all (${availableRows.length})`} />
      </section>
      <section className={'recipient-rows'}>
        {rowList}
      </section>
      <PaginationBar cursor={cursor} setCursor={setCursor} maxRowsPerPage={MAX_RECIPIENTS_PER_PAGE} totalRows={rows.length} />
    </section>
  )
}

export default RecipientSelectionTable;