import { getData, postData } from './Api';

import { Recipient, RecipientId, Interaction, InteractionSync } from '../components/people/PeopleConstants';
import { RecipientRow } from '../components/engagements/modals/add-recipients/RecipientSelectionTable';
import { Metric, Segment } from '../components/people/segments/SegmentsConstants';

const PostPeopleUrls = {
  GetRecipientsUrl: '/api/people/recipients',
  GetFilteredRecipientsUrl: '/api/people/recipients/filtered',
  SaveOrUpdateRecipientsUrl: '/api/people/recipients/saveOrUpdate',
  DeleteRecipientUrl: '/api/people/recipient/delete',
  SaveOrUpdateInteractionUrl: '/api/people/interaction/saveOrUpdate',
  SaveInteractionsUrl: '/api/people/interactions/save',
  SyncInteractionsUrl: '/api/people/interactions/sync',
  MapInteractionsToServicesUrl: '/api/people/interactions/servicesMap',
  SaveOrUpdateSegmentsUrl: '/api/people/segments/saveOrUpdate',
  UpdateInteractionServicesUrl: '/api/people/interactions/services/update',
};

const {
  GetRecipientsUrl,
  GetFilteredRecipientsUrl,
  SaveOrUpdateRecipientsUrl,
  DeleteRecipientUrl,
  SyncInteractionsUrl,
  SaveInteractionsUrl,
  SaveOrUpdateInteractionUrl,
  MapInteractionsToServicesUrl,
  SaveOrUpdateSegmentsUrl,
  UpdateInteractionServicesUrl
} = PostPeopleUrls;

interface FindRecipientsWithFiltersRequest {
  page: number,
  searchText?: string
};

interface FindRecipientsWithFiltersResponse {
  success: boolean,
  recipients: Recipient[]
};

interface SaveOrUpdateRecipientsRequest {
  recipients: Recipient[]
};

interface SaveOrUpdateRecipientsResponse {
  success: boolean,
  recipientIds: RecipientId[]
};

interface SyncInteractionsRequest {
  interactions: InteractionSync[]
};

interface SyncInteractionsResponse {
  success: boolean,
  syncedInteractions: InteractionSync[]
};

interface SaveInteractionsRequest {
  interactions: Interaction[]
};

interface SaveInteractionsResponse {
  success: boolean,
  savedInteractions: string[]
};

interface SaveUpdateInteractionsRequest {
  interaction: Interaction
};

interface SaveUpdateInteractionResponse {
  success: boolean,
  recipient: Recipient
};

interface UpdateInteractionsToServicesMapResponse {
  mapped: string[],
  unmapped: string[],
  success: boolean,
};

interface DeleteRecipientResponse {
  success: boolean
};

/**
 * Retrieves the user from the server.
 */
export async function getRecipients(): Promise<{ success: boolean, recipients: RecipientRow[]}> {
  return getData(GetRecipientsUrl);
}

/**
 * Retrieves the user from the server.
 */
export async function getRecipientsWithFilters(page: number, searchText: string = ''): Promise<FindRecipientsWithFiltersResponse> {
  const { success, recipients } = await postData<FindRecipientsWithFiltersRequest, FindRecipientsWithFiltersResponse>(
    GetFilteredRecipientsUrl, { page, searchText }
  );
  return { success, recipients };
}

/**
 * Saves or updates a recipient. Returns a promise with the list of the recipientIds successfully saved or updated.
 */
export async function postSaveOrUpdateRecipients(recipients: Recipient[]): Promise<SaveOrUpdateRecipientsResponse> {
  const { success, recipientIds } = await postData<SaveOrUpdateRecipientsRequest, SaveOrUpdateRecipientsResponse>(
    SaveOrUpdateRecipientsUrl, { recipients }
  );
  return { success, recipientIds };
};

/**
 * Deletes a recipient. Returns a promise with a boolean on whether the deletion was successful or not.
 */
export async function postDeleteRecipient(recipientId: string): Promise<DeleteRecipientResponse> {
  const { success } = await postData<{ recipientId: string }, { success: boolean }>(
    DeleteRecipientUrl, { recipientId }
  );
  return { success };
};

/**
 * Syncs an interaction. Returns a promise with the list of recipients that match the interactions.
 */
export async function postSyncInteractions(interactions: InteractionSync[]): Promise<SyncInteractionsResponse> {
  const { success, syncedInteractions } = await postData<SyncInteractionsRequest, SyncInteractionsResponse>(
    SyncInteractionsUrl, { interactions }
  );
  return { success, syncedInteractions };
};

/**
 * Saves a list of interactions. Returns a promise with the list of interactionIds that got updated.
 */
export async function postSaveInteractions(interactions: Interaction[]): Promise<SaveInteractionsResponse> {
  const { success, savedInteractions } = await postData<SaveInteractionsRequest, SaveInteractionsResponse>(
    SaveInteractionsUrl, { interactions }
  );
  return { success, savedInteractions };
};

/**
 * Saves or updates an interaction. Returns a promise with the recipient enriched with the latest interaction data.
 */
export async function postSaveOrUpdateInteraction(interaction: Interaction): Promise<SaveUpdateInteractionResponse> {
  const { success, recipient } = await postData<SaveUpdateInteractionsRequest, SaveUpdateInteractionResponse>(
    SaveOrUpdateInteractionUrl, { interaction }
  );
  return { success, recipient };
};

/**
 * 
 */
export async function postMapInteractionsToServices() : Promise<UpdateInteractionsToServicesMapResponse> {
  const { success, mapped, unmapped } = await postData<object, UpdateInteractionsToServicesMapResponse>(
    MapInteractionsToServicesUrl, {}
  );
  return { success, mapped, unmapped };
}

/**
 * 
 */
export async function saveOrUpdateSegments(segments: Segment[], metrics: Metric[]): Promise<{ segments: Segment[], metrics: Metric[] }> {
  return postData(SaveOrUpdateSegmentsUrl, { segments, metrics });
}

/**
 * TODO:
 */
export async function postUpdateInteractionWithServices(interactionId: string, serviceIds: string[]) {
  return postData(UpdateInteractionServicesUrl, { interactionId, serviceIds });
}