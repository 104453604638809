import './AIPromptSection.scss';

import OutlineButton from '../../common/buttons/OutlineButton';

interface AIPromptSectionProps {
  openAIModal: () => void,
}

function AIPromptSection({ openAIModal } : AIPromptSectionProps) {
  return (
    <section className={'ai-prompt-section'}>
      <section className={'label'}>
        <h5 className={'label-title'}>{'Your AI prompt'}</h5>
        <p className={'label-text'}>{'View and edit your AI prompt...'}</p>
      </section>
      <OutlineButton content={'Edit prompt'} onClick={openAIModal} />
    </section>
  );
}

export default AIPromptSection;