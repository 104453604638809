import './NavItemContent.scss';

import React from 'react';

interface NavItemContentProps {
  icon: string,
  selectedIcon: string,
  text: string,
  selected: boolean,
  count?: number | null,
}

function NavItemContent({ icon, selectedIcon, text, selected, count = null } : NavItemContentProps) {
  const iconSrc = selected ? selectedIcon : icon;
  const hasCount = count !== 0 && count != null;
  const classNames = ['button-content', 'nav-item-content', selected ? 'selected' : ''].join(' ');
  return (
    <section className={classNames}>
      <section className={'label'}>
        <img className={'icon'} src={iconSrc} alt={''} />
        <span className={'text'}>{text}</span>
      </section>
      <span className={'count'}>{hasCount && count}</span>
    </section>
  );
}

export default NavItemContent;