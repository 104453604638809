import './SegmentsPage.scss';

import { useNavigate } from 'react-router';

import PrimaryButton from '../../../common/buttons/PrimaryButton';
import SegmentsTable from '../tables/SegmentsTable';
import { useLocalStorage, LocalStorageKey } from '../../../../hooks/LocalStorageHooks';
import { Metric, Segment, DEFAULT_METRICS } from '../SegmentsConstants';
import useAuth from '../../../../context/AuthContext';

function SegmentsPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const userSegments = user && user.userSegment && user.userSegment.segments ? user.userSegment.segments : [];
  const metrics = user && user.userSegment && user.userSegment.metrics ? user.userSegment.metrics : DEFAULT_METRICS;
  const clearMetrics = useLocalStorage<Metric[]>(LocalStorageKey.CreateSegmentMetricsKey, metrics)[2];
  const clearSegments = useLocalStorage<Segment[]>(LocalStorageKey.CreateSegmentsKey, userSegments)[2];
  const createSegment = () => {
    clearMetrics();
    clearSegments();
    navigate('/people/segments/create');
  }
  return (
    <article className={'segments-page'}>
      <header className={'segments-page-header'}>
        <h2 className={'title'}>{'Segments'}</h2>
        <h3 className={'subtitle'}>{'View, create and edit your customer segments'}</h3>
        <section className={'segments-buttons'}>
          <PrimaryButton content={'Create new'} onClick={createSegment} />
        </section>
      </header>
      <section className={'segments-page-content'}>
        <SegmentsTable />
      </section>
    </article>
  );
}

export default SegmentsPage;