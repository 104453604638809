import './ServicesPage.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router';

import ServicesTable from '../tables/ServicesTable';
import DualButtonToggle from '../../common/buttons/composite/DualButtonToggle';
import ServicesAIMappingDisplay from '../displays/ServicesAIMappingDisplay';
import OutlineButton from '../../common/buttons/OutlineButton';
import { postMapInteractionsToServices } from '../../../apis/PeopleApi';
import InteractionsToServicesMapResultModal from '../modals/InteractionsToServicesMapResultModal';

interface ServicesPageProps {
  isServiceDisplay: boolean,
};

function ServicesPage({ isServiceDisplay } : Readonly<ServicesPageProps>) {
  const navigate = useNavigate();
  const [isUpdatingMapping, setIsUpdatingMapping] = useState(false);
  const [mapping, setMapping] = useState<{ mapped: string[], unmapped: string[] } | null>(null);
  const onLeftClick = () => navigate('/settings/services');
  const onRightClick = () => navigate('/settings/services/map');
  const display = isServiceDisplay ? <ServicesTable /> : <ServicesAIMappingDisplay />;
  const updateMapping = async () => {
    setIsUpdatingMapping(true);
    const { mapped, unmapped } = await postMapInteractionsToServices();
    setMapping({ mapped, unmapped });
    setIsUpdatingMapping(false);
  }
  const showMappingResultModal = mapping !== null;
  const closeMappingResultModal = () => setMapping(null);
  return (
    <article className={'services-page'}>
      { showMappingResultModal && <InteractionsToServicesMapResultModal close={closeMappingResultModal} mappingResult={mapping} />}
      <header className={'services-page-header'}>
        <h2 className={'title'}>{'Services'}</h2>
        <h3 className={'subtitle'}>{'Add, edit, and view your services and products'}</h3>
      </header>
      <section className={'page-buttons'}>
        <DualButtonToggle
          leftButtonContent={'My services'}
          rightButtonContent={'AI mapping'}
          leftButtonOnClick={onLeftClick}
          rightButtonOnClick={onRightClick}
          leftSelected={isServiceDisplay}
          rightSelected={!isServiceDisplay}
        />
        <OutlineButton content={'Update mapping'} onClick={updateMapping} loading={isUpdatingMapping} />
      </section>
      <section className={'services-page-content'}>
        {display}
      </section>
    </article>
  );
}

export default ServicesPage;