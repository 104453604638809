import { Engagement } from '../components/engagements/EngagementsTypes';
import { postData } from './Api';

const PostEngagementUrls = {
  GenerateBaseMessageUrl: '/api/engagement/generate/baseMessage',
  GeneratePreviewsUrl: '/api/engagement/generate/previews',
  DraftEngagementUrl: '/api/engagement/draft',
  PublishEngagementUrl: '/api/engagement/publish',
  GenerateAllPreviewsUrl: '/api/engagement/generate/previews/all',
};

const { GenerateBaseMessageUrl, DraftEngagementUrl, GeneratePreviewsUrl, PublishEngagementUrl, GenerateAllPreviewsUrl } = PostEngagementUrls;

interface GenerateBaseMessageResponse {
  baseMessage: string,
};

interface GenerateBaseMessageRequest {
  campaignPurpose: string,
  offerId: string | null,
}

interface SaveEngagementResponse {
  engagements: Engagement[],
};

interface SaveEngagementRequest {
  engagement: Engagement,
};

interface GeneratePreviewsRequest {
  engagement: Engagement,
}

interface GeneratePreviewsResponse {
  engagement: Engagement,
}

/**
 * Generates the base message from variant details using AI. Returns the generated base message for
 * the variant based on campaign purpose, name and offer details.
 */
export async function postGenerateBaseMessage({ campaignPurpose, offerId } : GenerateBaseMessageRequest): Promise<GenerateBaseMessageResponse> {
  const { baseMessage } = await postData<GenerateBaseMessageRequest, GenerateBaseMessageResponse>(
    GenerateBaseMessageUrl, { campaignPurpose, offerId }
  );
  return { baseMessage };
}

/**
 * Saves a draft of an engagement along with its variants into the database.
 */
export async function postSaveDraftEngagement(engagement : Engagement) {
  const { engagements } = await postData<SaveEngagementRequest, SaveEngagementResponse>(
    DraftEngagementUrl, { engagement }
  );
  return { engagements };
}

/**
 * Generates previews for a set of variants in an engagement. Returns the engagement with the populated messages.
 */
export async function postGeneratePreviews(engagementToProcess: Engagement) {
  const { engagement } = await postData<GeneratePreviewsRequest, GeneratePreviewsResponse>(
    GeneratePreviewsUrl, { engagement: engagementToProcess }
  );
  return engagement;
}

/**
 * Publishes an engagement queuing it up to be sent to users.
 */
export async function postPublishEngagement(engagement : Engagement) {
  const { engagements } = await postData<SaveEngagementRequest, SaveEngagementResponse>(
    PublishEngagementUrl, { engagement }
  );
  return { engagements };
}

/**
 * Sets an engagement to the generating status to generate all previews for an engagement.
 */
export async function postGeneratePreviewsForEngagement(engagement : Engagement) {
  const { engagements } = await postData<SaveEngagementRequest, SaveEngagementResponse>(
    GenerateAllPreviewsUrl, { engagement }
  );
  return { engagements };
}