import './ChannelsCell.scss';

import React from 'react';

import { Channel } from '../EngagementsTypes';
import { CHANNEL_ICONS } from '../EngagementsConstants';
import Tooltip from '../../common/tooltips/Tooltip';

const { SMS, Email } = Channel;

interface ChannelsCellProps {
  channels: Channel[],
  additionalClassNames?: string[],
}

const getTooltipText = (channel: Channel) => {
  switch (channel) {
    case SMS:
      return 'This engagement is delivered over SMS';
    case Email:
      return 'This engagement is delivered over email';
    default:
      return '';
  }
}

function ChannelsCell({ channels, additionalClassNames = [] } : Readonly<ChannelsCellProps>) {
  const icons = CHANNEL_ICONS.map(({ icon, selectedIcon, key }) => {
    const selected = channels.includes(key);
    const iconComponent = <img key={key} className={'channel-icon'} src={selected ? selectedIcon : icon} alt={key} />;
    const disabled = !selected;
    return (
      <Tooltip key={key} content={getTooltipText(key)} disabled={disabled} additionalClassNames={['channel-tooltip']}>
        {iconComponent}
      </Tooltip>
    );
  });
  return (
    <section className={['channels-cell', ...additionalClassNames].join(' ')}>
      {icons}
    </section>
  );
}

export default ChannelsCell;