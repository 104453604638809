import './CompetitorOffersTable.scss';

import { useState, ReactNode } from 'react';
import { useNavigate } from 'react-router';

import Table, { TableHeaderProps, TableRow } from '../../common/tables/Table';
import PaginationBar from '../../common/tables/PaginationBar';
import EditTextModal from '../../common/modals/EditTextModal';
import { TextToDisplay } from '../../engagements/EngagementsConstants';
import { CompetitorOfferStatus } from '../CompetitorOffersConstants';
import RedTag from '../../common/tags/RedTag';
import GreenTag from '../../common/tags/GreenTag';
import OutlineButton from '../../common/buttons/OutlineButton';
import { Channel } from '../../engagements/EngagementsTypes';
import ChannelsCell from '../../engagements/common/ChannelsCell';

const { Live, Expired } = CompetitorOfferStatus;

const COMPETITOR_OFFERS_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Competitor name', key: 'competitorName' },
  { displayText: 'Date found', key: 'dateFound' },
  { displayText: 'Offer expiry', key: 'offerExpiry' },
  { displayText: 'Offer status', key: 'offerStatus' },
  { displayText: 'Offer name', key: 'offerName' },
  { displayText: 'Channels', key: 'channels' },
  { displayText: 'Offer rules', key: 'offerRules' },
  { displayText: 'Use offer', key: 'useOffer' },
];

type CompetitorOffer = {
  competitorName: string,
  dateFound: string,
  expirationDate: string,
  status: CompetitorOfferStatus,
  offerName: string,
  offerRules: string,
  channels: Channel[],
};

const DUMMY_TABLE_DATA: CompetitorOffer[] = [
  {
    competitorName: 'Caci clinics',
    dateFound: '19/08/2024',
    expirationDate: '27/09/2024',
    status: Live,
    offerName: '$50 in-store credit on $200 minimum spend',
    offerRules: 'Terms apply, 12 months valid',
    channels: [Channel.SMS, Channel.Email],
  },
  {
    competitorName: 'Laser clinic aus',
    dateFound: '18/08/2024',
    expirationDate: '22/09/2024',
    status: Live,
    offerName: '50% off selected Laser Hair Removal Treatments now on',
    offerRules: 'Online offer only.',
    channels: [Channel.SMS, Channel.Email],
  },
  {
    competitorName: 'Silk laser clinics',
    dateFound: '17/08/2024',
    expirationDate: '25/09/2024',
    status: Live,
    offerName: 'UP TO 40% OFF ANNIVERSARY SALE',
    offerRules: 'To celebrate nine incredible years of helping YOU look and feel your most confident, you can still enjoy up to 40% OFF your favorite treatments for a limited time.',
    channels: [Channel.SMS, Channel.Email],
  },
  {
    competitorName: 'Caci clinics',
    dateFound: '07/07/2024',
    expirationDate: '11/08/2024',
    status: Expired,
    offerName: '10% off',
    offerRules: '10% off welcome offer available online only. Offer only available on glasses and sunglasses, excludes contact lenses, accessories, and optometry services. Not available in conjunction with any other offer or discount. Your code expires 30 days after receiving this email.',
    channels: [Channel.SMS, Channel.Email],
  },
  {
    competitorName: 'Caci clinics',
    dateFound: '01/07/2024',
    expirationDate: '01/08/2024',
    status: Expired,
    offerName: 'You can earn points for all sorts of online purchases, website visits and redeem them for great deals & discounts.',
    offerRules: `We've extended our sale! 💚`,
    channels: [Channel.SMS, Channel.Email],
  },
  {
    competitorName: 'Caci clinics',
    dateFound: '01/06/2024',
    expirationDate: '01/07/2024',
    status: Expired,
    offerName: '$50 in-store credit on $200 minimum spend',
    offerRules: `Terms apply, 12 months valid`,
    channels: [Channel.SMS, Channel.Email],
  },
];

function getStatusNode(status: CompetitorOfferStatus): ReactNode {
  switch (status) {
    case Live:
      return <GreenTag content={status} />;
    case Expired:
      return <RedTag content={status} />;
    default:
      return null;
  }
};

const COMPETITOR_OFFERS_MAX_ROWS = 5;

function CompetitorOffersTable() {
  const navigate = useNavigate();
  const [textToDisplay, setTextToDisplay] = useState<TextToDisplay | null>(null);
  const [rows, setRows] = useState<CompetitorOffer[]>(DUMMY_TABLE_DATA);
  const [cursor, setCursor] = useState(0);
  const setCompetitorOffer = (trackedOffer: CompetitorOffer, index: number) => {
    const newCompetitorOffers = [...rows];
    newCompetitorOffers[index] = trackedOffer;
    setRows(newCompetitorOffers);
  }
  const displayText = (text: string, index: number, fieldName: string) => () => setTextToDisplay({ text, index, fieldName });
  const tableRows: TableRow[] = rows.map(({ competitorName, dateFound, expirationDate, status, offerName, offerRules, channels } : CompetitorOffer, index: number) => {
    const competitorNameNode = <section className={'two-cell center short-text'} onClick={displayText(competitorName, index, 'competitorName')}>{competitorName}</section>;
    const dateFoundNode = <section className={'one-cell'}>{dateFound}</section>;
    const offerExpiryNode = <section className={'one-cell'}>{expirationDate}</section>;
    const offerStatusNode = <section className={'status-cell'}>{getStatusNode(status)}</section>;;
    const offerNameNode = <section className={'two-cell center long-text'} onClick={displayText(offerName, index, 'offerName')}>{offerName}</section>;
    const offerRulesNode = <section className={'two-cell center long-text'} onClick={displayText(offerRules, index, 'offerRules')}>{offerRules}</section>;

    // TODO: Replace ID
    const useOffer = () => navigate('/settings/offers', { state: { offer: { name: offerName, rules: offerRules, _id: 1 }}});
    const useOfferNode = <section className={'button-cell'}><OutlineButton content={'Use'} onClick={useOffer} /></section>;
    const channelsNode = <ChannelsCell channels={channels} additionalClassNames={['one-cell']} />;;
    return {
      cells: [
        { key: 'competitiorName', value: competitorNameNode },
        { key: 'dateFound', value: dateFoundNode },
        { key: 'offerExpiry', value: offerExpiryNode },
        { key: 'offerStatus', value: offerStatusNode },
        { key: 'offerName', value: offerNameNode },
        { key: 'channelsNode', value: channelsNode },
        { key: 'offerRules', value: offerRulesNode },
        { key: 'useOffer', value: useOfferNode },
      ]
    };
  });
  if (rows.length === 0) {
    return null;
  }

  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  const confirmTextChanges = (text: string) => {
    if (textToDisplay) {
      const { index, fieldName } = textToDisplay;
      setCompetitorOffer({ ...rows[index], [fieldName]: text }, index);
    }
    closeTextToDisplayModal();
  }
  const modalText = textToDisplay ? textToDisplay.text : '';
  return (
    <>
      { showingText && <EditTextModal text={modalText} confirm={confirmTextChanges} close={closeTextToDisplayModal} /> }
      <section className={'competitor-offers-table-container'}>
        <Table
          additionalClassNames={['competitor-offers-table']}
          headers={COMPETITOR_OFFERS_TABLE_HEADERS}
          rows={tableRows}
          cursor={cursor}
          maxRows={COMPETITOR_OFFERS_MAX_ROWS}
        />
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={COMPETITOR_OFFERS_MAX_ROWS}
            totalRows={tableRows.length}
          />
        </section>
      </section>
    </>
  )
}

export default CompetitorOffersTable;

