import { Metric } from '../common/multi-select-rows/metrics/MetricConstants';
import { PersonalizationVariable } from './helpers/AIPromptHelpers';

export enum Channel {
  SMS = 'SMS',
  Email = 'Email',
};

export enum ForwardingPreference {
  NONE = 'None',
  ALL_REPLIES = 'All replies',
  ONLY_REPLIES_THAT_MAY_REQUIRE_CUSTOMER_ACTION = 'Only replies that may require customer action',
};

export enum EngagementStatus {
  All = 'All',
  Draft = 'Draft',
  Scheduled = 'Scheduled',
  Active = 'Active',
  Complete = 'Complete',
  Archived = 'Archived',
  Generating = 'Generating',
  Review = 'Review',
};

export enum DeliveryMethod {
  WorkRex = 'WorkRex (default)',
  MailChimp = 'MailChimp',
  Twilio = 'Twilio',
};

export type Autoresponse = {
  reply: string,
  response: string,
};

export type EngagementAdvancedSettings = {
  forwardingPreference: ForwardingPreference | null,
  emailAddress: string,
  autoresponses: Autoresponse[],
}

export type RecipientDetails = {
  firstName: string,
  lastName: string,
  mobileNumber: string,
  emailAddress: string,
}

export type VariantMessage = {
  recipientId: string,
  recipient: RecipientDetails,
  message: string,
  prompt: string,
  scheduledSentDate: string | null,
}

export type Variant = {
  _id: string | null,
  name: string,
  campaignPurpose: string,
  offer: string | null,
  channel: Channel | null,
  baseMessage: string,
  advancedSettings: EngagementAdvancedSettings,
  recipients: string[],
  messages: VariantMessage[],
};

export type Engagement = {
  _id: string | null,
  name: string,
  description: string,
  status: EngagementStatus,
  channels: Channel[],
  totalVariants: number,
  totalRecipients: number,
  variants: Variant[],
  prompt: string,
  personalizationVariables: PersonalizationVariable[],
  date: number | null,
  batchSend: string,
  attribution: string,
  metrics: Metric[],
  deliveryMethod: string | null,
  archivedDate: number | null,
  showUnsubscribeMessage: boolean,
  unsubscribeMessage: string,
  showSignature: boolean,
  signature: string,
  hyperpersonalise: boolean,
};
