import './Modal.scss';

import { ReactNode } from 'react';

interface ModalProps {
  children: ReactNode,
  additionalClassNames?: string[],
}

function Modal({ children, additionalClassNames = [] } : Readonly<ModalProps>) {
  return (
    <section className={`modal ${additionalClassNames.join(' ')}`}>
      <section className={'modal-content'}>
        {children}
      </section>
    </section>
  )
}

export default Modal;