export interface HOUR {
  key: number,
  value: number,
  text: string
}
export interface HOUR_TYPES {
  [key: number | string]: HOUR
}

export const HOUR_MAP : HOUR_TYPES = {
  1: {
    key: 1,
    value: 1,
    text: '01',
  },
  2: {
    key: 2,
    value: 2,
    text: '02',
  },
  3: {
    key: 3,
    value: 3,
    text: '03',
  },
  4: {
    key: 4,
    value: 4,
    text: '04',
  },
  5: {
    key: 5,
    value: 5,
    text: '05',
  },
  6: {
    key: 6,
    value: 6,
    text: '06',
  },
  7: {
    key: 7,
    value: 7,
    text: '07',
  },
  8: {
    key: 8,
    value: 8,
    text: '08',
  },
  9: {
    key: 9,
    value: 9,
    text: '09',
  },
  10: {
    key: 10,
    value: 10,
    text: '10',
  },
  11: {
    key: 11,
    value: 11,
    text: '11',
  },
  12: {
    key: 12,
    value: 0,
    text: '12',
  }
};

export interface MINUTE {
  key: number,
  text: string
}

interface MINUTE_TYPES {
  [key: number | string]: MINUTE
};

export const MINUTE_MAP : MINUTE_TYPES = {
  0: {
    key: 0,
    text: '00',
  },
  5: {
    key: 5,
    text: '05',
  },
  10: {
    key: 10,
    text: '10',
  },
  15: {
    key: 15,
    text: '15',
  },
  20: {
    key: 20,
    text: '20',
  },
  25: {
    key: 25,
    text: '25',
  },
  30: {
    key: 30,
    text: '30',
  },
  35: {
    key: 35,
    text: '35',
  },
  40: {
    key: 40,
    text: '40',
  },
  45: {
    key: 45,
    text: '45',
  },
  50: {
    key: 50,
    text: '50',
  },
  55: {
    key: 55,
    text: '55',
  }
};