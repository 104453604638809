import PrimaryButton from '../PrimaryButton';

import PLUS_ICON from './assets/white-plus-icon.svg';

import { ButtonProps } from '../ButtonConstants';

function PrimaryPlusButton({ additionalClassNames = [], onClick, disabled, content, loading, loadingContent } : Readonly<ButtonProps>) {
  const classNames = ['primary-plus-button', ...additionalClassNames];
  const buttonContent = (
    <span className={'button-content'}>
      <img className={'icon'} src={PLUS_ICON} alt={''} />
      {content}
    </span>
  )
  return <PrimaryButton
    additionalClassNames={classNames}
    onClick={onClick}
    content={buttonContent}
    disabled={disabled}
    loading={loading}
    loadingContent={loadingContent}
  />
}

export default PrimaryPlusButton;