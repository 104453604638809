import { Interaction } from "../../PeopleConstants";
import InteractionRow from "./InteractionRow";

interface InteractionListProps {
  interactions: Interaction[],
  listHeading: string,
  updateInteraction: (interaction: Interaction) => void;
}

function InteractionList({ interactions, listHeading, updateInteraction }: InteractionListProps) {
  const interactionRows = interactions.map(interaction => 
    <InteractionRow interaction={interaction} confirm={updateInteraction} />
  );
  
  return (
    <section className={'interaction-list-section'}>
      <span className={'interaction-section-header'}>{listHeading}</span>
      <section className={'interaction-list'}>
        {interactionRows}
      </section>
    </section>
  )
}

export default InteractionList;