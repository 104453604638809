import './OffersTable.scss';

import { useState, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router';

import Table, { TableHeaderProps } from '../../common/tables/Table';
import Dropdown from '../../common/dropdowns/Dropdown';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import LightPurpleTag from '../../common/tags/LightPurpleTag';
import GreenTag from '../../common/tags/GreenTag';
import PrimaryPlusButton from '../../common/buttons/composite/PrimaryPlusButton';
import { TextToDisplay } from '../../engagements/EngagementsConstants';
import EditTextModal from '../../common/modals/EditTextModal';
import PaginationBar from '../../common/tables/PaginationBar';
import EllipsisDropdown from '../../common/dropdowns/EllipsisDropdown';
import { OfferActivity, OfferMechanic, OfferStatus, OfferMechanicOptions } from '../SettingsConstants';
import GreyTag from '../../common/tags/GreyTag';
import useAuth from '../../../context/AuthContext';
import { User } from '../../../types/UserTypes';
import { Offer } from '../SettingsConstants';
import { postSaveOrUpdateOffer, postDeleteOffer, postGenerateBaseOffer } from '../../../apis/SettingsApi';

const { Draft } = OfferStatus;
const { InUse, NotInUse } = OfferActivity;

// TODO: Add an info icon to Base Offer cell: "You can insert these base offers into your campaign messages"

const OFFERS_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Offer name', key: 'name' },
  { displayText: 'Service', key: 'service' },
  { displayText: 'Mechanics', key: 'mechanics' },
  { displayText: 'Offer rules', key: 'rules' },
  { displayText: 'Generate', key: 'generate' },
  { displayText: 'Base offer', key: 'baseOffer' },
  { displayText: 'Status', key: 'status' },
  { displayText: 'Activity', key: 'activity' },
  { displayText: 'More', key: 'more' },
];

const EMPTY_OFFER: Offer = {
  _id: null,
  name: '',
  service: null,
  mechanics: null,
  rules: '',
  baseOffer: '',
  status: Draft,
  activity: NotInUse,
};

const OFFERS_MAX_ROWS = 5;

function getActivityNode(activity: OfferActivity): ReactNode {
  switch (activity) {
    case InUse:
      return <GreenTag content={activity} additionalClassNames={['tag-cell']} />;
    case NotInUse:
      return <GreyTag content={activity} additionalClassNames={['tag-cell']} />;
    default:
      return null;
  }
};

function OffersTable() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user, setUser } = useAuth();
  const services = user && user.services ? user.services : [];
  const initialOffers = user && user.offers ? user.offers : [];
  let defaultTableData = [...initialOffers];
  let defaultCursor = 0;
  if (state && state.offer) {
    const { name, rules } = state.offer;
    const offerAlreadyExists = defaultTableData.some(offer => offer.name === name);
    if (!offerAlreadyExists) {
      defaultTableData = [...defaultTableData, { ...EMPTY_OFFER, name, rules }];
    }

    // TODO: Check that this works
    defaultCursor = defaultTableData.length - (defaultTableData.length % OFFERS_MAX_ROWS);
  }
  const [cursor, setCursor] = useState(defaultCursor);
  const [textToDisplay, setTextToDisplay] = useState<TextToDisplay | null>(null);
  const [offers, setOffers] = useState<Offer[]>(defaultTableData);
  const [modalLoading, setModalLoading] = useState(false);
  const [loadingArray, setLoadingArray] = useState<number[]>([]);
  const [loadingAddOffer, setLoadingAddOffer] = useState(false);
  const setOffer = async (offer: Offer, index: number) => {
    const newOffers = [...offers];
    newOffers[index] = offer;
    setOffers(newOffers);
    const response = await postSaveOrUpdateOffer(offer);
    setUser({ ...user as User, offers: response.offers });
    newOffers[index] = { ...offer, _id: response.updatedOfferId };
    setOffers(newOffers);
  }
  const setLoading = (loading: boolean, index: number) => {
    if (loading) {
      if (!loadingArray.includes(index)) {
        setLoadingArray([...loadingArray, index]);
      }
    }
    else {
      setLoadingArray(loadingArray.filter((value) => value !== index));
    }
  };
  const serviceOptions = services
    .filter(({ name }) => name !== '')
    .map(({ name, _id }) => ({ displayText: name, value: `${_id}` }));
  // TODO: Go to correct cursor after adding offer
  const addOffer = async () => {
    const newOffer = { ...EMPTY_OFFER };
    setLoadingAddOffer(true);
    const response = await postSaveOrUpdateOffer(newOffer);
    setUser({ ...user as User, offers: response.offers });
    setOffers([...offers, { ...newOffer, _id: response.updatedOfferId }]);
    setLoadingAddOffer(false);
  };
  const deleteOffer = async (index: number) => {
    const offer = offers[index];
    const offerId = offer._id;
    if (offerId) {
      const offers = await postDeleteOffer(offerId);
      setUser({ ...user as User, offers });
    }
    setOffers(offers.filter((_, i) => i !== index));
  };
  const displayText = (text: string, index: number, fieldName: string) => () => setTextToDisplay({ text, index, fieldName });
  const setService = (index: number) => async (service: string) => {
    const offer = offers[index];
    await setOffer({ ...offer, service }, index);
  };
  const setMechanics = (index: number) => async (mechanics: OfferMechanic) => {
    const offer = offers[index];
    await setOffer({ ...offer, mechanics }, index);
  };
  const rows = offers.map(({ _id, name, service, mechanics, rules, baseOffer, status, activity }, index) => {
    const nameNode = <section className={'two-cell short-text'} onClick={displayText(name, index, 'name')}>{name}</section>
    const addService = () => navigate('/settings/services');
    const addServiceButton = { content: 'Add new service', onClick: addService };
    const serviceNode = <Dropdown defaultDisplayText={'No service'} options={serviceOptions} selected={service} select={setService(index)} additionalClassNames={['two-cell']} additionalOption={addServiceButton} />;
    const mechanicsNode = <Dropdown defaultDisplayText={'No mechanic'} options={OfferMechanicOptions} selected={mechanics} select={setMechanics(index)} additionalClassNames={['two-cell']} />;
    const rulesNode = <section className={'two-cell long-text'} onClick={displayText(rules, index, 'rules')}>{rules}</section>;

    const generateDisabled = name === '' || rules === '';
    const generateBaseOffer = async () => {
      setLoading(true, index);
      const response = await postGenerateBaseOffer({ _id, name, service, mechanics, rules, baseOffer: '', status, activity });
      setUser({ ...user as User, offers: response.offers });
      await setOffer({ ...offers[index], baseOffer: response.baseOffer }, index);
      setLoading(false, index);
    }
    const loading = loadingArray.includes(index);
    const generateNode = <PrimaryButton content={'Generate offer'} additionalClassNames={['button-cell']} onClick={generateBaseOffer} disabled={generateDisabled} loading={loading} />;
    const baseOfferNode = <section className={'two-cell long-text'} onClick={displayText(baseOffer, index, 'baseOffer')}>{baseOffer}</section>;
    const statusNode = <LightPurpleTag content={status} additionalClassNames={['tag-cell']} />;
    const activityNode = getActivityNode(activity);

    // TODO: Archive, Duplicate, Publish
    const moreOptions = [{ displayText: 'Archive', value: index, onClick: () => deleteOffer(index) }]
    const moreNode = <EllipsisDropdown options={moreOptions} additionalClassNames={['half-cell']} />;
    return {
      cells: [
        { key: 'name', value: nameNode },
        { key: 'service', value: serviceNode },
        { key: 'mechanics', value: mechanicsNode },
        { key: 'rules', value: rulesNode },
        { key: 'generate', value: generateNode },
        { key: 'baseOffer', value: baseOfferNode },
        { key: 'status', value: statusNode },
        { key: 'activity', value: activityNode },
        { key: 'more', value: moreNode },
      ]
    }
  });
  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  const confirmTextChanges = async (text: string) => {
    if (textToDisplay) {
      const { index, fieldName } = textToDisplay;
      const offer = offers[index];
      setModalLoading(true);
      await setOffer({ ...offer, [fieldName]: text }, index);
      setModalLoading(false);
    }
    closeTextToDisplayModal();
  }
  const modalText = textToDisplay ? textToDisplay.text : '';
  return (
    <>
      { showingText && <EditTextModal text={modalText} confirm={confirmTextChanges} close={closeTextToDisplayModal} loading={modalLoading} /> }
      <section className={'offers-table-container'}>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['offers-table']}
            headers={OFFERS_TABLE_HEADERS}
            rows={rows}
            cursor={cursor}
            maxRows={OFFERS_MAX_ROWS}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={OFFERS_MAX_ROWS}
            totalRows={rows.length}
          />
        </section>
        <PrimaryPlusButton content={'Add offer'} additionalClassNames={['create-offer-button']} onClick={addOffer} loading={loadingAddOffer} />
      </section>
    </>
  );
}

export default OffersTable;

