import { useState } from 'react';

import VerifyPage from './VerifyPage';
import { LoginMethod } from './LoginConstants';
import LoginChannelManager from './LoginChannelManager';

function LoginViewManager() {
  const [showingVerifyPage, setShowingVerifyPage] = useState(false);
  const [loginDetail, setLoginDetail] = useState('');
  const [loginMethod, setLoginMethod] = useState<LoginMethod>(LoginMethod.SMS);

  if (showingVerifyPage) {
    return (
      <VerifyPage
        loginDetail={loginDetail}
        setShowingVerifyPage={setShowingVerifyPage}
        loginMethod={loginMethod}
      />
    );
  }
  return (
    <LoginChannelManager
      setShowingVerifyPage={setShowingVerifyPage}
      loginDetail={loginDetail}
      setLoginDetail={setLoginDetail}
      loginMethod={loginMethod}
      setLoginMethod={setLoginMethod}
    />
  );
}

export default LoginViewManager;