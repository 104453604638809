import './LoginInput.scss';

import React, { ReactNode } from 'react';

interface LoginInputProps {
  children: ReactNode;
  error: string,
  inputLabel: string,
  onSubmit: () => void
}

function LoginInput({ children, error, inputLabel, onSubmit } : LoginInputProps) {
  const displayText = error ? error : inputLabel;
  return (
    <section>
      <section className={'login-input-container'}>
        {children}
        <p className={'login-input-label'}>{displayText}</p>
      </section>
      <button className={'login-proceed-button'} type={'submit'} onClick={onSubmit}>{'Continue'}</button>
    </section>
  )
}

export default LoginInput;