import { VALUE_TYPES } from "../../../../Constants";
import {
  greaterThan,
  isEmpty,
  isNotEmpty,
  lessThan,
  numberEquals,
  numberNotEquals
} from "../../../engagements/FilterHelpers";
import { OptionType } from '../../dropdowns/DropdownConstants';
import { ConditionKey, ConditionText } from "../MultiSelectRowConstants";

const { NUMBER } = VALUE_TYPES;

// Metric Attributes
export enum MetricAttributeText {
  TotalSpendValue = 'Total Spend Value',
  TransactionValue = 'Transaction Value',
  NumberOfTransactions = 'Number of Transactions',
};

export enum MetricAttributeKey {
  TotalSpendValueKey = 'totalSpendValue',
  TransactionValueKey = 'transactionValue',
  NumberOfTransactionsKey = 'numberOfTransactions',
}

const {
  TotalSpendValue,
  TransactionValue,
  NumberOfTransactions,
} = MetricAttributeText;

const {
  TotalSpendValueKey,
  TransactionValueKey,
  NumberOfTransactionsKey,
} = MetricAttributeKey;

export const METRIC_ATTRIBUTES = [
  { displayText: TotalSpendValue, value: TotalSpendValueKey },
  { displayText: TransactionValue, value: TransactionValueKey },
  { displayText: NumberOfTransactions, value: NumberOfTransactionsKey },
];

const {
  IsExactly,
  IsExactlyNot,
  IsEmpty,
  IsNotEmpty,
  IsGreaterThan,
  IsLessThan,
} = ConditionText;

const {
  IsExactlyKey,
  IsExactlyNotKey,
  IsEmptyKey,
  IsNotEmptyKey,
  IsGreaterThanKey,
  IsLessThanKey,
} = ConditionKey;

export type METRIC_OPTIONS_MAP = Record<string, OptionType<ConditionKey>[]>;

// Used as the options array for selecting conditions.
export const METRIC_CONDITION_MAPPINGS : METRIC_OPTIONS_MAP = {
  [NUMBER]: [
    { displayText: IsExactly, value: IsExactlyKey },
    { displayText: IsExactlyNot, value: IsExactlyNotKey },
    { displayText: IsGreaterThan, value: IsGreaterThanKey },
    { displayText: IsLessThan, value: IsLessThanKey },
    { displayText: IsEmpty, value: IsEmptyKey },
    { displayText: IsNotEmpty, value: IsNotEmptyKey },
  ]
};

export type Metric = {
  attribute: MetricAttributeKey | null,
  condition: ConditionKey | null,
  input: string,
};

// Used to get attribute types.
export const METRIC_ATTRIBUTES_DETAIL_MAP = {
  [TotalSpendValueKey]: {
    key: TotalSpendValueKey,
    displayText: TotalSpendValue,
    type: NUMBER
  },
  [TransactionValueKey]: {
    key: TransactionValueKey,
    displayText: TransactionValue,
    type: NUMBER
  },
  [NumberOfTransactionsKey]: {
    key: NumberOfTransactionsKey,
    displayText: NumberOfTransactions,
    type: NUMBER
  },
};

interface MetricCondition<T> {
  key: ConditionKey,
  displayText: string,
  valueType: VALUE_TYPES,
  valueTypeTitle: string,
  filterFunction: (a: T, b: T) => boolean
}

type TYPE_CONDITION = Record<string, MetricCondition<string>>;
type MAP_TYPE_CONDITION = Record<string, TYPE_CONDITION>;

// Used to get condition types
export const METRIC_CONDITION_FUNCTIONS : MAP_TYPE_CONDITION = {
  [NUMBER]: {
    isExactly: {
      key: IsExactlyKey,
      displayText: 'Is Exactly',
      valueType: NUMBER,
      valueTypeTitle: 'isExactly',
      filterFunction: numberEquals
    },
    isExactlyNot: {
      key: IsExactlyNotKey,
      displayText: 'Is Exactly Not',
      valueType: NUMBER,
      valueTypeTitle: 'isExactlyNot',
      filterFunction: numberNotEquals
    },
    isLessThan: {
      key: IsLessThanKey,
      displayText: 'Is Less Than',
      valueType: NUMBER,
      valueTypeTitle: 'isLessThan',
      filterFunction: lessThan
    },
    isGreaterThan: {
      key: IsGreaterThanKey,
      displayText: 'Is Greater Than',
      valueType: NUMBER,
      valueTypeTitle: 'isGreaterThan',
      filterFunction: greaterThan
    },
    isEmpty: {
      key: IsEmptyKey,
      displayText: 'Is Empty',
      valueType: NUMBER,
      valueTypeTitle: 'isEmpty',
      filterFunction: isEmpty
    },
    isNotEmpty: {
      key: IsNotEmptyKey,
      displayText: 'Is Not Empty',
      valueType: NUMBER,
      valueTypeTitle: 'isNotEmpty',
      filterFunction: isNotEmpty
    },
  },
}