import './CreateEngagementTable.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router';

import Table, { TableHeaderProps } from '../../common/tables/Table';
import { CHANNEL_OPTIONS } from '../../../Constants';
import { TextToDisplay, EMPTY_VARIANT } from '../EngagementsConstants';
import { Engagement, Variant } from '../EngagementsTypes';
import Dropdown from '../../common/dropdowns/Dropdown';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import PrimaryPlusButton from '../../common/buttons/composite/PrimaryPlusButton';
import OutlineButton from '../../common/buttons/OutlineButton';
import TrashIconButton from '../../common/buttons/composite/TrashIconButton'
import ConfirmVariantDeletionModal from '../modals/ConfirmVariantDeletionModal';
import EditTextModal from '../../common/modals/EditTextModal';
import PaginationBar from '../../common/tables/PaginationBar';
import useAuth from '../../../context/AuthContext';
import { postGenerateBaseMessage } from '../../../apis/EngagementApi';

const TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Variant name', key: 'name' },
  { displayText: 'Campaign Purpose', key: 'campaignPurpose' },
  { displayText: 'Offer', key: 'offer' },
  { displayText: 'Channel', key: 'channel' },
  { displayText: 'Generate', key: 'generate' },
  { displayText: 'Base message', key: 'baseMessage' },
  { displayText: 'Settings', key: 'settings' },
  { displayText: 'Delete', key: 'delete' },
];

function getVariant(index: number): Variant {
  return {
    ...EMPTY_VARIANT,
    name: `Variant ${index + 1}`,    
  };
}

interface CreateEngagementTableProps {
  engagement: Engagement,
  setEngagement: (engagement: Engagement) => void,
}

const CREATE_ENGAGEMENTS_MAX_ROWS = 5;

function CreateEngagementTable({ engagement, setEngagement } : Readonly<CreateEngagementTableProps> ) {
  const { user } = useAuth();
  const [cursor, setCursor] = useState(0);
  const navigate = useNavigate();
  const offerOptions  = user && user.offers ? user.offers.map(({ name, _id }) => ({ displayText: name, value: _id })) : [];
  const { variants } = engagement;
  const setVariants = (variants: Variant[]) => setEngagement({ ...engagement, variants });
  const [selectedVariantNumber, setSelectedVariantNumber] = useState<number | null>(null);
  const [textToDisplay, setTextToDisplay] = useState<TextToDisplay | null>(null);
  const [loadingArray, setLoadingArray] = useState<number[]>([]);
  const setVariant = (variant: any, index: number) => {
    const newVariants = [...variants];
    newVariants[index] = variant;
    setVariants(newVariants);
  }
  const addVariant = () => setVariants([...variants, getVariant(variants.length)]);
  const deleteVariant = (index: number) => setVariants(variants.filter((_ : Variant, i: number) => i !== index));
  const setOffer = (index: number) => (offer: string) => setVariant({ ...variants[index], offer }, index);
  const setChannel = (index: number) => (channel: string) => setVariant({ ...variants[index], channel }, index);
  const displayText = (text: string, index: number, fieldName: string) => () => setTextToDisplay({ text, index, fieldName });
  const addOffer = () => navigate('/settings/offers', { state: { originUrl: '/engagements/create' } });
  const addOfferButton = { content: 'Add new offer', onClick: addOffer };
  const setLoading = (loading: boolean, index: number) => {
    if (loading) {
      if (!loadingArray.includes(index)) {
        setLoadingArray([...loadingArray, index]);
      }
    }
    else {
      setLoadingArray(loadingArray.filter((value) => value !== index));
    }
  };
  const setBaseMessage = (baseMessage: string, index: number) => {
    setVariant({ ...variants[index], baseMessage }, index);
  };
  const rows = variants.map(({ name, campaignPurpose, offer, channel, baseMessage } : Variant, index: number) => {
    const nameNode = <section className={'two-cell short-text'} onClick={displayText(name, index, 'name')}>{name}</section>
    const campaignPurposeNode = <section className={'two-cell long-text'} onClick={displayText(campaignPurpose, index, 'campaignPurpose')}>{campaignPurpose}</section>
    const offerNode = <Dropdown options={offerOptions} selected={offer} select={setOffer(index)} additionalClassNames={['two-cell add-offer']} additionalOption={addOfferButton} />;
    const channelNode = <Dropdown options={CHANNEL_OPTIONS} selected={channel} select={setChannel(index)} additionalClassNames={['two-cell']} />;
    const goToAdvancedSettings = () => navigate('/engagements/settings', { state: { variantIndex: index } });
    const generateMessage = async () => {
      setLoading(true, index);
      const { baseMessage } = await postGenerateBaseMessage({ campaignPurpose, offerId: offer });
      setBaseMessage(baseMessage, index);
      setLoading(false, index);
    }
    const loading = loadingArray.includes(index);
    const generateDisabled = !campaignPurpose;
    const generateNode = <PrimaryButton content={'Generate message'} additionalClassNames={['button-cell']} onClick={generateMessage} loading={loading} disabled={generateDisabled} />;
    const baseMessageNode = <section className={'two-cell long-text'} onClick={displayText(baseMessage, index, 'baseMessage')}>{baseMessage}</section>;
    const settingsNode = <OutlineButton content={'Advanced Settings'} additionalClassNames={['button-cell']} onClick={goToAdvancedSettings} />;
    const deleteNode = <TrashIconButton onClick={() => setSelectedVariantNumber(index)} additionalClassNames={['one-cell']} />;
    return {
      cells: [
        { key: 'name', value: nameNode },
        { key: 'campaignPurpose', value: campaignPurposeNode },
        { key: 'offer', value: offerNode },
        { key: 'channel', value: channelNode },
        { key: 'generate', value: generateNode },
        { key: 'baseMessage', value: baseMessageNode },
        { key: 'settings', value: settingsNode },
        { key: 'delete', value: deleteNode },
      ]
    }
  });
  const modalOpen = selectedVariantNumber !== null;
  const closeConfirmationModal = () => setSelectedVariantNumber(null);
  const confirmDeleteVariant = () => {
    if (selectedVariantNumber !== null) {
      deleteVariant(selectedVariantNumber);
    }
    closeConfirmationModal();
  };
  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  const confirmTextChanges = (text: string) => {
    if (textToDisplay) {
      const { index, fieldName } = textToDisplay;
      setVariant({ ...variants[index], [fieldName]: text }, index);
    }
    closeTextToDisplayModal();
  }
  const modalText = textToDisplay ? textToDisplay.text : '';
  return (
    <>
      { modalOpen && <ConfirmVariantDeletionModal cancel={closeConfirmationModal} confirm={confirmDeleteVariant} /> }
      { showingText && <EditTextModal text={modalText} confirm={confirmTextChanges} close={closeTextToDisplayModal} /> }
      <section className={'create-engagement-table-container'}>
        <section className={'table-container'}>
          <Table
            additionalClassNames={['create-engagement-table']}
            headers={TABLE_HEADERS}
            rows={rows}
            cursor={cursor}
            maxRows={CREATE_ENGAGEMENTS_MAX_ROWS}
          />
        </section>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={CREATE_ENGAGEMENTS_MAX_ROWS}
            totalRows={rows.length}
          />
        </section>
        <PrimaryPlusButton content={'Add variant'} additionalClassNames={['create-variant-button']} onClick={addVariant} />
      </section>
    </>
  )
}

export default CreateEngagementTable;

