import './TimeSelectorPanel.scss';

import { HOUR_MAP, MINUTE_MAP } from '../InputConstants';
import TimeOption from './TimeOption';

interface TimeSelectorPanelProps {
  selectedHour: number,
  selectedMinute: number,
  setSelectedHour: (hour: number) => void,
  setSelectedMinute: (minute: number) => void,
};

function TimeSelectorPanel({ selectedHour, selectedMinute, setSelectedHour, setSelectedMinute } : TimeSelectorPanelProps) {

  return (
    <section className={'time-picker-modal-container'}>
      <section className={'time-title-container'}>
        <hr className={'time-div'}/>
        <span className={'time-title'}>{'HOURS'}</span>
      </section>
      <section className={'time-selections'}>
        <section className={'time-selection'}>
          {Object.keys(HOUR_MAP).slice(0, 6).map(hour => <TimeOption key={`time-option${hour}`} selectedTime={selectedHour} time={HOUR_MAP[hour]} setTime={setSelectedHour} />)}
        </section>
        <section className={'time-selection'}>
          {Object.keys(HOUR_MAP).slice(6, 12).map(hour => <TimeOption key={`time-option${hour}`} selectedTime={selectedHour} time={HOUR_MAP[hour]} setTime={setSelectedHour} />)}
        </section>
      </section>
      <section className={'time-title-container'}>
        <span className={'time-title'}>{'MINUTES'}</span>
        <hr className={'time-div'}/>
      </section>
      <section className={'time-selections'}>
        <section className={'time-selection'}>
          {Object.keys(MINUTE_MAP).slice(0, 6).map(minute => <TimeOption key={`time-option${minute}`} selectedTime={selectedMinute} time={MINUTE_MAP[minute]} setTime={setSelectedMinute} />)}
        </section>
        <section className={'time-selection'}>
          {Object.keys(MINUTE_MAP).slice(6, 12).map(minute => <TimeOption key={`time-option${minute}`} selectedTime={selectedMinute} time={MINUTE_MAP[minute]} setTime={setSelectedMinute} />)}
        </section>
      </section>
    </section>
  )
}

export default TimeSelectorPanel;