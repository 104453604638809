import './Sidebar.scss';

import React, { ReactNode } from 'react';

interface SidebarProps {
  additionalClassNames?: string[];
  children: ReactNode;
}

function Sidebar({ additionalClassNames = [], children } : SidebarProps) {
  const classNames = ['sidebar', ...additionalClassNames].join(' ');
  return (
    <aside className={classNames}>
      {children}
    </aside>
  )
};

export default Sidebar;