import './FormItem.scss';

import React, { ReactNode } from 'react';

interface FormItemProps {
  label: string;
  children: ReactNode;
  additionalClassNames?: string[]
}

function FormItem({ label, children, additionalClassNames = [] } : Readonly<FormItemProps>) {
  return (
    <section className={`form-item ${additionalClassNames.join(' ')}`}>
      <label className={'form-item-label'}>{label}</label>
      <section className={'form-item-content'}>
        {children}
      </section>
    </section>
  );
}

export default FormItem;