import './CompetitorOfferRow.scss';

import React from 'react';

import TrashIconButton from '../../common/buttons/composite/TrashIconButton';

interface CompetitorOfferRowProps {
  number: number,
  name: string,
  url: string,
  deleteRow: () => void,
};

function CompetitorOfferRow({ number, name, url, deleteRow }: Readonly<CompetitorOfferRowProps>) {
  return (
    <section className={'competitor-offer-row'}>
      <span className={'number'}>{number}</span>
      <span className={'name'}>{name}</span>
      <span className={'url'}>{url}</span>
      <TrashIconButton onClick={deleteRow} />
    </section>
  );
}

export default CompetitorOfferRow;