import './RecipientViewTable.scss';

import React from 'react';

import RecipientViewRow from './RecipientViewRow';
import { RecipientRow } from './RecipientSelectionTable';

interface RecipientViewTableProps {
  rows: RecipientRow[],
  setSelectedRows: (selectedRows: string[]) => void,
}

function RecipientViewTable({ rows, setSelectedRows, } : Readonly<RecipientViewTableProps>) {
  const rowCount = rows.length;
  const rowList = rows.map(({ _id, firstName, lastName, emailAddress, mobileNumber }) => {
    const name = `${firstName ? firstName : ''}${lastName ? ` ${lastName}` : ''}`;
    const removeRow = () => setSelectedRows(rows.map(({ _id }) => _id).filter((selectedId) => selectedId !== _id));
    return <RecipientViewRow key={_id} name={name} emailAddress={emailAddress} mobileNumber={mobileNumber} removeRow={removeRow} />;
  });
  return (
    <section className={'recipient-view-table'}>
      <header className={'table-header'}>
        <h4 className={'table-title'}>{'Selected Recipients'}</h4>
        <span className={'count'}>{`(${rowCount})`}</span>
      </header>
      <section className={'recipient-rows'}>
        {rowList}
      </section>
    </section>
  );
}

export default RecipientViewTable;