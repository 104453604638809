import './MetricTable.scss';

import React, { useState } from 'react';

import Table, { TableHeaderProps } from '../../../common/tables/Table';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';
import PaginationBar from '../../../common/tables/PaginationBar';
import { MetricSegment } from '../SegmentsConstants';
import PrimaryPlusButton from '../../../common/buttons/composite/PrimaryPlusButton';
import EditTextModal from '../../../common/modals/EditTextModal';
import { TextToDisplay } from '../../../engagements/EngagementsConstants';
import Tooltip from '../../../common/tooltips/Tooltip';
import { MetricAttributeType } from '../SegmentsConstants';

import INFO_ICON from '../assets/information-icon.svg';

const NUMERIC_PATTERN = /^(0|[1-9]\d*)(\.\d{0,2})?$/;

const METRIC_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Name', key: 'boundName' },
  { displayText: 'Lower bound', key: 'lowerBound' },
  { displayText: <span className={'cell-text'}>{'Upper bound'}<Tooltip content={'Leave this field blank to cover all upper bounds'}><img className={'info-icon'} src={INFO_ICON} alt={''} /></Tooltip></span>, key: 'upperBound' },
  { displayText: 'Delete', key: 'delete' },
];

const DUMMY_TABLE_DATA: MetricSegment[] = [
  {
    boundName: '',
    lowerBound: '',
    upperBound: '',
  },
  {
    boundName: '',
    lowerBound: '',
    upperBound: '',
  },
  {
    boundName: '',
    lowerBound: '',
    upperBound: '',
  },
];

const METRIC_MAX_ROWS = 5;

interface MetricTableProps {
  segments: MetricSegment[];
  setSegments: (segments: MetricSegment[]) => void;
  metricAttributeType: MetricAttributeType;
}

function MetricTable({ segments = DUMMY_TABLE_DATA, setSegments, metricAttributeType } : Readonly<MetricTableProps>) {
  const [textToDisplay, setTextToDisplay] = useState<TextToDisplay | null>(null);
  const [cursor, setCursor] = useState(0);
  const addBound = () => {
    if (segments.length === 0) {
      setSegments([...segments, { boundName: '', lowerBound: '0.00', upperBound: '' }])
    }
    else {
      const lastBound = segments[segments.length - 1];
      setSegments([...segments, { boundName: '', lowerBound: lastBound.upperBound, upperBound: '' }]);
    }
  };
  const setSegment = (segment: MetricSegment, index: number) => {
    const newSegments = [...segments];
    newSegments[index] = segment;
    setSegments(newSegments);
  }
  const displayText = (text: string, index: number, fieldName: string, validate?: (value: string) => boolean) => {
    return () => setTextToDisplay({ text, index, fieldName, validate });
  };
  const deleteBound = (index: number) => setSegments(segments.filter((_, i) => i !== index));
  const validateNumeric = (value: string) => {
    if (value === '') return true;
    return NUMERIC_PATTERN.test(value);
  }
  const rows = segments.map(({ boundName, lowerBound, upperBound }, index) => {
    const hasLowerBoundText = lowerBound !== null && lowerBound !== '';
    const hasUpperBoundText = upperBound !== null && upperBound !== '';
    const isDollarType = metricAttributeType === MetricAttributeType.Dollar;
    const lowerBoundDisplayText = isDollarType ? `$${lowerBound}` : lowerBound;
    const upperBoundDisplayText = isDollarType ? `$${upperBound}` : upperBound;
    const lowerBoundText = hasLowerBoundText ? lowerBoundDisplayText : 'Add value';
    const upperBoundText = hasUpperBoundText ? upperBoundDisplayText : 'Add value';
    const boundNameNode = <section className={'two-cell center short-text'} onClick={displayText(boundName, index, 'boundName', undefined)}>{boundName}</section>
    const lowerBoundNode = <section className={`one-cell center short-text ${hasLowerBoundText ? '': 'empty'}`} onClick={displayText(lowerBound, index, 'lowerBound', validateNumeric)}>{lowerBoundText}</section>;
    const upperBoundNode = <section className={`one-cell center short-text ${hasUpperBoundText ? '': 'empty'}`} onClick={displayText(upperBound, index, 'upperBound', validateNumeric)}>{upperBoundText}</section>;
    const deleteNode = <TrashIconButton onClick={() => deleteBound(index)} additionalClassNames={['half-cell']} />;
    return {
      cells: [
        { key: 'boundName', value: boundNameNode },
        { key: 'lowerBound', value: lowerBoundNode },
        { key: 'upperBound', value: upperBoundNode },
        { key: 'delete', value: deleteNode },
      ]
    }
  });
  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  const confirmTextChanges = (text: string) => {
    if (textToDisplay) {
      const { index, fieldName } = textToDisplay;
      setSegment({ ...segments[index], [fieldName]: text }, index);
    }
    closeTextToDisplayModal();
  }
  const modalText = textToDisplay ? textToDisplay.text : '';
  const validate = textToDisplay ? textToDisplay.validate : undefined;
  return (
    <>
      { showingText && <EditTextModal text={modalText} confirm={confirmTextChanges} close={closeTextToDisplayModal} validate={validate} /> }
      <section className={'metric-table-container'}>
        <Table
          additionalClassNames={['metric-table']}
          headers={METRIC_TABLE_HEADERS}
          rows={rows}
          cursor={cursor}
          maxRows={METRIC_MAX_ROWS}
        />
        <PrimaryPlusButton content={'Add boundary'} onClick={addBound} additionalClassNames={['add-metric-button']}/>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={METRIC_MAX_ROWS}
            totalRows={segments.length}
          />
        </section>
      </section>
    </>
  )
}

export default MetricTable;

