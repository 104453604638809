import { useEffect } from 'react';
import { getCookie } from 'typescript-cookie';

import useAuth from '../context/AuthContext';
import { getUser } from '../apis/UserApi';

/**
 * When loading the app for the first time, check in cookies if the user has a valid token.
 * If so, retrieve their details and mark them as logged in.
 * @param {*} firstLoad 
 * @param {*} setFirstLoad 
 */
export const useInitialUserFetch = (firstLoad: boolean, setFirstLoad: (firstLoad: boolean) => void) => {
  const { authed, setAuthed, setUser } = useAuth();
  useEffect(() => {
    const token = getCookie('token');
    if (!authed && token) {
      const populateUser = async () => {
        try {
          const { user, success } = await getUser();
          console.log(user);
          if (success && user !== null) {
            setUser(user);
            setAuthed(true);
            setFirstLoad(true);
          }
          else {
            setFirstLoad(true);
          }
        }
        catch (err) {
          console.error(err);
          setFirstLoad(true);
        }
      }
      populateUser();
    }
    else {
      setFirstLoad(true);
    }
  }, [firstLoad, setFirstLoad, authed, setAuthed, setUser]);
}