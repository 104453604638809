import './ChangeLoginMethodButton.scss';

interface ChangeLoginMethodButtonProps {
  optionText: string,
  updateLoginMethod: () => void,
};

function ChangeLoginMethodButton({ optionText, updateLoginMethod } : ChangeLoginMethodButtonProps) {
  return (
    <button className={'login-method'} onClick={updateLoginMethod} type={'button'}>
      <p>{optionText}</p>
    </button>
  )
}

export default ChangeLoginMethodButton;