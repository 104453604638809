import './RecipientFilter.scss';

import { Dispatch, SetStateAction, useState } from 'react';

import OutlineButton from '../../../common/buttons/OutlineButton';
import AddFilterModal from '../../../engagements/modals/add-filter/AddFilterModal';
import { filterRecipients } from '../../../common/multi-select-rows/filters/FilterHelpers';
import { Filter, FILTER_ATTRIBUTES_MAP } from '../../../common/multi-select-rows/filters/FilterConstants';
import { RecipientRow } from '../../../engagements/modals/add-recipients/RecipientSelectionTable';
import LightPurpleTag from '../../../common/tags/LightPurpleTag';

interface RecipientFilterProps {
  recipients: RecipientRow[] | null,
  filteredRecipients: RecipientRow[] | null,
  setFilteredRecipients: (recipients: RecipientRow[]) => void,
  filters: Filter[],
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>
};

function RecipientFilter({ recipients, filteredRecipients, setFilteredRecipients, filters, setFilters } : RecipientFilterProps) {
  const resetFilters = () => setFilters([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const recipientRows = !!recipients ? recipients.map(recipient => ({ ...recipient, alreadySelected: false })) : [];
  const filteredRecipientCount = recipients && filteredRecipients ? `(${filteredRecipients.length}/${recipients.length})` : '';
  const selectedFilters = filters.length > 0;

  // Not sure how to get around this issue of attribute potentially being null.
  // If I remove the null check I get this error: Type 'null' cannot be used as an index type
  const filterTags = filters
    .map(({ attribute }) => {
      if (attribute) {
        return <LightPurpleTag content={FILTER_ATTRIBUTES_MAP[attribute].displayText} />;
      }
      return null;
    })

  const confirmFilters = (filters: Filter[]) => {
    const populatedFilters = filters.filter((filter: Filter) => filter.attribute);
    setFilters(populatedFilters);
    const recipientsToDisplay = filterRecipients(recipientRows, populatedFilters);
    setFilteredRecipients(recipientsToDisplay);
  };

  const filterText = `Filters${filters.length > 0 ? ` (${filters.length})` : ''}`;
  return (
    <section className={'recipients-filters'}>
      <OutlineButton additionalClassNames={['filter-button']} content={filterText} onClick={() => setFilterOpen(true)} />
      {filterTags}
      {selectedFilters && <span>{filteredRecipientCount}</span>}
      {filterOpen && <AddFilterModal parentFilters={filters} close={() => setFilterOpen(false)} confirm={confirmFilters} resetFilters={resetFilters} />}
    </section>
  );
}

export default RecipientFilter;