import './InteractionListDisplay.scss';

import useAuth from '../../../../context/AuthContext';
import { postSaveOrUpdateInteraction } from '../../../../apis/PeopleApi';
import { Interaction, Recipient } from '../../PeopleConstants';

import InteractionList from '../common/InteractionList';

interface InteractionListDisplayProps {
  interactions: Interaction[],
  personId: string,
  setPerson: (person: Recipient) => void,
}

// Either a person comes in through URL, or through state.
function InteractionListDisplay({ interactions, personId, setPerson } : Readonly<InteractionListDisplayProps>) {
  const { user, setUser } = useAuth();
  const updateInteraction = async (interaction: Interaction) => {
    const { success, recipient } = await postSaveOrUpdateInteraction(interaction)
    
    if (success && recipient !== null) {
      setPerson(recipient);

      if (success) {
        setPerson(recipient);
        
        if (user !== null) {
          const userRecipients = user.recipients;
          const recipientIndex = userRecipients.findIndex(userRecipient => userRecipient._id === personId);
          userRecipients[recipientIndex] = recipient;
          setUser({ ...user, recipients: userRecipients })
        }
      }
    }
  };
  
  const today = Date.now();

  const upcomingInteractions = interactions
    .filter(({ scheduledDate }) => scheduledDate > today)
    .sort((a, b) => b.scheduledDate - a.scheduledDate);
  const pastInteractions = interactions
    .filter(({ scheduledDate }) => scheduledDate <= today)
    .sort((a, b) => b.scheduledDate - a.scheduledDate);

  const upcomingInteractionsHeading = `Upcoming interactions (${upcomingInteractions.length})`;
  const pastInteractionsHeading = `Past interactions (${pastInteractions.length})`;
  
  return (
    <section className={'interaction-display'}>
      <InteractionList interactions={upcomingInteractions} listHeading={upcomingInteractionsHeading} updateInteraction={updateInteraction} />
      <InteractionList interactions={pastInteractions} listHeading={pastInteractionsHeading} updateInteraction={updateInteraction} />
    </section>
  );
}

export default InteractionListDisplay;