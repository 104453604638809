import React, { useState, createContext, useContext, ReactNode } from 'react';
import { removeCookie, setCookie } from 'typescript-cookie'
import { useLocation, Navigate } from 'react-router-dom';
import { User } from '../types/UserTypes';

const EmptyAuth = {
  authed: false,
  user: null,
  setAuthed: () => {},
  setUser: () => {},
  login: () => {},
  logout: () => Promise.resolve(),
}

interface AuthProps {
  authed: boolean,
  user: User | null,
  setAuthed: (authed: boolean) => void,
  setUser: (user: User | null) => void,
  login: (user: User, token: string) => void,
  logout: () => Promise<void>,
}

const AuthContext = createContext<AuthProps>(EmptyAuth);

function useAuth(): AuthProps {
  const [authed, setAuthed] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  return {
    authed,
    user,
    setAuthed,
    setUser,
    login: (user: User, token: string) => {
      setCookie('token', token);
      setUser(user);
      setAuthed(true);
    },
    logout: async () => {
      return new Promise(() => {
        removeCookie('token');
        setAuthed(false);
        setUser(null);
      });
    }
  };
};

/** Provider for authorization object (includes user data). */
export const AuthProvider = ({ children } : { children: ReactNode }) => {
  const value = useAuth();
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthConsumer = () => {
  return useContext(AuthContext);
};

/** Consumer for authorization object. */
export default AuthConsumer;

export interface ProtectedRouteProps {
  outlet: JSX.Element,
};

/** React wrapper to force the child component to require a certain level of authorization. */
export const RequireAuth = ({ outlet } : ProtectedRouteProps) => {
  const { authed } = AuthConsumer();
  const { pathname } = useLocation();
  if (authed) {
    return outlet;
  }
  return <Navigate to={'/login'} replace={true} state={{ path: pathname }} />;
};

/** React wrapper to force the child component to NOT require a certain level of authorization i.e. pages on available to logged out users. */
export const RequireNoAuth = ({ outlet } : ProtectedRouteProps) => {
  const { authed } = AuthConsumer();
  const { pathname, state } = useLocation();
  const path = state && state.path ? state.path : '/';
  if (authed) {
    return <Navigate to={path} replace={true} state={{ path: pathname }} />;
  }
  return outlet;
};
