import './RecoverLostClientsPage.scss';

import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router';

import { postCaseStudyRequest } from '../../apis/CaseStudyApi';
import PrimaryButton from '../common/buttons/PrimaryButton';
import Dropdown from '../common/dropdowns/Dropdown';

export enum MessageSendingOption {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

const MessageSendingOptions = [
  {
    displayText: '100 messages',
    value: MessageSendingOption.Low,
  },
  {
    displayText: '200 messages',
    value: MessageSendingOption.Medium,
  },
  {
    displayText: '400 messages',
    value: MessageSendingOption.High,
  },
]


function RecoverLostClientsPage(): ReactElement {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messageOption, setMessageOption] = useState<MessageSendingOption | null>(null);
  const requestMessages = async () => {
    const firstName = 'Lance';
    const businessName = 'WorkRex';
    const message = `${firstName} from ${businessName} requested Recover Lost Clients for the ${messageOption} message option.`;
    await postCaseStudyRequest(message);
    setLoading(false);
    navigate('/case-studies/home');
  }
  return (
    <article className={'recover-lost-clients-page'}>
      <section className={'page'}>
        <header className={'recover-lost-clients-page-header'}>
          <h2 className={'title'}>{'Recover lost clients'}</h2>
          <h3 className={'subtitle'}>{'Re-activate old clients to gain new appointments'}</h3>
        </header>
        <section className={'page-content'}>
          <iframe
            className={'preview-iframe'}
            src={'https://www.youtube.com/embed/-KjeI0Gdxhg?si=pZA89YM--TU1Meeu'}
            title={'Bring back your lost clients'}
            allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
            referrerPolicy={'strict-origin-when-cross-origin'}
            allowFullScreen
          />
          <section className={'option-selection'}>
            <span className={'label'}>{'Roughly 100 messages translates to 3 to 5 appointments a month, 200 translates to 6 to 10, etc.'}</span>
            <Dropdown
              options={MessageSendingOptions}
              defaultDisplayText={'Select the types of businesses to connect with'}
              select={setMessageOption}
              selected={messageOption}
            />
          </section>
          <PrimaryButton additionalClassNames={['cta-button']} content={'Request messages'} disabled={!messageOption} onClick={requestMessages} loading={loading} />
        </section>
      </section>
    </article>
  );
}

export default RecoverLostClientsPage;