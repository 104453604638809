import './GreyTag.scss';

import { TagProps } from './TagConstants';

import Tag from './Tag';

function GreyTag({ content, additionalClassNames = [] }: Readonly<TagProps>) {
  return <Tag content={content} additionalClassNames={['grey', ...additionalClassNames]} />;
};

export default GreyTag;