import './LoginWrapper.scss';

import React, { ReactNode } from 'react';

interface LoginWrapperProps {
  children: ReactNode,
}

function LoginWrapper({ children } : LoginWrapperProps) {
  return (
    <article className={'login-page'}>
      <section className={'login-page-container'}>
        {children}
      </section>
    </article>
  )
}

export default LoginWrapper;