import './PersonDetailField.scss';

import { DeliverabilityPreferences } from '../../PeopleConstants';

interface PersonDetailFieldProps<T> {
  label: string,
  value: T,
}

function PersonDetailField({ label, value }: Readonly<PersonDetailFieldProps<string>>) {
  return (
    <section className={'body-detail'}>
      <span className={'detail-heading'}>{label}</span>
      <span className={'detail-value'}>{value}</span>
    </section>
  );
}

export default PersonDetailField;