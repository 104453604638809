import ConfirmationModalContent from '../../common/modals/ConfirmationModalContent';
import Modal from '../../common/modals/Modal';

interface InteractionsToServicesMapResultModalProps {
  close: () => void,
  mappingResult: {
    mapped: string[],
    unmapped: string[],
  }
};

function InteractionsToServicesMapResultModal({ close, mappingResult } : Readonly<InteractionsToServicesMapResultModalProps>) {
  const { mapped, unmapped } = mappingResult;
  const subtitle = (
    <>
      {'Successfully mapped '}
      <b>{mapped.length}</b>
      {' interactions to services. There are '}
      <b>{unmapped.length}</b>
      {' unmapped interactions remaining.'}
    </>
  )
  return (
    <Modal additionalClassNames={['interactions-to-services-map-result-modal']}>
      <ConfirmationModalContent
        title={'Interactions to services mapping complete!'}
        subtitle={subtitle}
        confirm={close}
        confirmText={'Close'}
      />
    </Modal>
  );
}

export default InteractionsToServicesMapResultModal;