import './App.scss';

import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import WorkRexPage from './components/wrappers/WorkRexPage';
import { CaseStudyType } from './components/case-studies/CaseStudiesPage';
import EngagementsPage from './components/engagements/pages/EngagementsPage';
import OffersPage from './components/settings/pages/OffersPage';
import CreateEngagementPage from './components/engagements/pages/CreateEngagementPage';
import SelectRecipientsPage from './components/engagements/pages/SelectRecipientsPage';
import EngagementDeliveryPage from './components/engagements/pages/EngagementDeliveryPage';
import AdvancedSettingsPage from './components/engagements/pages/AdvancedSettingsPage';
import EditEngagementTable from './components/engagements/pages/EditEngagementPage';
import SegmentsPage from './components/people/segments/pages/SegmentsPage';
import EngagementResultsPage from './components/engagements/pages/EngagementResultsPage';
import VariantResultsPage from './components/engagements/pages/VariantResultsPage';
import CreateSegmentPage from './components/people/segments/pages/CreateSegmentPage';
import ServicesPage from './components/settings/pages/ServicesPage';
import CompetitorOffersPage from './components/settings/pages/CompetitorOffersPage';
import EngagementPreviewPage from './components/engagements/pages/EngagementPreviewPage';
import { RequireAuth, RequireNoAuth } from './context/AuthContext';
import LoginViewManager from './components/login/LoginViewManager';
import { useInitialUserFetch } from './hooks/DataHooks';
import LoadingRingPage from './components/common/pages/LoadingRingPage';
import RecipientsPage from './components/people/recipients/pages/RecipientsPage';
import PersonPage from './components/people/recipients/pages/PersonPage';
import CaseStudiesPage from './components/case-studies/CaseStudiesPage';
import CaseStudiesPageWrapper from './components/wrappers/CaseStudiesPageWrapper';
import HandwrittenLettersPage from './components/case-studies/HandwrittenLettersPage';
import LocalBusinessPartnersPage from './components/case-studies/LocalBusinessPartnersPage';
import RecoverLostClientsPage from './components/case-studies/RecoverLostClientsPage';

function App() {
  const [firstLoad, setFirstLoad] = useState(false);
  useInitialUserFetch(firstLoad, setFirstLoad);
  if (!firstLoad) {
    return <LoadingRingPage color={'#000000'} />;
  }
  return (
    <Router>
      <Routes>
        <Route path={'/'} element={<RequireAuth outlet={<WorkRexPage />} />}>
          <Route path={'engagements'} element={<EngagementsPage />} />
          <Route path={'engagements/create'} element={<CreateEngagementPage />} />
          <Route path={'engagements/recipients'} element={<SelectRecipientsPage />} />
          <Route path={'engagements/delivery'} element={<EngagementDeliveryPage />} />
          <Route path={'engagements/preview'} element={<EngagementPreviewPage isEditingExistingEngagement={false} />} />
          <Route path={'engagements/settings'} element={<AdvancedSettingsPage />} />
          <Route path={'engagements/results'} element={<EngagementResultsPage />} />
          <Route path={'engagements/results/variants'} element={<VariantResultsPage />} />
          <Route path={'engagements/edit'} element={<EditEngagementTable />} />
          <Route path={'engagements/edit/delivery'} element={<EngagementDeliveryPage isEditingExistingEngagement={true} />} />
          <Route path={'engagements/edit/preview'} element={<EngagementPreviewPage isEditingExistingEngagement={true} />} />
          <Route path={'people/segments'} element={<SegmentsPage />} />
          <Route path={'people/segments/create'} element={<CreateSegmentPage />} />
          <Route path={'people'} element={<RecipientsPage />} />
          <Route path={'people/person'} element={<PersonPage />} />
          <Route path={'settings/services'} element={<ServicesPage isServiceDisplay={true} />} />
          <Route path={'settings/services/map'} element={<ServicesPage isServiceDisplay={false} />} />
          <Route path={'settings/offers'} element={<OffersPage />} />
          <Route path={'settings/competitors'} element={<CompetitorOffersPage isTrackDisplay={true} />} />
          <Route path={'settings/competitors/track'} element={<CompetitorOffersPage isTrackDisplay={true} />} />
          <Route path={'settings/competitors/view'} element={<CompetitorOffersPage isTrackDisplay={false} />} />
        </Route>
        <Route path={'login'} element={<RequireNoAuth outlet={<LoginViewManager />} />} />
        <Route path={'case-studies'} element={<CaseStudiesPageWrapper />}>
          <Route path={'home'} element={<CaseStudiesPage type={null} />} />
          <Route path={'home/leads'} element={<CaseStudiesPage type={CaseStudyType.FindLeads} />} />
          <Route path={'home/clients'} element={<CaseStudiesPage type={CaseStudyType.RetainClients} />} />
          <Route path={'handwritten-letters'} element={<HandwrittenLettersPage />} />
          <Route path={'perks-program'} element={<LocalBusinessPartnersPage />} />
          <Route path={'lost-clients'} element={<RecoverLostClientsPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
