import './AdvancedSettingsPage.scss';

import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';

import FormBlock from '../forms/FormBlock';
import Dropdown from '../../common/dropdowns/Dropdown';
import { EngagementAdvancedSettings, ForwardingPreference, Autoresponse, Engagement } from '../EngagementsTypes';
import { FORWARDING_PREFERENCES, EMPTY_AUTORESPONSE, EMPTY_ADVANCED_SETTINGS } from '../EngagementsConstants';
import AutoresponseForm from '../forms/AutoresponseForm';
import PrimaryPlusButton from '../../common/buttons/composite/PrimaryPlusButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import OutlineButton from '../../common/buttons/OutlineButton';
import PrimaryInput from '../../common/inputs/PrimaryInput';
import { useLocalStorage, LocalStorageKey } from '../../../hooks/LocalStorageHooks';
import { getEmptyEngagement } from '../helpers/EngagementHelpers';
import useAuth from '../../../context/AuthContext';

const { CreateEngagementKey } = LocalStorageKey;
const { ALL_REPLIES, ONLY_REPLIES_THAT_MAY_REQUIRE_CUSTOMER_ACTION } = ForwardingPreference;

function isEmptyResponse(autoresponse : Autoresponse) {
  return autoresponse.reply === '' || autoresponse.response === '';
}

function AdvancedSettingsPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const emptyEngagement = getEmptyEngagement(user?.firstName, user?.business?.name);
  const [engagement, setEngagement] = useLocalStorage<Engagement>(CreateEngagementKey, emptyEngagement);
  const { state } = useLocation();
  const variantIndex = state && state.variantIndex !== undefined ? state.variantIndex : null;
  const hasInvalidVariantIndex = variantIndex === null || !engagement || engagement.variants.length < variantIndex;
  const defaultAdvancedSettings = hasInvalidVariantIndex
    ? EMPTY_ADVANCED_SETTINGS
    : engagement.variants[variantIndex].advancedSettings;
  const [advancedSettings, setAdvancedSettings] = useState(defaultAdvancedSettings);
  if (hasInvalidVariantIndex || !engagement.variants[variantIndex].advancedSettings) {
    return <Navigate to={'/engagements/create'} />;
  }
  const saveAdvancedSettings = (advancedSettings: EngagementAdvancedSettings) => {
    const variants = [...engagement.variants];
    variants[variantIndex] = { ...variants[variantIndex], advancedSettings };
    setEngagement({ ...engagement, variants });
  }
  const selectForwardingPreferences = (forwardingPreference: ForwardingPreference) => setAdvancedSettings({ ...advancedSettings, forwardingPreference });
  const { forwardingPreference, autoresponses, emailAddress } = advancedSettings;
  const setAutoresponse = (autoresponse : Autoresponse, index : number) => {
    const newAutoresponses = [...autoresponses];
    newAutoresponses[index] = autoresponse;
    setAdvancedSettings({ ...advancedSettings, autoresponses: newAutoresponses });
  }
  const setEmailAddress = (emailAddress : string) => setAdvancedSettings({ ...advancedSettings, emailAddress });
  const autoresponseForms = autoresponses.map(({ reply, response }, index) => {
    const setReply = (newReply : string) => setAutoresponse({ reply: newReply, response }, index);
    const setResponse = (newResponse : string) => setAutoresponse({ reply, response: newResponse }, index);
    const removeResponse = () => setAdvancedSettings({ ...advancedSettings, autoresponses: autoresponses.filter((_, i) => i !== index) });
    return (
      <AutoresponseForm
        key={`autoresponse-${index}`}
        reply={reply}
        response={response}
        setReply={setReply}
        setResponse={setResponse}
        removeResponse={removeResponse}
      />
    );
  });
  const addAutoresponse = () => setAdvancedSettings({ ...advancedSettings, autoresponses: [...autoresponses, { ...EMPTY_AUTORESPONSE }] });
  const back = () => navigate('/engagements/create');
  const save = () => {
    saveAdvancedSettings(advancedSettings);
    navigate('/engagements/create');
  }
  const hasEmptyResponse = autoresponses.some(isEmptyResponse);
  const shouldForwardEmails = forwardingPreference === ALL_REPLIES || forwardingPreference === ONLY_REPLIES_THAT_MAY_REQUIRE_CUSTOMER_ACTION;
  return (
    <article className={'advanced-settings-page'}>
      <section className={'page'}>
        <header className={'advanced-settings-page-header'}>
          <h2 className={'title'}>{'Advanced settings'}</h2>
          <h3 className={'subtitle'}>{'Change the settings of your engagement'}</h3>
        </header>
        <section className={'advanced-settings-page-content'}>
          <FormBlock additionalClassNames={['forwarding-preferences']} title={'Auto-forward messages'} subtitle={'Edit your preferences for which customer replies will be auto-forwarded to the email of your choice.'}>
            <Dropdown options={FORWARDING_PREFERENCES} selected={forwardingPreference} select={selectForwardingPreferences} />
            { shouldForwardEmails && <PrimaryInput placeholder={'Add forwarding email'} text={emailAddress} setText={setEmailAddress} /> }
          </FormBlock>
          <FormBlock title={'Auto-responder'} subtitle={'Set up auto-responder to help you reply to all messages using AI.'}>
            <section className={'autoresponses'}>
              {autoresponseForms}
            </section>
            <PrimaryPlusButton content={'Add reply'} onClick={addAutoresponse} />
          </FormBlock>
        </section>
      </section>
      <footer className={'buttons'}>
        <OutlineButton content={'Back'} onClick={back} />
        <PrimaryButton content={'Save'} onClick={save} disabled={hasEmptyResponse} />
      </footer>
    </article>
  );
}

export default AdvancedSettingsPage;