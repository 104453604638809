import './OutlineTag.scss';

import { TagProps } from './TagConstants';

import Tag from './Tag';

function OutlineTag({ content, additionalClassNames = [] }: Readonly<TagProps>) {
  return <Tag content={content} additionalClassNames={['outline', ...additionalClassNames]} />;
};

export default OutlineTag;