import './EngagementDeliveryForm.scss';

import { useState } from 'react';
import { Metric } from '../../common/multi-select-rows/metrics/MetricConstants';
import { Engagement } from '../EngagementsTypes';
import FormItem from './FormItem';
import PrimaryInput from '../../common/inputs/PrimaryInput';
import Dropdown from '../../common/dropdowns/Dropdown';
import WhiteCheckbox from '../../common/checkboxes/WhiteCheckbox';
import { DeliveryMethodOptions } from '../EngagementsConstants';
import DateTimeInput from '../../common/inputs/datetime/DateTimeInput';
import AddMetricModal from '../modals/add-metrics/AddMetricModal';
import OutlinePlusButton from '../../common/buttons/composite/OutlinePlusButton';

interface EngagementDeliveryFormProps {
  isEditingExistingEngagement: boolean,
  engagement: Engagement,
  setEngagement: (engagement: Engagement) => void,
}

function EngagementDeliveryForm({ isEditingExistingEngagement, engagement, setEngagement }: Readonly<EngagementDeliveryFormProps>) {
  const [openMetric, setOpenMetric] = useState(false);
  const openMetricModal = () => setOpenMetric(true);
  const closeMetricModal = () => setOpenMetric(false);
  const confirm = (metrics: Metric[]) => setEngagement({ ...engagement, metrics })

  const setText = (fieldName: string) => (value: string | number) => setEngagement({ ...engagement, [fieldName]: value });
  const selectDelivery = (deliveryMethod: string) => setEngagement({ ...engagement, deliveryMethod });
  const toggle = (fieldName: 'showUnsubscribeMessage' | 'showSignature') => () => setEngagement({ ...engagement, [fieldName]: !engagement[fieldName] });
  const {
    name,
    description,
    date,
    batchSend,
    attribution,
    metrics,
    deliveryMethod,
    showUnsubscribeMessage,
    unsubscribeMessage,
    showSignature,
    signature
  } = engagement;
  const formattedDate = date ? new Date(date) : new Date();
  return (
    <section className={'engagement-delivery-form'}>
      <FormItem label={'Engagement name'}>
        <PrimaryInput placeholder={'Enter a name for your engagement'} setText={setText('name')} text={name} disabled={isEditingExistingEngagement} />
      </FormItem>
      <FormItem label={'Engagement description'}>
        <PrimaryInput placeholder={'Enter a description for your engagement'} setText={setText('description')} text={description} disabled={isEditingExistingEngagement} />
      </FormItem>
      <FormItem label={'Select a date'}>
        <DateTimeInput minDate={new Date()} selectedDate={formattedDate} setDate={setText('date')} />
      </FormItem>
      <FormItem label={'Batch send'}>
        <PrimaryInput placeholder={'Enter number of batches to send'} setText={setText('batchSend')} text={batchSend} />
      </FormItem>
      <FormItem label={'Attribution'} additionalClassNames={['attribution']}>
        <PrimaryInput placeholder={'Days to attribute for campaign results'} setText={setText('attribution')} text={attribution} />
        <OutlinePlusButton content={'Add conversion metric'} onClick={openMetricModal} />
        {openMetric && <AddMetricModal parentMetrics={metrics} close={closeMetricModal} confirm={confirm} />}
      </FormItem>
      <FormItem label={'Delivery method'}>
        <Dropdown options={DeliveryMethodOptions} selected={deliveryMethod} select={selectDelivery} defaultDisplayText={'Select delivery method'} />
      </FormItem>
      <FormItem label={'Unsubscribe'} additionalClassNames={['multiple']}>
        <WhiteCheckbox checked={showUnsubscribeMessage} setChecked={toggle('showUnsubscribeMessage')} label={'Include unsubscribe message'} />
        <PrimaryInput placeholder={'Add your unsubscribe message'} text={unsubscribeMessage} setText={setText('unsubscribeMessage')} disabled={!showUnsubscribeMessage} />
      </FormItem>
      <FormItem label={'Signature'} additionalClassNames={['multiple']}>
        <WhiteCheckbox checked={showSignature} setChecked={toggle('showSignature')} label={'Include a \'sent by\' signature'} />
        <PrimaryInput placeholder={'Add your signature here'} text={signature} setText={setText('signature')} disabled={!showSignature} />
      </FormItem>
    </section>
  );
}

export default EngagementDeliveryForm;