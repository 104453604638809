import GreenTag from "../../../common/tags/GreenTag";
import GreyTag from "../../../common/tags/GreyTag";
import RedTag from "../../../common/tags/RedTag";
import { Status, StatusDictionary } from "../../PeopleConstants";

interface StatusTagManagerProps {
  status: Status
};

const { ACTIVE, NOT_ACTIVE, DELETED } = Status;

const StatusTags = {
  [ACTIVE]: GreenTag,
  [NOT_ACTIVE]: GreyTag,
  [DELETED]: RedTag,
};

function StatusTagManager({ status } : StatusTagManagerProps) {
  const Tag = StatusTags[status];
  return (
    <Tag content={StatusDictionary[status]} />
  );
}

export default StatusTagManager;