import './ConfirmRecipientDeletionModal.scss';

import React from 'react';

import Modal from '../../../common/modals/Modal';
import ConfirmationModalContent from '../../../common/modals/ConfirmationModalContent';

interface ConfirmRecipientDeletionModalProps {
  cancel: () => void,
  confirm: () => void,
};

function ConfirmRecipientDeletionModal({ cancel, confirm } : Readonly<ConfirmRecipientDeletionModalProps>) {
  return (
    <Modal additionalClassNames={['confirm-variant-deletion-modal']}>
      <ConfirmationModalContent
        title={'Are you absolutely sure?'}
        subtitle={'This action cannot be undone. This will delete this recipient.'}
        cancel={cancel}
        confirm={confirm}
      />
    </Modal>
  )
};

export default ConfirmRecipientDeletionModal;