import './RecipientSelectionBar.scss';

import DottedButton from '../../../common/buttons/DottedButton';
import OutlineButton from '../../../common/buttons/OutlineButton';

import CIRCLE_PLUS_ICON from '../../assets/circle-plus-icon.svg';

interface RecipientSelectionBarProps {
  quickSelect: (numRecipients: number) => (() => void),
  openFilterModal: () => void,
}

function RecipientSelectionBar({ quickSelect, openFilterModal } : Readonly<RecipientSelectionBarProps>) {
  const selectOneHundred = quickSelect(100);
  const selectFifty = quickSelect(50);
  const selectTwenty = quickSelect(20);
  const addFilter = () => openFilterModal();

  const quickAddOneHundredContent = (
    <section className={'button-content'}>
      <img className={'icon'} src={CIRCLE_PLUS_ICON} alt={'plus'} />
      <span className={'button-text'}>{'Quick add 100'}</span>
    </section>
  );
  const quickAddFiftyContent = (
    <section className={'button-content'}>
      <img className={'icon'} src={CIRCLE_PLUS_ICON} alt={'plus'} />
      <span className={'button-text'}>{'Quick add 50'}</span>
    </section>
  );
  const quickAddTwentyContent = (
    <section className={'button-content'}>
      <img className={'icon'} src={CIRCLE_PLUS_ICON} alt={'plus'} />
      <span className={'button-text'}>{'Quick add 20'}</span>
    </section>
  );
  return (
    <section className={'recipient-selection-bar'}>
      <DottedButton content={quickAddOneHundredContent} onClick={selectOneHundred} />
      <DottedButton content={quickAddFiftyContent} onClick={selectFifty} />
      <DottedButton content={quickAddTwentyContent} onClick={selectTwenty} />
      <OutlineButton content={'Add filter'} onClick={addFilter} />
    </section>
  );
}

export default RecipientSelectionBar;