import './InteractionRow.scss';

import { useState } from 'react';

import { formatedDateString } from '../../../../utils/DateHelpers';
import { Interaction, InteractionTypeText } from '../../PeopleConstants';

import OutlineButton from '../../../common/buttons/OutlineButton';
import OutlineTag from '../../../common/tags/OutlineTag';
import AddUpdateInteractionModal from '../modals/AddUpdateInteractionModal';

interface InteractionProps {
  interaction: Interaction,
  confirm: (interaction: Interaction) => void,
}

// Either a person comes in through URL, or through state.
function InteractionRow({ interaction, confirm } : Readonly<InteractionProps>) {
  const [modalOpen, setModalOpen] = useState(false);

  const { interactionType, interactionDetails, creationDate, createdByName, scheduledDate } = interaction;

  const formattedCreationDate = creationDate === null ? 'None' : new Date(creationDate).toLocaleDateString('en-au', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });

  const scheduledDateString = formatedDateString(scheduledDate);

  return (
    <section className={'interaction-row'}>
      <section className={'interaction-left-side'}>
        <section className={'interaction-type'}>
          <OutlineTag content={InteractionTypeText[interactionType]} />
          <span className={'interaction-created'}>{`Created by ${createdByName}, ${formattedCreationDate}`}</span>
        </section>
        <span className={'interaction-details'}>{interactionDetails}</span>
      </section>
      <section className={'interaction-right-side'}>
        <span className={'interaction-scheduled-date'}>{`Scheduled for ${scheduledDateString}`}</span>
        <OutlineButton content={'Edit'} onClick={() => setModalOpen(true)} />
        {modalOpen && <AddUpdateInteractionModal close={() => setModalOpen(false)} interactionToInsertUpdate={interaction} confirm={confirm} />}
      </section>
    </section>
  );
}

export default InteractionRow;