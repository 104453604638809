import './FormBlock.scss';

import React, { ReactNode } from 'react';

interface FormBlockProps {
  title: string;
  subtitle?: string,
  children: ReactNode;
  additionalClassNames?: string[]
}

function FormBlock({ title, subtitle = '', children, additionalClassNames = [] } : Readonly<FormBlockProps>) {
  return (
    <section className={`form-block ${additionalClassNames.join(' ')}`}>
      <h3 className={'form-title'}>{title}</h3>
      <h4 className={'form-subtitle'}>{subtitle}</h4>
      <section className={'form-block-content'}>
        {children}
      </section>
    </section>
  );
}

export default FormBlock;