import './EditEngagementPage.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router';

import PrimaryButton from '../../common/buttons/PrimaryButton';
import EditEngagementTable from '../tables/EditEngagementTable';
import OutlineButton from '../../common/buttons/OutlineButton';
import GenericSwitch from '../../common/switches/GenericSwitch';

import { LiveVariant } from '../EngagementsConstants';

const hasDataChanged = (original: LiveVariant[], current: LiveVariant[]) => {
  return JSON.stringify(original) !== JSON.stringify(current);
};

const DUMMY_TABLE_DATA: LiveVariant[] = [
  {
    name: 'SMS reactivations',
    description: 'Reactivate churned customers',
    performance: 3,
    offer: '$50 in-store credit',
    channel: 'SMS',
    numberOfRecipientsSent: 54,
    totalNumberOfRecipients: 54,
    enabled: true,
    message: '',
  },
  {
    name: 'Email reactivations',
    description: 'This is the second variant used',
    performance: 2,
    offer: '',
    channel: 'Email',
    numberOfRecipientsSent: 154,
    totalNumberOfRecipients: 154,
    enabled: true,
    message: '',
  },
];

function ViewEngagementPage() {
  const [showRetiredVariants, setShowRetiredVariants] = useState(false);
  const [variants, setVariants] = useState<LiveVariant[]>(DUMMY_TABLE_DATA);
  const navigate = useNavigate();
  const next = () => {
    navigate('/engagements/edit/delivery');
  }
  const back = () => {
    navigate('/engagements/view');
  }
  const hasChanged = hasDataChanged(DUMMY_TABLE_DATA, variants);
  return (
    <article className={'edit-engagement-page'}>
      <section className={'page'}>
        <header className={'edit-engagement-page-header'}>
          <h2 className={'title'}>{'Reactivate churned customers'}</h2>
          <h3 className={'subtitle'}>{'Optimise your live engagement'}</h3>
        </header>
        <section className={'edit-engagement-page-content'}>
          <section className={'button-headers'}>
            <GenericSwitch label={'Show retired variants'} checked={showRetiredVariants} setChecked={setShowRetiredVariants} />
            { hasChanged && <PrimaryButton content={'Apply changes'} /> }
          </section>
          <EditEngagementTable variants={variants} setVariants={setVariants}/>
        </section>
      </section>
      <footer className={'footer'}>
        <section className={'footer-left'}>
          <OutlineButton content={'Back'} onClick={back} />
          <PrimaryButton content={'Next'} onClick={next} />
        </section>
        <section className={'footer-right'}>{'1/3 steps'}</section>
      </footer>
    </article>
  );
}

export default ViewEngagementPage;