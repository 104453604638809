import './WhiteCheckbox.scss';

import { CheckboxProps } from './CheckboxConstants';
import GenericCheckbox from './GenericCheckbox';

import BLACK_CHECK_MARK_ICON from './assets/black-checkmark-icon.svg';

function WhiteCheckbox({ checked, setChecked, label = null, id = '' } : CheckboxProps) {
  return (
    <GenericCheckbox
      checked={checked}
      setChecked={setChecked}
      label={label}
      checkIcon={BLACK_CHECK_MARK_ICON}
      additionalClassNames={['white']}
      id={id}
    />
  );
}

export default WhiteCheckbox;