import SMS_ICON from './assets/sms-icon.svg';
import SELECTED_SMS_ICON from './assets/selected-sms-icon.svg';
import EMAIL_ICON from './assets/email-icon.svg';
import SELECTED_EMAIL_ICON from './assets/selected-email-icon.svg';

import {
  EngagementStatus,
  Autoresponse,
  EngagementAdvancedSettings,
  Engagement,
  Channel,
  ForwardingPreference,
  Variant,
  DeliveryMethod
} from './EngagementsTypes';

const { WorkRex } = DeliveryMethod;
const { Draft, Scheduled, Generating, Complete, Active, All, Archived } = EngagementStatus;

export const ENGAGEMENT_FILTER_OPTIONS = [
  {
    displayText: 'All engagements',
    value: All,
  },
  {
    displayText: 'Draft',
    value: Draft,
  },
  {
    displayText: 'Generating',
    value: Generating,
  },
  {
    displayText: 'Scheduled',
    value: Scheduled,
  },
  {
    displayText: 'Active',
    value: Active,
  },
  {
    displayText: 'Complete',
    value: Complete,
  },
  {
    displayText: 'Archived',
    value: Archived,
  },
];

interface ChannelIcon {
  icon: string,
  selectedIcon: string,
  key: Channel,
};

export const EMPTY_AUTORESPONSE : Autoresponse = { reply: '', response: '' };

export const EMPTY_ADVANCED_SETTINGS : EngagementAdvancedSettings = {
  forwardingPreference: null,
  emailAddress: '',
  autoresponses: [EMPTY_AUTORESPONSE],
}

export const CHANNEL_ICONS: ChannelIcon[] = [
  {
    icon: SMS_ICON,
    selectedIcon: SELECTED_SMS_ICON,
    key: Channel.SMS,
  },
  {
    icon: EMAIL_ICON,
    selectedIcon: SELECTED_EMAIL_ICON,
    key: Channel.Email,
  },
];

export const EMPTY_VARIANT: Variant = {
  _id: null,
  name: '',
  campaignPurpose: '',
  offer: null,
  channel: null,
  baseMessage: '',
  advancedSettings: { ...EMPTY_ADVANCED_SETTINGS },
  recipients: [],
  messages: [],
};

export const EMPTY_ENGAGEMENT: Engagement = {
  _id: null,
  name: '',
  description: '',
  date: null,
  batchSend: '',
  channels: [],
  totalVariants: 0,
  totalRecipients: 0,
  variants: [
    { ...EMPTY_VARIANT, name: 'Variant 1' },
  ],
  prompt: '',
  personalizationVariables: [],
  status: Draft,
  attribution: '',
  metrics: [],
  hyperpersonalise: false,
  deliveryMethod: WorkRex,
  showUnsubscribeMessage: true,
  unsubscribeMessage: 'Reply STOP to opt out',
  showSignature: true,
  signature: 'Sent by [Name] from [Business]',
  archivedDate: null,
};

export type LiveVariant = {
  name: string,
  description: string,
  performance: number,
  offer: string | null,
  channel: string | null,
  numberOfRecipientsSent: number,
  totalNumberOfRecipients: number,
  message: string,
  enabled: boolean,
};

export type TextToDisplay = {
  text: string,
  index: number,
  fieldName: string,
  validate?: (value: string) => boolean,
};

export const DeliveryMethodOptions = [
  {
    displayText: WorkRex,
    value: WorkRex,
  }
];

export const FORWARDING_PREFERENCES = [
  {
    displayText: ForwardingPreference.NONE,
    value: ForwardingPreference.NONE,
  },
  {
    displayText: ForwardingPreference.ALL_REPLIES,
    value: ForwardingPreference.ALL_REPLIES,
  },
  {
    displayText: ForwardingPreference.ONLY_REPLIES_THAT_MAY_REQUIRE_CUSTOMER_ACTION,
    value: ForwardingPreference.ONLY_REPLIES_THAT_MAY_REQUIRE_CUSTOMER_ACTION,
  },
];
