import './EllipsisDropdown.scss';

import { useState, useRef } from 'react';

import Button from '../buttons/Button';
import { OptionType } from './DropdownConstants';
import GhostButton from '../buttons/GhostButton';

import ELLIPSIS_ICON from './assets/ellipsis-icon.svg';
import { useOutsideClick } from '../../../hooks/ClickHooks';

export interface EllipsisDropdownProps<T> {
  options: OptionType<T>[],
  additionalClassNames?: string[],
  onOpen?: () => void,
  onClose?: () => void,
  onSelection?: () => void,
};

function EllipsisDropdown<T>({
  options,
  additionalClassNames = [],
  onOpen = () => {},
  onClose = () => {},
  onSelection = () => {}
} : Readonly<EllipsisDropdownProps<T>>) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownClassName = ['ellipsis-dropdown', ...additionalClassNames].join(' ');
  const buttonClassNames = open ? ['ellipsis-button', 'open'] : ['ellipsis-button'];
  const toggleDropdown = () => {
    setOpen(!open);
    if (!open) {
      onOpen();
    }
    else {
      onClose();
    }
  }
  const optionList = options.map(({ displayText, onClick }) => {
    const buttonContent = (
      <span className={'button-content'}>
        <span className={'text'}>{displayText}</span>
      </span>
    );
    const selectOption = () => {
      if (onClick) {
        onClick();
      }
      setOpen(false);
      onSelection();
    }
    return (
      <li className={'dropdown-option'} key={displayText}>
        <Button additionalClassNames={['dropdown-selection-option']} content={buttonContent} onClick={selectOption} />
      </li>
    );
  });
  const buttonContent = (
    <span className={'button-content'}>
      <img className={'ellipsis-icon'} src={ELLIPSIS_ICON} alt={'ellipsis'} />
    </span>
  );
  const dropdownOptions = (
    <section className={'dropdown-options-container'}>
      <ul className={'dropdown-options'}>
        {optionList}
      </ul>
    </section>
  );
  useOutsideClick(dropdownRef, () => setOpen(false));
  return (
    <section className={dropdownClassName} ref={dropdownRef}>
      <GhostButton
        additionalClassNames={buttonClassNames}
        onClick={toggleDropdown}
        content={buttonContent}
      />
      { open && options.length > 0 && dropdownOptions }
    </section>
  )
}

export default EllipsisDropdown;