import { getData } from './Api';

import { User } from '../types/UserTypes';

const GetUserUrls = {
  GetUserUrl: '/api/user',
};

const { GetUserUrl } = GetUserUrls;

interface UserRequest {
  user: User | null,
  success: boolean,
}

/**
 * Retrieves the user from the server.
 */
export async function getUser(): Promise<UserRequest> {
  return getData(GetUserUrl);
}