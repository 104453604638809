import './MetricRow.scss';

import React from 'react';

import Dropdown from '../../dropdowns/Dropdown';
import PrimaryInput from '../../inputs/PrimaryInput';
import DateInput from '../../inputs/DateInput';
import {
  MetricAttributeKey,
  METRIC_ATTRIBUTES,
  METRIC_CONDITION_MAPPINGS,
  METRIC_ATTRIBUTES_DETAIL_MAP,
  METRIC_CONDITION_FUNCTIONS
} from './MetricConstants';
import { VALUE_TYPES } from '../../../../Constants';
import MultiSelectRow from '../MultiSelectRow';
import { ConditionKey } from '../MultiSelectRowConstants';

const { IsEmptyKey, IsNotEmptyKey } = ConditionKey;
const { DATE } = VALUE_TYPES;

const EMPTY_CONDITIONS = [IsEmptyKey, IsNotEmptyKey];

interface MetricRowProps {
  attribute: MetricAttributeKey | null,
  setAttribute: (attribute: MetricAttributeKey) => void,
  condition: ConditionKey | null,
  setCondition: (condition: ConditionKey) => void,
  input: string | number,
  setInput: (input: string | number) => void,
  deleteRow: () => void,
}

function MetricRow({ attribute, setAttribute, condition, setCondition, input, setInput, deleteRow } : Readonly<MetricRowProps>) {
  const showConditions = attribute !== null;
  const showInput = condition !== null && !EMPTY_CONDITIONS.includes(condition);

  // Need to figure out a better way to do this.
  const attributeType = showConditions ? METRIC_ATTRIBUTES_DETAIL_MAP[attribute].type : '';

  const dateAttribute = attributeType === DATE;
  const dateInput = dateAttribute && input
    ? new Date(input)
    : new Date();

  let inputField = null;
  if (showConditions && showInput) {
    const conditionObj = METRIC_CONDITION_FUNCTIONS[attributeType][condition];
    if (conditionObj && conditionObj.valueType === DATE) {
      inputField = <DateInput selectedDate={dateInput} setDate={setInput} />
    }
    else {
      inputField = <PrimaryInput placeholder={'Add value'} text={`${input}`} setText={setInput} />;
    }
  }
  
  return (
    <MultiSelectRow deleteRow={deleteRow}>
      <Dropdown defaultDisplayText={'Select attribute'} options={METRIC_ATTRIBUTES} selected={attribute} select={setAttribute} additionalClassNames={['metric-attributes-dropdown']} />
      { showConditions && <Dropdown defaultDisplayText={'Select condition'} options={METRIC_CONDITION_MAPPINGS[attributeType]} selected={condition} select={setCondition} additionalClassNames={['metric-conditions-dropdown']} /> }
      { showInput && inputField }
    </MultiSelectRow>
  );
}

export default MetricRow;