import './AddCompetitorOffersModal.scss';

import React, { useState } from 'react';

import Modal from '../../common/modals/Modal';
import PrimaryInput from '../../common/inputs/PrimaryInput';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import OutlineButton from '../../common/buttons/OutlineButton';
import { TrackedOffer, TrackingStatus } from '../CompetitorOffersConstants';
import CompetitorOfferRow from './CompetitorOfferRow';

const { Searching } = TrackingStatus;

interface AddCompetitorOffersModalProps {
  close: () => void,
  competitors: TrackedOffer[],
  confirm: (competitors: TrackedOffer[]) => void,
}

const MAX_COMPETITORS = 5;

function AddCompetitorOffersModal({ close, competitors, confirm } : Readonly<AddCompetitorOffersModalProps>) {
  const [competitorUrl, setCompetitorUrl] = useState('');
  const [competitorRows, setCompetitorRows] = useState(competitors);
  const competitorRowsDisplay = competitorRows.map(({ competitorName, url }, index) => {
    const deleteRow = () => setCompetitorRows(competitorRows.filter((_, i) => i !== index));
    return (
      <CompetitorOfferRow
        key={index}
        number={index + 1}
        name={competitorName}
        url={url}
        deleteRow={deleteRow}
      />
    );
  });
  const addDisabled = competitorUrl === '' || competitors.length >= MAX_COMPETITORS;
  const addRow = () => {
    if (competitorRows.length >= MAX_COMPETITORS) return;
    const newRows = [...competitorRows];
    newRows.push({ competitorName: 'New Company', url: competitorUrl, status: Searching });
    setCompetitorRows(newRows);
    setCompetitorUrl('');
  }
  const confirmChanges = () => {
    close();
    confirm(competitorRows);
  }
  return (
    <Modal additionalClassNames={['add-competitor-offers-modal']}>
      <header className={'modal-header'}>
        <h4 className={'modal-title'}>
          {'Add competitor offer'}
        </h4>
        <h5 className={'modal-subtitle'}>
          {'Add up to 5 competitors to track offers from'}
        </h5>
      </header>
      <section className={'modal-body'}>
        <section className={'competitor-input-container'}>
          <label className={'competitor-input-label'} htmlFor={'competitor-input'}>{'Competitor URL'}</label>
          <PrimaryInput id={'competitor-input'} placeholder={'Type URL'} text={competitorUrl} setText={setCompetitorUrl} additionalClassNames={['competitor-input']} />
          <PrimaryButton content={'Add'} onClick={addRow} disabled={addDisabled} />
        </section>
        <section className={'competitor-offer-rows'}>
          {competitorRowsDisplay}
        </section>
      </section>
      <section className={'modal-buttons'}>
        <OutlineButton content={'Cancel'} onClick={close} />
        <PrimaryButton content={'Confirm'} onClick={confirmChanges} disabled={competitorRows.length === 0} />
      </section>
    </Modal>
  );
}

export default AddCompetitorOffersModal