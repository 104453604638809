import './LocalBusinessPartnersPage.scss';

import { ReactElement, useState } from 'react';
import AutoComplete from 'react-google-autocomplete';
import { useNavigate } from 'react-router';

import { postCaseStudyRequest } from '../../apis/CaseStudyApi';
import PrimaryButton from '../common/buttons/PrimaryButton';
import MultiselectDropdown from '../common/dropdowns/MultiselectDropdown';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBN79DWVz6nD-htQLf37KfKv3Bnd4cbkxk';

export enum BusinessOption {
  BusinessType1 = 'Business Type 1',
  BusinessType2 = 'Business Type 2',
  BusinessType3 = 'Business Type 3',
}

const BusinessTypeOptions = [
  {
    displayText: 'Business Type 1',
    value: BusinessOption.BusinessType1,
  },
  {
    displayText: 'Business Type 2',
    value: BusinessOption.BusinessType2,
  },
  {
    displayText: 'Business Type 3',
    value: BusinessOption.BusinessType3,
  },
];

interface GoogleAddressObject {
  formatted_address: string,
}


function LocalBusinessPartnersPage(): ReactElement {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [businessOptions, setBusinessOptions] = useState<BusinessOption[]>([]);
  const [place, setPlace] = useState('');
  const requestPartners = async () => {
    setLoading(true);
    const firstName = 'Lance';
    const businessName = 'WorkRex';
    const message = `${firstName} from ${businessName} requested Local Business Partners for ${place} for the ${businessOptions.join(', ')} types.`;
    await postCaseStudyRequest(message);
    setLoading(false);
    navigate('/case-studies/home');
  }
  const onPlaceSelected = (place: GoogleAddressObject) => {
    setPlace(place.formatted_address);
  }
  const disabled = place.length === 0 || businessOptions.length === 0;
  return (
    <article className={'local-business-partners-page'}>
      <section className={'page'}>
        <header className={'local-business-partners-page-header'}>
          <h2 className={'title'}>{'Local business partners'}</h2>
          <h3 className={'subtitle'}>{'Build relationships with local businesses by partnering for employee perks'}</h3>
        </header>
        <section className={'page-content'}>
          <iframe
            className={'preview-iframe'}
            src={'https://www.youtube.com/embed/bRN7VOrrYiU?si=IyxtaxFXUjWY9fLI'}
            title={'Offer a perks program for local business employees'}
            allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'}
            referrerPolicy={'strict-origin-when-cross-origin'}
            allowFullScreen
          />
          <section className={'option-selection'}>
            <span className={'label'}>{'Select an area to search for business partners'}</span>
            <AutoComplete apiKey={GOOGLE_MAPS_API_KEY} onPlaceSelected={onPlaceSelected} />
          </section>
          <section className={'option-selection'}>
            <span className={'label'}>{'Business categories that you want to arrange a partnership with'}</span>
            <MultiselectDropdown
              options={BusinessTypeOptions}
              defaultDisplayText={'Select business types'}
              select={setBusinessOptions}
              selected={businessOptions}
            />
          </section>
          <PrimaryButton additionalClassNames={['cta-button']} content={'Request business partnerships'} disabled={disabled} onClick={requestPartners} loading={loading} />
        </section>
      </section>
    </article>
  );
}

export default LocalBusinessPartnersPage;