import './CompetitorOffersPage.scss';

import { useNavigate } from 'react-router';

import DualButtonToggle from '../../common/buttons/composite/DualButtonToggle';
import TrackCompetitorOffersDisplay from '../displays/TrackCompetitorOffersDisplay';
import ViewCompetitorOffersDisplay from '../displays/ViewCompetitorOffersDisplay';

interface CompetitorOffersPageProps {
  isTrackDisplay: boolean
}

function CompetitorOffersPage({ isTrackDisplay }: Readonly<CompetitorOffersPageProps>) {
  const navigate = useNavigate();
  const onLeftClick = () => navigate('/settings/competitors/track');
  const onRightClick = () => navigate('/settings/competitors/view');
  const display = isTrackDisplay ? <TrackCompetitorOffersDisplay /> : <ViewCompetitorOffersDisplay />;
  return (
    <article className={'competitor-offers-page'}>
      <header className={'competitor-offers-page-header'}>
        <h2 className={'title'}>{'Competitor offers'}</h2>
        <h3 className={'subtitle'}>{'Track, view and use offers from your competitors'}</h3>
      </header>
      <section className={'page-buttons'}>
        <DualButtonToggle
          leftButtonContent={'Track offers'}
          rightButtonContent={'View offers'}
          leftButtonOnClick={onLeftClick}
          rightButtonOnClick={onRightClick}
          leftSelected={isTrackDisplay}
          rightSelected={!isTrackDisplay}
        />  
      </section>
      <section className={'competitor-offers-page-content'}>
        {display}
      </section>
    </article>
  );
}

export default CompetitorOffersPage;