import { postData } from './Api';

const PostCaseStudyUrls = {
  RequestCaseStudyUrl: '/api/case-studies/request',
};

const { RequestCaseStudyUrl } = PostCaseStudyUrls;

interface CaseStudyRequest {
  message: string
};

/**
 * Sends a slack message for a case study request.
 */
export async function postCaseStudyRequest(message: string): Promise<boolean> {
  await postData<CaseStudyRequest, void>(
    RequestCaseStudyUrl, { message }
  );
  return true;
}

