import './GhostButton.scss';

import Button from './Button';
import { ButtonProps } from './ButtonConstants';
import BlackLoadingContent from './content/BlackLoadingContent';

function GhostButton(
  {
    additionalClassNames = [],
    onClick,
    content,
    disabled,
    loading,
    loadingContent = <BlackLoadingContent />,
    onMouseEnter,
    onMouseLeave, 
  } : Readonly<ButtonProps>)
{
  const classNames = ['ghost-button', ...additionalClassNames];
  return <Button
    additionalClassNames={classNames}
    onClick={onClick}
    content={content}
    disabled={disabled}
    loading={loading}
    loadingContent={loadingContent}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  />
}

export default GhostButton;