import './GeneratingMessagesModal.scss';

import Modal from '../../common/modals/Modal';
import ConfirmationModalContent from '../../common/modals/ConfirmationModalContent';

interface GeneratingMessagesModalProps {
  cancel: () => void,
};

function GeneratingMessagesModal({ cancel } : Readonly<GeneratingMessagesModalProps>) {
  return (
    <Modal additionalClassNames={['confirm-engagement-without-previewing-modal']}>
      <ConfirmationModalContent
        title={'Generating messages'}
        subtitle={`We will generate all your messages for preview. You can edit and publish the engagement once you’ve made your review. Generating messages can take up to 20 minutes.`}
        cancel={cancel}
        cancelText={'Close'}
      />
    </Modal>
  )
};

export default GeneratingMessagesModal;