import './ModalField.scss';

import React, { ReactNode } from 'react';

interface ModalFieldProps {
  children: ReactNode,
  label: string,
  additionalClassNames?: string[]
};

function ModalField({ children, label, additionalClassNames = [] }: Readonly<ModalFieldProps>) {
  return (
    <section className={`interaction-field ${additionalClassNames.join(' ')}`}>
      <label className={'interaction-label'} htmlFor={'interaction'}>{label}</label>
      {children}
    </section>
  );
}

export default ModalField;