import './BulkImportButtonBar.scss';

import PrimaryPlusButton from "../../../common/buttons/composite/PrimaryPlusButton";
import RecipientFlatfileImport from '../../../../utils/flatfile/RecipientFlatfileImport';
import InteractionFlatfileImport from '../../../../utils/flatfile/InteractionFlatfileImport';
import { RecipientFlatFileComponentProps } from '../../../../utils/flatfile/FlatFileConstants';
import { useState } from 'react';
import AddUpdateRecipientModal from '../modals/AddUpdateRecipientModal';
import { DEFAULT_RECIPIENT, Recipient } from '../../PeopleConstants';
import { postSaveOrUpdateRecipients } from '../../../../apis/PeopleApi';

function BulkImportButtonBar({ setRecipients } : RecipientFlatFileComponentProps) {
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);
  const confirm = (recipient: Recipient) => {
    postSaveOrUpdateRecipients([recipient])
      .then(response => {
        console.log(response)
      })
  }
  return (
    <section className={'recipients-import-buttons'}>
      <RecipientFlatfileImport setRecipients={setRecipients} />
      <PrimaryPlusButton content={'Add customer'} onClick={() => setOpenModal(true)} />
      {openModal && <AddUpdateRecipientModal close={closeModal} recipientToInsertUpdate={{ ...DEFAULT_RECIPIENT }} confirm={confirm} />}
      <InteractionFlatfileImport />
    </section>
  )
}

export default BulkImportButtonBar;