import ConfirmationModalContent from '../../common/modals/ConfirmationModalContent';
import Modal from '../../common/modals/Modal';

interface AddCompetitorOffersConfirmationModalProps {
  close: () => void,
};

function AddCompetitorOffersConfirmationModal({ close } : Readonly<AddCompetitorOffersConfirmationModalProps>) {
  return (
    <Modal additionalClassNames={['add-competitor-offers-confirmation-modal']}>
      <ConfirmationModalContent
        title={'Competitor offers selected'}
        subtitle={'Leave it with us! We will track the offers for the competitors you have chosen. Once offers are found, they can be viewed in the ‘view offers’ tab.'}
        confirm={close}
        confirmText={'Close'}
      />
    </Modal>
  );
}

export default AddCompetitorOffersConfirmationModal;