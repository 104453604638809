import Dropdown from '../../../common/dropdowns/Dropdown';
import MetricDisplay from './MetricDisplay';
import MetricTable from '../tables/MetricTable';
import { MetricAttributeType, MetricSegment, Metric, FrequencyOption } from '../SegmentsConstants';
import PrimaryButton from '../../../common/buttons/PrimaryButton';
import OutlineButton from '../../../common/buttons/OutlineButton';
import SubtlePlusButton from '../../../common/buttons/composite/SubtlePlusButton';
import SubtleButton from '../../../common/buttons/SubtleButton';
import { hasValidBoundNames, hasValidBounds } from '../SegmentsHelper';

const { DoNotSegment, AverageAnnualVisits, AverageAnnualPurchases } = FrequencyOption;

const FREQUENCY_OPTIONS = [
  { displayText: DoNotSegment, value: DoNotSegment },
  { displayText: AverageAnnualVisits, value: AverageAnnualVisits },
  { displayText: AverageAnnualPurchases, value: AverageAnnualPurchases },
];

interface FrequencyMetricDisplayProps {
  next: () => void,
  skip: () => void,
  back: () => void,
  addNewMetric: () => void,
  metric: Metric,
  setMetric: (metric: Metric) => void,
  canSkip: boolean,
};

function FrequencyMetricDisplay({ next, back, skip, addNewMetric, metric, setMetric, canSkip } : Readonly<FrequencyMetricDisplayProps> ) {
  const { metricOption } = metric;
  const rows = metric.bounds;
  const setFrequencyOption = (option: FrequencyOption) => setMetric({ ...metric, metricOption: option });
  const setRows = (rows: MetricSegment[]) => setMetric({ ...metric, bounds: rows });
  const hasValidSpendBounds = hasValidBounds(rows) && hasValidBoundNames(rows);
  const disabled = rows.length <= 1 || !hasValidSpendBounds;
  const metricSelectionOptions = (
    <section className={'metric-selection-options'}>
      <label className={'label'}>{'Choose a frequency metric'}</label>
      <Dropdown defaultDisplayText={'Frequency segment'} options={FREQUENCY_OPTIONS} select={setFrequencyOption} selected={metricOption as FrequencyOption | null} />
    </section>
  );
  const metricDisplay = <MetricTable segments={rows} setSegments={setRows} metricAttributeType={MetricAttributeType.Frequency} />;
  const metricButtons = (
    <section className={'metric-buttons'}>
      <OutlineButton content={'Back'} onClick={back} />
      { canSkip && <SubtleButton content={'Skip'} onClick={skip} /> }
      <SubtlePlusButton content={'Add new metric'} onClick={addNewMetric} />
      <PrimaryButton content={'Next'} onClick={next} disabled={disabled} />
    </section>
  )
  return (
    <MetricDisplay
      title={'Create a new segment'}
      subtitle={'Define the frequency boundaries for your customers in this segment'}
      metricSelectionOptions={metricSelectionOptions}
      metricDisplay={metricDisplay}
      metricButtons={metricButtons}
    />
  );
};

export default FrequencyMetricDisplay;