import './Button.scss';

import { ButtonProps } from './ButtonConstants';

function Button({
  additionalClassNames = [],
  content,
  onClick,
  disabled = false,
  loading = false,
  loadingContent = '',
  onMouseEnter,
  onMouseLeave,
}: Readonly<ButtonProps>) {
  const classNames = ['button', ...additionalClassNames, loading ? 'loading' : ''].join(' ');
  return (
    <button className={classNames} onClick={onClick} disabled={disabled} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {loading ? loadingContent : content}
    </button>
  )
}

export default Button;