import './ButtonContent.scss';

import LoadingRing from '../../loading/LoadingRing';

function WhiteLoadingContent() {
  return (
    <section className={'button-content'}>
      <LoadingRing color={'#FFFFFF'} />
      {'Loading'}
    </section>
  )
}

export default WhiteLoadingContent;