import './TrashIconButton.scss';

import { useState, MouseEvent } from 'react';

import GhostButton from '../GhostButton';

import TRASH_ICON from './assets/trash-icon.svg';
import HOVERED_TRASH_ICON from './assets/trash-icon-hovered.svg';

interface TrashIconButtonProps {
  onClick?: ((event: MouseEvent) => void),
  disabled?: boolean,
  onMouseEnter?: ((event: MouseEvent) => void),
  onMouseLeave?: ((event: MouseEvent) => void),
  additionalClassNames?: string[],
}

function TrashIconButton({ onClick, disabled, onMouseEnter, onMouseLeave, additionalClassNames = [] } : Readonly<TrashIconButtonProps>) {
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = (event: MouseEvent) => {
    setHovered(true);
    if (onMouseEnter) {
      onMouseEnter(event);
    }
  }
  const handleMouseLeave = (event: MouseEvent) => {
    setHovered(false);
    if (onMouseLeave) {
      onMouseLeave(event);
    }
  }
  const content = hovered
    ? <img src={HOVERED_TRASH_ICON} alt={''} />
    : <img src={TRASH_ICON} alt={''} />;
  return <GhostButton
    additionalClassNames={['trash-icon-button', ...additionalClassNames]}
    onClick={onClick}
    content={content}
    disabled={disabled}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  />
}

export default TrashIconButton