import { MetricSegment, MetricOption, FrequencyOption, SpendOption, Metric } from './SegmentsConstants';

export function hasValidBoundNames(rows: MetricSegment[]) {
  if (rows.some(({ boundName }) => boundName === '')) {
    return false;
  };
  const boundNames = new Set(rows.map(({ boundName }) => boundName));
  return boundNames.size === rows.length;
}

export function hasValidBounds(rows: MetricSegment[]) {
  if (rows.length === 0) {
    return false;
  }
  const numRows = rows.length;
  let prevUpperBound = null;
  for (let i = 0; i < numRows; i++) {
    if (i === 0) {
      if (parseFloat(rows[i].lowerBound).toFixed(2) !== parseFloat('0.00').toFixed(2)) {
        return false;
      }
      prevUpperBound = rows[i].upperBound;
      continue;
    }
    else if (i === numRows - 1) {
      if (rows[i].upperBound !== '') {
        return false;
      }
      if (prevUpperBound === null) {
        return false;
      }
      if (parseFloat(rows[i].lowerBound).toFixed(2) !== parseFloat(rows[i - 1].upperBound).toFixed(2)) {
        return false;
      }
      continue;
    }
    if (prevUpperBound === null) {
      return false;
    }
    if (parseFloat(rows[i].lowerBound).toFixed(2) !== parseFloat(prevUpperBound).toFixed(2)) {
      return false;
    }
    if (parseFloat(parseFloat(rows[i].lowerBound).toFixed(2)) >= parseFloat(parseFloat(rows[i].upperBound).toFixed(2))) {
      return false;
    }
  }
  return true;
}

function isWithinBounds(value?: number, lowerBound?: string, upperBound?: string) {
  const valueNumber = value ? value : 0;
  const lowerBoundNumber = lowerBound ? parseFloat(lowerBound) : 0;
  const upperBoundNumber = upperBound ? parseFloat(upperBound) : Infinity;
  return valueNumber >= lowerBoundNumber && valueNumber < upperBoundNumber;
}

function isRecipientWithinBound(recipient: any, bound: MetricSegment, metricOption: MetricOption | null) {
  const { lowerBound, upperBound } = bound;
  if (metricOption === SpendOption.AverageAnnualSpend) {
    return isWithinBounds(recipient.averageAnnualSpend, lowerBound, upperBound);
  }
  else if (metricOption === SpendOption.LifetimeSpend) {
    return isWithinBounds(recipient.lifetimeValue, lowerBound, upperBound);
  }
  else if (metricOption === SpendOption.AveragePurchaseValue) {
    return isWithinBounds(recipient.averagePurchaseValue, lowerBound, upperBound);
  }
  else if (metricOption === FrequencyOption.AverageAnnualVisits) {
    return isWithinBounds(recipient.averageAnnualVisits, lowerBound, upperBound);
  }
  else if (metricOption === FrequencyOption.AverageAnnualPurchases) {
    return isWithinBounds(recipient.averageAnnualPurchases, lowerBound, upperBound);
  }
  return true;
}

export function filterRecipientsWithBounds(recipients: any[], boundSet: { bound: MetricSegment, metricOption: MetricOption | null }[][]) {
  return recipients.filter((recipient) => boundSet.some((bounds) => bounds.every(({ bound, metricOption }) => isRecipientWithinBound(recipient, bound, metricOption))));
}

export function getBoundsWithMetricOptions(bounds: string[][], metrics: Metric[]) : { bound: MetricSegment, metricOption: MetricOption | null }[][] {
  return bounds.map((boundSet) => {
    return boundSet.flatMap((boundId, i) => {
     const bound = metrics[i].bounds.find(({ boundName }) => boundName === boundId);
     if (bound) {
      const metricOption = metrics[i].metricOption;
      return [{ bound, metricOption }];
     }
     return [];
    })
  });
}
