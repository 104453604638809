import './EngagementPerformance.scss';

import React, { useId } from 'react';

import PERFORMANCE_BAR from '../assets/performance-bar.svg';
import EMPTY_PERFORMANCE_BAR from '../assets/empty-performance-bar.svg';
import ALERT_ICON from '../assets/alert-icon.svg';
import Tooltip from '../../common/tooltips/Tooltip';

interface EngagementPerformanceProps {
  performance: number,
  showTooltip?: boolean,
  maxPerformance?: number,
}

function EngagementPerformance({ performance, maxPerformance = 4, showTooltip = true } : Readonly<EngagementPerformanceProps>) {
  const uuid = useId();
  const performanceBars = Array.from(Array(maxPerformance).keys()).map((value) => {
    if (value + 1 <= performance) {
      return <img className={'performance-bar filled'} src={PERFORMANCE_BAR} alt={'Performance bar'} key={`${uuid}-${value}`} />;
    }
    return <img className={'performance-bar empty'} src={EMPTY_PERFORMANCE_BAR} alt={'Empty performance bar'} key={`${uuid}-${value}`} />;
  })

  // TODO: Replace tooltip with IconTooltip component
  return (
    <section className={'engagement-performance'}>
      <ul className={'performance-bars'}>
        {performanceBars}
      </ul>
      { showTooltip && (
        <Tooltip content={'This campaign generated 4.5X ROI so you should continue running this offer'}>
          <img className={'tooltip-icon'} src={ALERT_ICON} alt={'Alert icon'} />
        </Tooltip>
      )}
    </section>
  )
}

export default EngagementPerformance;