import './MultiOptionSwitch.scss';

import { useId } from 'react';

import { MultiOptionSwitchProps } from './SwitchConstants';
import OutlineButton from '../buttons/OutlineButton';

function MultiOptionSwitch({ selectedOption, options, select, id = '', additionalClassNames = [] } : Readonly<MultiOptionSwitchProps<string>>) {
  const uuid = useId();
  const uniqueId = `${id}-${uuid}`;
  const classNames = ['multi-option-switch', ...additionalClassNames].join(' ');
  const buttonOptions = options.map(option =>
    <OutlineButton
      additionalClassNames={['borderless', option === selectedOption ? 'selected' : '']}
      onClick={() => select(option)}
      content={option}
    />
  );

  return (
    <section className={classNames}>
      {buttonOptions}
    </section>
  )
}

export default MultiOptionSwitch;