import './GreenTag.scss';

import { TagProps } from './TagConstants';

import Tag from './Tag';

function GreenTag({ content, additionalClassNames = [] }: Readonly<TagProps>) {
  return <Tag content={content} additionalClassNames={['green', ...additionalClassNames]} />;
};

export default GreenTag;