import './Dropdown.scss';

import { useState, useRef } from 'react';
import _ from 'lodash';

import Button from '../buttons/Button';
import { OptionType } from './DropdownConstants';
import { useOutsideClick } from '../../../hooks/ClickHooks';

import DEFAULT_DROPDOWN_ARROW from './assets/default-dropdown-arrow-icon.svg';
import TICK_ICON from './assets/tick-icon.svg';

// TODO: Create multiselect dropdown


type AdditionalOptionType = {
  content: string,
  onClick: () => void,
};

export interface MultiselectDropdownProps<T> {
  options: OptionType<T>[],
  defaultDisplayText?: string,
  additionalOption?: AdditionalOptionType | null,
  arrowIcon?: string,
  additionalClassNames?: string[],
  select: (values: T[]) => void,
  selected: T[],
  disabled?: boolean
};

function MultiselectDropdown<T>(
  {
    options,
    additionalOption = null,
    arrowIcon = DEFAULT_DROPDOWN_ARROW,
    defaultDisplayText = 'Select',
    additionalClassNames = [],
    select = () => {},
    selected,
    disabled = false
  } : MultiselectDropdownProps<T>)
{
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownClassName = ['dropdown', ...additionalClassNames, open ? 'dropdown-open' : ''].join(' ');
  const buttonClassNames = open ? ['dropdown-button', 'open'] : ['dropdown-button'];
  const toggleMultiselectDropdown = () => setOpen(!open);
  const optionList = options.map(({ displayText, value }) => {
    const selectedClass = selected.some(selectedValue => _.isEqual(selectedValue, value )) ? 'selected' : '';
    const selectionClassNames = ['dropdown-selection-option', selectedClass];
    const buttonContent = (
      <span className={'button-content'}>
        <img className={'selected-icon'} src={TICK_ICON} alt={''} />
        <span className={'text'}>{displayText}</span>
      </span>
    );
    const onClick = () => {
      if (selected.some(selectedValue => _.isEqual(selectedValue, value))) {
        select(selected.filter(selectedValue => !_.isEqual(selectedValue, value)));
      }
      else {
        select([...selected, value]);
      }
    }
    return (
      <li className={'dropdown-option'} key={displayText}>
        <Button additionalClassNames={selectionClassNames} content={buttonContent} onClick={onClick} />
      </li>
    );
  });
  let additionalOptionNode;
  if (additionalOption) {
    const { content, onClick } = additionalOption;
    const additionalButtonContent =(
      <span className={'button-content'}>
        <img className={'selected-icon'} src={''} alt={''} />
        <span className={'text'}>{content}</span>
      </span>
    );
    additionalOptionNode = (
      <li className={'dropdown-option'}>
        <Button additionalClassNames={['additional-options-button', 'dropdown-selection-option']} content={additionalButtonContent} onClick={onClick} />
      </li>
    )
  }
  let buttonDisplayText;
  if (selected.length === 0) {
    buttonDisplayText = defaultDisplayText;
  }
  else if (selected.length === 1) {
    const selectedOption = options.find(({ value }) => _.isEqual(value, selected[0]));
    buttonDisplayText = selectedOption ? selectedOption.displayText : defaultDisplayText;
  }
  else {
    buttonDisplayText = `${selected.length} selected`;
  }
  const buttonContent = (
    <span className={'button-content'}>
      <span className={'button-text'}>{buttonDisplayText}</span>
      <img className={'icon'} src={arrowIcon} alt={''} />
    </span>
  )
  const dropdownOptions = (
    <section className={'dropdown-options-container'}>
      <ul className={'dropdown-options'}>
        <section className={'main-options'}>
          {optionList}
        </section>
        {!!additionalOptionNode && options.length > 0 && <hr className={'divider'} />}
        {additionalOptionNode}
      </ul>
    </section>
  );
  useOutsideClick(dropdownRef, () => setOpen(false));
  return (
    <section className={dropdownClassName} ref={dropdownRef}>
      <Button
        additionalClassNames={buttonClassNames}
        onClick={toggleMultiselectDropdown}
        content={buttonContent}
        disabled={disabled}
      />
      { open && (options.length > 0 || additionalOptionNode) && dropdownOptions }
    </section>
  )
}

export default MultiselectDropdown;