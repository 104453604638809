export enum OfferMechanic {
  BOGO = 'BOGO',
  Upgrade = 'Upgrade',
  Discount = 'Discount',
  StoreCredit = 'Store credit',
}

export enum OfferStatus {
  Draft = 'Draft',
  Published = 'Published',
};

export enum ServiceType {
  Product = 'Product',
  Service = 'Service',
};

export enum OfferActivity {
  InUse = 'In use',
  NotInUse = 'Not in use',
};

export type Service = {
  _id: string | null,
  name: string,
  type: ServiceType | null,
  customise: string,
  recommendations: string,
};

export const ServiceTypeOptions = [
  { displayText: ServiceType.Service, value: ServiceType.Service },
  { displayText: ServiceType.Product, value: ServiceType.Product },
];

export interface Offer {
  _id: string | null,
  name: string,
  service: string | null,
  mechanics: OfferMechanic | null,
  rules: string,
  baseOffer: string,
  status: string,
  activity: OfferActivity,
};

export const OfferMechanicOptions = [
  {
    displayText: OfferMechanic.BOGO,
    value: OfferMechanic.BOGO,
  },
  {
    displayText: OfferMechanic.Upgrade,
    value: OfferMechanic.Upgrade,
  },
  {
    displayText: OfferMechanic.Discount,
    value: OfferMechanic.Discount,
  },
  {
    displayText: OfferMechanic.StoreCredit,
    value: OfferMechanic.StoreCredit,
  },
]


/** TODO: replace with actual data
export const PRODUCTS: Service[] = [
  {
    name: 'Body Sculpting',
    type: ServiceType.Service,
    frequency: 'Once per month',
    re: 'RF micro-needling, EMFace as both are great for skin toning',
  },
  {
    name: 'EMFACE',
    type: ServiceType.Service,
    frequency: 'Once per quarter',
    upsells: '',
  },
  {
    name: 'Laser hair removal',
    type: ServiceType.Service,
    frequency: 'Once per 6 weeks',
    upsells: 'Electrolysis where permanent hair removal is wanted',
  },
  {
    name: 'RF micro-needling',
    type: ServiceType.Service,
    frequency: 'Only when needed',
    upsells: 'The EMFACE is a great alternative to RF Microneedling without needles or pain',
  },
  {
    name: 'Hair loss treatments',
    type: ServiceType.Service,
    frequency: 'Only when needed',
    upsells: '',
  },
  {
    name: 'Laser tattoo removal',
    type: ServiceType.Service,
    frequency: '4 to 6 sessions',
    upsells: '',
  },
];
*/