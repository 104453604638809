import './Tooltip.scss';

import { ReactNode } from 'react';

interface TooltipProps {
  children: ReactNode | string,
  content: ReactNode | string,
  additionalClassNames?: string[],
  disabled?: boolean,
};

function Tooltip({ children, content, additionalClassNames = [], disabled = false } : TooltipProps) {
  const classNames = ['tooltip-container', ...additionalClassNames, disabled ? 'disabled' : ''];
  return (
    <section className={classNames.join(' ')}>
      <section className={'tooltip-item'}>
        {children}
      </section>
      <section className={'tooltip'}>
        <section className={'tooltip-content'}>
          {content}
        </section>
      </section>
    </section>
  );
};

export default Tooltip;