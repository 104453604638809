import './EngagementPreviewPage.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router';

import OutlineButton from '../../common/buttons/OutlineButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import RecipientSearchBar from '../modals/add-recipients/RecipientSearchBar';
import AIPromptSection from '../forms/AIPromptSection';
import PreviewMessage from '../previews/PreviewMessage';
import AIPromptModal from '../modals/AIPromptModal';
import useAuth from '../../../context/AuthContext';
import { getEmptyEngagement } from '../helpers/EngagementHelpers';
import { useLocalStorage, LocalStorageKey } from '../../../hooks/LocalStorageHooks';
import { Engagement, Variant, EngagementStatus } from '../EngagementsTypes';
import ConfirmEngagementWithoutPreviewingModal from '../modals/ConfirmEngagementWithoutPreviewingModal';
import SubtleButton from '../../common/buttons/SubtleButton';
import GeneratingMessagesModal from '../modals/GeneratingMessagesModal';
import { postPublishEngagement, postGeneratePreviewsForEngagement } from '../../../apis/EngagementApi';
import { User } from '../../../types/UserTypes';
import { PersonalizationVariable } from '../helpers/AIPromptHelpers';
import { postGeneratePreviews } from '../../../apis/EngagementApi';
import CreateEngagementModal from '../modals/CreateEngagementModal';

const { CreateEngagementKey } = LocalStorageKey;

const { Review } = EngagementStatus;

interface EngagementPreviewPageProps {
  isEditingExistingEngagement?: boolean,
};

const MAX_NUM_PREVIEWS = 5;

function EngagementPreviewPage({ isEditingExistingEngagement = false } : Readonly<EngagementPreviewPageProps>) {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const [showingConfirmEngagementModal, setShowingConfirmEngagementModal] = useState(false);
  const emptyEngagement = getEmptyEngagement(user?.firstName, user?.business?.name);
  const [engagement, setEngagement] = useLocalStorage<Engagement>(CreateEngagementKey, emptyEngagement);
  const { variants, status, personalizationVariables, date, batchSend } = engagement;
  const setDate = (date: number) => setEngagement({ ...engagement, date });
  const setBatchSend = (batchSend: string) => setEngagement({ ...engagement, batchSend });
  const [prompt, setPrompt] = useState(engagement.prompt);
  const savePrompt = async (prompt: string, personalizationVariables: PersonalizationVariable[]) => {
    // We empty out the recipient messages so the preview messages get re-generated
    const newEngagement = { ...engagement, prompt, personalizationVariables, variants: engagement.variants.map((variant) => ({ ...variant, messages: [] })) }
    const engagementWithPreviews = await postGeneratePreviews(newEngagement);
    setEngagement(engagementWithPreviews);
  }
  const [aiModalOpen, setAIModalOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loadingGenerateAllPreviewsButton, setLoadingGenerateAllPreviewsButton] = useState(false);
  const [showingGeneratingMessagesModal, setShowingGeneratingMessagesModal] = useState(false);
  const [showingConfirmWithoutPreviewingModal, setShowingConfirmWithoutPreviewingModal] = useState(false);
  const back = () => isEditingExistingEngagement ? navigate('/engagements/edit/delivery') : navigate('/engagements/delivery');
  const variantMessages = variants
    .flatMap(({ messages, channel }, variantIndex) => 
      messages ? messages.map((message, messageIndex) => ({ ...message, channel, variantIndex, messageIndex })) : [])
  const numberOfRecipients = variants.flatMap(({ recipients }) => recipients).length;
  const createEngagement = () => {
    setShowingConfirmEngagementModal(true);
  };
  const stepText = isEditingExistingEngagement ? '3/3 steps' : '4/4 steps';
  const buttonText = isEditingExistingEngagement ? 'Save engagement' : 'Create engagement';
  const numberOfMessages = variantMessages.length;
  const allMessagesGenerated = numberOfMessages === numberOfRecipients;
  const previewSubtitle = allMessagesGenerated
  ? (
    <span className={'subtitle-text'}>
      {'Review messages to '}
      <b>{numberOfRecipients}</b>
      {' recipients before they are sent, and edit them to your liking'}
    </span>
  )
  : (
    <span className={'subtitle-text'}>
      {'There are '}
      <b>{numberOfMessages}</b>
      {' preview messages for you to review. You can choose to generate all '}
      <b>{numberOfRecipients}</b>
      {' messages for review by hitting the \'generate\' button.'}
    </span>
  );
  const setVariant = (variant: Variant, variantIndex: number) => {
    const newVariants = [...engagement.variants];
    newVariants[variantIndex] = variant;
    setEngagement({ ...engagement, variants: newVariants });
  }
  const setVariantMessage = (variantIndex: number, message: string, messageIndex: number) => {
    const variant = variants[variantIndex];
    const newMessages = variant.messages;
    const newMessage = newMessages[messageIndex];
    newMessages[messageIndex] = { ...newMessage, message };
    const newVariant = { ...variant, messages: newMessages };
    setVariant(newVariant, variantIndex);
  }
  const openAIModal = () => setAIModalOpen(true);
  const closeAIModal = () => setAIModalOpen(false);
  const confirmWithoutPreviewing = async (setLoading: (loading: boolean) => void) => {
    setLoading(true);
    const { engagements }  = await postPublishEngagement(engagement);
    setLoading(false);
    setUser({ ...user, engagements } as User);
    navigate('/engagements');
  }
  const generateAllPreviews = async () => {
    setLoadingGenerateAllPreviewsButton(true);
    const { engagements }  = await postGeneratePreviewsForEngagement(engagement);
    setUser({ ...user, engagements } as User);
    setLoadingGenerateAllPreviewsButton(false);
    setShowingGeneratingMessagesModal(true);
    // TODO: Update backend to Generating state
  }

  const isReview = status === Review;
  const generatePreviewsText = isReview ? 'Re-generate all previews' : 'Generate all previews';
  const closeConfirmWithoutPreviewingModal = () => setShowingConfirmWithoutPreviewingModal(false);
  const closeGeneratingMessagesModal = () => {
    navigate('/engagements');
  }
  const closeConfirmEngagementModal = () => setShowingConfirmEngagementModal(false);
  const confirmEngagement = async (setLoading: (loading: boolean) => void) => {

    if (numberOfRecipients !== variantMessages.length) {
      closeConfirmEngagementModal();
      setShowingConfirmWithoutPreviewingModal(true);
    }
    else {
      setLoading(true);
      await postPublishEngagement(engagement);
      setLoading(false);
      closeConfirmEngagementModal();
      navigate('/engagements');
    }
  }
  return (
    <article className={'engagement-preview-page'}>
      { aiModalOpen && <AIPromptModal prompt={prompt} setPrompt={setPrompt} closeModal={closeAIModal} savePrompt={savePrompt} initialSelectedVariables={personalizationVariables} /> }
      { showingConfirmEngagementModal && (
          <CreateEngagementModal
            cancel={closeConfirmEngagementModal}
            confirm={confirmEngagement}
            date={date}
            setDate={setDate}
            batchSend={batchSend}
            setBatchSend={setBatchSend}
          />
      )}
      { showingConfirmWithoutPreviewingModal && (
          <ConfirmEngagementWithoutPreviewingModal
            confirm={confirmWithoutPreviewing}
            cancel={closeConfirmWithoutPreviewingModal}
            numPreviews={MAX_NUM_PREVIEWS}
          />
      )}
      { showingGeneratingMessagesModal && (
        <GeneratingMessagesModal cancel={closeGeneratingMessagesModal} />
      )}
      <section className={'engagement-preview-page-content'}>
        <header className={'engagement-preview-page-header'}>
          <h2 className={'title'}>{'Preview your messages'}</h2>
          <section className={'subtitle-container'}>
            <section className={'subtitle-and-search'}>
              <h3 className={'subtitle'}>
                {previewSubtitle}
              </h3>
              <RecipientSearchBar searchText={searchText} setSearchText={setSearchText} />
            </section>
            <AIPromptSection openAIModal={openAIModal} />
          </section>
        </header>
        { variantMessages.length > 0 &&
          <PreviewMessage
            previews={variantMessages}
            setPreview={setVariantMessage}
            totalMessages={numberOfMessages}
            totalRecipients={numberOfRecipients}
          />
    }
      </section>
      <footer className={'footer'}>
        <section className={'footer-left'}>
          <OutlineButton content={'Back'} onClick={back} />
          <SubtleButton content={generatePreviewsText} onClick={generateAllPreviews} loading={loadingGenerateAllPreviewsButton} additionalClassNames={[isReview ? 'review' : '']} />
          <PrimaryButton content={buttonText} onClick={createEngagement} />
        </section>
        <section className={'footer-right'}>{stepText}</section>
      </footer>
    </article>
  );
}

export default EngagementPreviewPage;