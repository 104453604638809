import './CrossIconButton.scss';

import { MouseEvent } from 'react';

import GhostButton from '../GhostButton';

import CROSS_ICON from './assets/cross-icon.svg';

interface CrossIconButtonProps {
  onClick?: ((event: MouseEvent) => void),
  disabled?: boolean,
  additionalClassNames?: string[],
}

function CrossIconButton({ onClick, disabled, additionalClassNames = [] } : Readonly<CrossIconButtonProps>) {
  const content = <img src={CROSS_ICON} alt={''} />;
  return <GhostButton
    additionalClassNames={['cross-icon-button', ...additionalClassNames]}
    onClick={onClick}
    content={content}
    disabled={disabled}
  />
}

export default CrossIconButton