import './CreateSegmentsTable.scss';

import { useState } from 'react';

import Table, { TableHeaderProps } from '../../../common/tables/Table';
import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';
import PaginationBar from '../../../common/tables/PaginationBar';
import { Segment, Metric } from '../SegmentsConstants';
import PrimaryPlusButton from '../../../common/buttons/composite/PrimaryPlusButton';
import EditTextModal from '../../../common/modals/EditTextModal';
import { TextToDisplay } from '../../../engagements/EngagementsConstants';
import { OptionType } from '../../../common/dropdowns/DropdownConstants';
import Tooltip from '../../../common/tooltips/Tooltip';
import { isEqual } from 'lodash';
import { getBoundsWithMetricOptions, filterRecipientsWithBounds } from '../SegmentsHelper';
import useAuth from '../../../../context/AuthContext';

import INFO_ICON from '../assets/information-icon.svg';
import CUSTOMIZE_ICON from '../assets/customize-icon.svg';
import MultiselectDropdown from '../../../common/dropdowns/MultiselectDropdown';

const CREATE_SEGMENTS_HEADERS: TableHeaderProps[] = [
  { displayText: 'Segment name', key: 'segmentName' },
  { displayText: 'Boundary selection', key: 'boundarySelection' },
  { displayText: 'Customers', key: 'numberOfCustomers' },
  { 
    displayText: (
      <span className={'cell-text'}>
        <span className={'cell-content'}>
          <img className={'column-icon'} src={CUSTOMIZE_ICON} alt={''} />
          {'Customise'}
        </span>
        <Tooltip content={'Provide AI with context to better personalise messages to this customer segment'}><img className={'info-icon'} src={INFO_ICON} alt={''} /></Tooltip>
      </span>
    ),
    key: 'customise' 
  },
  { displayText: 'Delete', key: 'delete' },
];
const CREATE_SEGMENTS_MAX_ROWS = 5;

interface CreateSegmentsTableProps {
  segments: Segment[];
  setSegments: (segments: Segment[]) => void;
  categories: OptionType<any>[],
  metrics: Metric[],
}

const EMPTY_SEGMENT: Segment = {
  segmentName: '',
  bounds: [],
  description: '',
  customiseMessage: '',
};

function CreateSegmentsTable({ segments, setSegments, categories, metrics } : Readonly<CreateSegmentsTableProps>) {
  const { user } = useAuth();
  const recipients = user && user.recipients ? user.recipients : [];
  const [textToDisplay, setTextToDisplay] = useState<TextToDisplay | null>(null);
  const [cursor, setCursor] = useState(0);
  const addSegment = () => setSegments([...segments, { ...EMPTY_SEGMENT }]);
  const setSegment = (segment: Segment, index: number) => {
    const newSegments = [...segments];
    newSegments[index] = segment;
    setSegments(newSegments);
  };
  const setBoundary = (index: number) => (bounds: string[][]) => {
    setSegment({ ...segments[index], bounds }, index);
  };
  const displayText = (text: string, index: number, fieldName: string) => () => setTextToDisplay({ text, index, fieldName });
  const deleteBound = (index: number) => setSegments(segments.filter((_, i) => i !== index));
  const rows = segments.map(({ segmentName, bounds, customiseMessage }, index) => {
    const boundSetWithMetricOption = getBoundsWithMetricOptions(bounds ? bounds : [], metrics);
    const numberOfCustomers = filterRecipientsWithBounds(recipients, boundSetWithMetricOption).length;
    const segmentNameNode = <section className={'two-cell center short-text'} onClick={displayText(segmentName, index, 'segmentName')}>{segmentName}</section>;

    // We only want to show categories that are not already selected by other segments)
    const selectedBoundsByOtherSegments = segments.filter((_, i) => i !== index).flatMap(({ bounds }) => bounds);
    const availableCategories = categories.filter(({ value }) => !selectedBoundsByOtherSegments.some((selectedBounds) => isEqual(selectedBounds, value)));
    const boundsNode = <MultiselectDropdown defaultDisplayText={'Select categories'} options={availableCategories} additionalClassNames={['two-cell']} select={setBoundary(index)} selected={bounds} />;
    const numberOfCustomersNode = <section className={'one-cell'}>{numberOfCustomers}</section>;
    const customiseNode = <section className={'two-cell center long-text'} onClick={displayText(customiseMessage, index, 'customiseMessage')}>{customiseMessage}</section>
    const deleteNode = <TrashIconButton onClick={() => deleteBound(index)} additionalClassNames={['one-cell']} />;
    return {
      cells: [
        { key: 'segmentName', value: segmentNameNode },
        { key: 'boundsNode', value: boundsNode },
        { key: 'numberOfCustomers', value: numberOfCustomersNode },
        { key: 'customise', value: customiseNode },
        { key: 'delete', value: deleteNode },
      ]
    }
  });
  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  const confirmTextChanges = (text: string) => {
    if (textToDisplay) {
      const { index, fieldName } = textToDisplay;
      setSegment({ ...segments[index], [fieldName]: text }, index);
    }
    closeTextToDisplayModal();
  }
  const modalText = textToDisplay ? textToDisplay.text : '';
  return (
    <>
      { showingText && <EditTextModal text={modalText} confirm={confirmTextChanges} close={closeTextToDisplayModal} /> }
      <section className={'create-segments-table-container'}>
        <Table
          additionalClassNames={['create-segments-table']}
          headers={CREATE_SEGMENTS_HEADERS}
          rows={rows}
          cursor={cursor}
          maxRows={CREATE_SEGMENTS_MAX_ROWS}
        />
        <PrimaryPlusButton content={'Add segment'} onClick={addSegment} additionalClassNames={['add-segment-button']}/>
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={CREATE_SEGMENTS_MAX_ROWS}
            totalRows={segments.length}
          />
        </section>
      </section>
    </>
  )
}

export default CreateSegmentsTable;

