import './RedTag.scss';

import { TagProps } from './TagConstants';

import Tag from './Tag';

function RedTag({ content, additionalClassNames = [] }: Readonly<TagProps>) {
  return <Tag content={content} additionalClassNames={['red', ...additionalClassNames]} />;
};

export default RedTag;