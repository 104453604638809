import { FlatfileProvider } from "@flatfile/react";

import { prodFlatFileAuth } from "../../Constants";
import InteractionFlatFileButton from "../../components/people/recipients/common/InteractionFlatFileButton";
import { flatfileConfig } from "./FlatFileConstants";

function InteractionFlatfileImport() {
  return (
    <FlatfileProvider
      publishableKey={prodFlatFileAuth.publishableKey}
      config={flatfileConfig}
    >
      <InteractionFlatFileButton />
    </FlatfileProvider>
  )
};

export default InteractionFlatfileImport;