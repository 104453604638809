import './PrimaryInput.scss';

import React, { FormEvent } from 'react';

export interface PrimaryInputProps {
  text: string,
  setText: ((value: string) => void),
  placeholder: string,
  id?: string,
  disabled?: boolean,
  additionalClassNames?: string[],
}

function PrimaryInput({ text, setText, placeholder, id = '', disabled = false, additionalClassNames = [] }: Readonly<PrimaryInputProps>) {
  const onChange = (event: FormEvent<HTMLInputElement>) => setText((event.target as HTMLInputElement).value);
  return <input
    id={id}
    type={'text'}
    className={`primary-input ${additionalClassNames.join(' ')}`}
    placeholder={placeholder}
    value={text}
    onChange={onChange}
    disabled={disabled}
  />;
}

export default PrimaryInput;