import { postData } from './Api';

import {
  MobileLoginRequest,
  MobileVerifyLoginRequest,
  EmailLoginRequest,
  EmailVerifyLoginRequest,
  SuccessResponse,
  LoginSuccessResponse
} from './types/LoginTypes';

const PostLoginUrls = {
  SubmitMobileLoginUrl: '/api/login/submit/mobile',
  SubmitEmailLoginUrl: '/api/login/submit/email',
  VerifyMobileLoginUrl: '/api/login/verify/mobile',
  VerifyEmailLoginUrl: '/api/login/verify/email',
}

const { SubmitMobileLoginUrl, SubmitEmailLoginUrl, VerifyMobileLoginUrl, VerifyEmailLoginUrl } = PostLoginUrls;

/**
 * Submits mobile login credentials. 
 * Triggers a verification code to be texted to the mobile number of the user if skip verification code is set to false. 
 */
export async function postSubmitMobileLogin(mobileNumber: string, skipVerificationCode?: boolean): Promise<boolean> {
  const { success } = await postData<MobileLoginRequest, SuccessResponse>(
    SubmitMobileLoginUrl, { mobileNumber, skipVerificationCode }
  );
  return success;
}

/**
 * Submits email login credentials. 
 * Triggers a verification code to be texted to the email address of the user if skip verification code is set to false. 
 */
export async function postSubmitEmailLogin(emailAddress: string, skipVerificationCode?: boolean): Promise<boolean> {
  const { success } = await postData<EmailLoginRequest, SuccessResponse>(
    SubmitEmailLoginUrl, { emailAddress, skipVerificationCode }
  );
  return success;
}

/**
 * Verifies a mobile login with the given code.
 */
export async function postVerifyMobileLogin(mobileNumber: string, code: string): Promise<LoginSuccessResponse> {
  const { success, user, token } = await postData<MobileVerifyLoginRequest, LoginSuccessResponse>(
    VerifyMobileLoginUrl, { mobileNumber, code }
  );
  return { success, user, token };
}

/**
 * Submits email login credentials. 
 * Triggers a verification code to be texted to the email address of the user if skip verification code is set to false. 
 */
export async function postVerifyEmailLogin(emailAddress: string, code: string): Promise<LoginSuccessResponse> {
  const { success, user, token } = await postData<EmailVerifyLoginRequest, LoginSuccessResponse>(
    VerifyEmailLoginUrl, { emailAddress, code }
  );
  return { success, user, token };
}