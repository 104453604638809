import './ConfirmEngagementWithoutPreviewingModal.scss';

import { useState } from 'react';

import Modal from '../../common/modals/Modal';
import ConfirmationModalContent from '../../common/modals/ConfirmationModalContent';

interface ConfirmEngagementWithoutPreviewingModalProps {
  cancel: () => void,
  confirm: (setLoading: (loading: boolean) => void) => void,
  numPreviews: number,
};

function ConfirmEngagementWithoutPreviewingModal({ cancel, confirm, numPreviews } : Readonly<ConfirmEngagementWithoutPreviewingModalProps>) {
  const [loading, setLoading] = useState(false);
  const confirmEngagement = () => {
    confirm(setLoading);
  }
  return (
    <Modal additionalClassNames={['confirm-engagement-without-previewing-modal']}>
      <ConfirmationModalContent
        title={'Create anyway?'}
        subtitle={`You have only previewed ${numPreviews} of your messages. Would you like to launch the engagement anyways?`}
        cancel={cancel}
        confirm={confirmEngagement}
        loading={loading}
      />
    </Modal>
  )
};

export default ConfirmEngagementWithoutPreviewingModal;