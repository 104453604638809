import './EngagementDeliveryPage.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router';

import OutlineButton from '../../common/buttons/OutlineButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import EngagementDeliveryForm from '../forms/EngagementDeliveryForm';
import { Engagement } from '../EngagementsTypes';
import { useLocalStorage, LocalStorageKey } from '../../../hooks/LocalStorageHooks';
import SubtleButton from '../../common/buttons/SubtleButton';
import useAuth from '../../../context/AuthContext';
import { postSaveDraftEngagement, postGeneratePreviews } from '../../../apis/EngagementApi';
import { User } from '../../../types/UserTypes';
import { getEmptyEngagement } from '../helpers/EngagementHelpers';

const { CreateEngagementKey, EditEngagementKey } = LocalStorageKey;

interface EngagementDeliveryPageProps {
  isEditingExistingEngagement?: boolean,
};

/**
 * Returns if an engagement is not in a state where it can be published.
 * 
 * The name and description both must be non-empty.
 * Date needs to be formatted to dd/mm/yy. (TODO: validate date format)
 * Batch send must be a number greater than 0. (TODO: validate batch send)
 * Attribution must be a number greater than 0. (TODO: validate attribution)
 * @returns true if the engagement is disabled.
 */
function isEngagementDisabled(engagement: Engagement, isEditing: boolean) {
  const { name, description, date, batchSend, attribution, deliveryMethod } = engagement;
  if (isEditing) {
    return !date || !batchSend || !attribution || !deliveryMethod;
  }
  return !name || !description || !date || !batchSend || !attribution || !deliveryMethod;
}

function EngagementDeliveryPage({ isEditingExistingEngagement = false } : Readonly<EngagementDeliveryPageProps>) {
  const key = isEditingExistingEngagement ? EditEngagementKey : CreateEngagementKey;
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const emptyEngagement = getEmptyEngagement(user?.firstName, user?.business?.name);
  const [engagement, setEngagement, clearEngagement] = useLocalStorage<Engagement>(key, emptyEngagement);
  const [loadingDraftButton, setLoadingDraftButton] = useState(false);
  const disabled = isEngagementDisabled(engagement, isEditingExistingEngagement);
  const navigate = useNavigate();
  const back = () => isEditingExistingEngagement ? navigate('/engagements/edit') : navigate('/engagements/recipients');
  const saveDraft = async () => {
    setLoadingDraftButton(true);
    const { engagements } = await postSaveDraftEngagement(engagement);
    setUser({ ...user as User, engagements });
    clearEngagement();
    setLoadingDraftButton(false);
    navigate('/engagements');
  };
  const generatePreviews = async () => {
    if (loading) {
      return;
    }
    const { variants } = engagement;
    setLoading(true);
    const hasGeneratedMessages = variants
      .flatMap(({ messages, recipients }) => {
        if (messages.every(({ recipientId }) => recipients.includes(recipientId))) {
          return messages;
        }
        else {
          return [];
        };
      })
      .length > 0;
    if (!hasGeneratedMessages) {
      const engagementWithPreviews = await postGeneratePreviews(engagement);
      setEngagement(engagementWithPreviews);
    }
    setLoading(false);
    navigate('/engagements/preview');
  }
  const stepText = isEditingExistingEngagement ? '2/3 steps' : '3/4 steps';
  const buttonText = 'Generate previews';
  return (
    <article className={'engagement-delivery-page'}>
      <section className={'engagement-delivery-page-content'}>
        <header className={'engagement-delivery-page-header'}>
          <h2 className={'title'}>{'Engagement Delivery'}</h2>
          <h3 className={'subtitle'}>{'Choose all the details for the delivery of your engagement'}</h3>
        </header>
        <EngagementDeliveryForm
          isEditingExistingEngagement={isEditingExistingEngagement}
          engagement={engagement}
          setEngagement={setEngagement}
        />
      </section>
      <footer className={'footer'}>
        <section className={'footer-left'}>
          <OutlineButton content={'Back'} onClick={back} />
          <SubtleButton content={'Save as draft'} onClick={saveDraft} loading={loadingDraftButton} />
          <PrimaryButton content={buttonText} onClick={generatePreviews} disabled={disabled} loading={loading} />
        </section>
        <section className={'footer-right'}>{stepText}</section>
      </footer>
    </article>
  );
}

export default EngagementDeliveryPage;