import './LoadingRing.scss';

interface LoadingRingProps {
  color: string
}

function LoadingRing({ color = '#FFFFFF' } : LoadingRingProps) {
  return (
    <div className={'lds-ring'} style={{ color }}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default LoadingRing;