import './RemovableTag.scss';

import { TagProps } from '../TagConstants';

import CrossIconButton from '../../buttons/composite/CrossIconButton';
import OutlineTag from '../OutlineTag';

interface RemovableTagProps extends TagProps {
  removeTag: () => void,
};

function RemovableTag({ content, additionalClassNames = [], removeTag }: Readonly<RemovableTagProps>) {
  const tagContent = (
    <>
      <span className={'tag-content'}>{content}</span>
      <CrossIconButton onClick={removeTag} />
    </>
  )
  return <OutlineTag content={tagContent} additionalClassNames={['outline', ...additionalClassNames]} />;
};

export default RemovableTag;