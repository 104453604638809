export enum MetricType {
  SPEND = 'SPEND',
  FREQUENCY = 'FREQUENCY',
  CUSTOM = 'CUSTOM',
  VIEW_ALL = 'VIEW_ALL',
};

export interface MetricSegment {
  boundName: string,
  lowerBound: string,
  upperBound: string,
};

export enum CustomMetricOption {
  LastTransactionDate = 'Last transaction date',
  LastVisitDate = 'Last visit date',
  LastInteractionDate = 'Last interaction date',
};

const { LastTransactionDate, LastVisitDate, LastInteractionDate } = CustomMetricOption;

export const CustomMetricOptions = [
  { displayText: LastTransactionDate, value: LastTransactionDate },
  { displayText: LastVisitDate, value: LastVisitDate },
  { displayText: LastInteractionDate, value: LastInteractionDate },
];

export enum MetricAttributeType {
  Dollar = 'Dollar',
  Frequency = 'Frequency',
  Date = 'Date',
}

export enum SpendOption {
  DoNotSegment = 'Do not segment',
  AverageAnnualSpend = 'Avg annual spend',
  AveragePurchaseValue = 'Avg purchase value',
  LifetimeSpend = 'Lifetime spend',
}

export enum FrequencyOption {
  DoNotSegment = 'Do not segment',
  AverageAnnualVisits = 'Avg annual visits',
  AverageAnnualPurchases = 'Avg annual purchases',
}

export type MetricOption = SpendOption | FrequencyOption | CustomMetricOption;

export interface Metric {
  metricName: string,
  type: MetricType,
  metricOption: MetricOption | null,
  customMetric: CustomMetricOption | null,
  bounds: MetricSegment[],
  skipped: boolean,
};

export interface Segment {
  segmentName: string,
  bounds: string[][],
  customiseMessage: string,
  description: string,
}

export interface UserSegments {
  segments: Segment[],
  metrics: Metric[],
};

export const DEFAULT_METRICS: Metric[] = [
  {
    metricName: 'Spend metric',
    type: MetricType.SPEND,
    metricOption: SpendOption.AverageAnnualSpend,
    customMetric: null,
    bounds: [],
    skipped: false,
  },
  {
    metricName: 'Frequency metric',
    type: MetricType.FREQUENCY,
    metricOption: FrequencyOption.AverageAnnualVisits,
    customMetric: null,
    bounds: [],
    skipped: false,
  },
];
