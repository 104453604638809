import './PreviewFooter.scss';

import React from 'react';

import TrashIconButton from '../../../common/buttons/composite/TrashIconButton';
import SubtleButton from '../../../common/buttons/SubtleButton';
import HyperpersonaliseBanner from './HypersonaliseBanner';
import OutlineButton from '../../../common/buttons/OutlineButton';

interface PreviewFooterProps {
  editing: boolean,
  setEditing: (editing: boolean) => void,
  save: () => void,
}

function PreviewFooter({ editing, setEditing, save } : Readonly<PreviewFooterProps>) {
  const deletePreview = () => {};
  const saveChanges = () => {
    save();
    setEditing(false);
  }
  const edit = () => setEditing(true);
  const editingButton = editing ? <SubtleButton content={'Save changes'} onClick={saveChanges} /> : <OutlineButton content={'Edit message'} onClick={edit} />
  return (
    <footer className={'preview-footer'}>
      <HyperpersonaliseBanner />
      <section className={'preview-footer-buttons'}>
        <TrashIconButton onClick={deletePreview} />
        {editingButton}
      </section>
    </footer>
  );
}

export default PreviewFooter;