import './SearchInput.scss';

import SEARCH_ICON from '../../../assets/icons/search.svg';

import PrimaryInput from './PrimaryInput';

export interface SearchInputProps {
  text: string,
  setText: ((value: string) => void),
  placeholder: string,
  id?: string,
  disabled?: boolean,
  additionalClassNames?: string[],
}

function SearchInput({ text, setText, placeholder, id = '', disabled = false, additionalClassNames = [] }: Readonly<SearchInputProps>) {
  const classNames = ['search-input', ...additionalClassNames];
  return (
    <section className={'search-container'}>
      <PrimaryInput
        id={id}
        additionalClassNames={classNames}
        placeholder={placeholder}
        text={text}
        setText={setText}
        disabled={disabled}
      />
      <img alt={'search-icon'} className={'search-input-icon'} src={SEARCH_ICON} />
    </section>
  );
}

export default SearchInput;