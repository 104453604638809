import './Tag.scss';

import { TagProps } from './TagConstants';

function Tag({ content, additionalClassNames = [] }: Readonly<TagProps>) {
  const classNames = ['tag', ...additionalClassNames].join(' ');
  return (
    <section className={classNames}>
      {content}
    </section>
  )
};

export default Tag;