export enum TrackingStatus {
  Searching = 'Searching',
  NotFound = 'No offers found',
  Found = 'Offers found',
};

const { Found } = TrackingStatus;

export enum CompetitorOfferStatus {
  Live = 'Live',
  Expired = 'Expired',
}

export type TrackedOffer = {
  competitorName: string,
  url: string,
  status: TrackingStatus,
};

export const DUMMY_TABLE_DATA: TrackedOffer[] = [
  {
    competitorName: 'Caci clinics',
    url: 'https://caci.co.nz/',
    status: Found,
  },
  {
    competitorName: 'Laser clinics aus',
    url: 'https://www.laserclinics.com.au/',
    status: Found,
  },
  {
    competitorName: 'Silk laser clinics',
    url: 'https://silklaser.com.au/',
    status: Found,
  },
];