import './EngagementResultsTable.scss';

import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import Table, { TableHeaderProps } from '../../common/tables/Table';
import { EngagementStatus } from '../EngagementsTypes';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import PaginationBar from '../../common/tables/PaginationBar';
import TextViewerModal from '../../common/modals/TextViewerModal';
import GreenTag from '../../common/tags/GreenTag';
import EngagementPerformance from '../common/EngagementPerformance';
import OutlineButton from '../../common/buttons/OutlineButton';
import { statusFilter } from '../helpers/EngagementHelpers';

import Tooltip from '../../common/tooltips/Tooltip';
import INFO_ICON from '../../people/segments/assets/information-icon.svg';

const { All, Active } = EngagementStatus;

const ENGAGEMENT_RESULTS_TABLE_HEADERS: TableHeaderProps[] = [
  { displayText: 'Name', key: 'name' },
  { displayText: 'Date active', key: 'date' },
  { displayText: 'Status', key: 'status' },
  { displayText: 'View variants', key: 'viewVariants' },
  {
    displayText: (
      <span className={'cell-text'}>
        <span className={'cell-content'}>
          {'Performance'}
        </span>
        <Tooltip content={'This score is calculated by WorkRex'}><img className={'info-icon'} src={INFO_ICON} alt={''} /></Tooltip>
      </span>
    ),
    key: 'performance'
  },
  { displayText: 'Total send', key: 'totalSend' },
  { displayText: 'Total read', key: 'totalRead' },
  { displayText: 'Total replies', key: 'totalReplies' },
  { displayText: 'Total opt-outs', key: 'totalOptOuts' },
  { displayText: 'Total visits', key: 'totalVisits' },
  { displayText: 'Revenue', key: 'revenue' },
  { displayText: 'Costs', key: 'costs' },
  { displayText: 'Export results', key: 'exportResults' },
];

const DUMMY_TABLE_DATA = [
  {
    name: 'Reactivate churned customers',
    date: '07/09/2024',
    status: Active,
    variants: 2,
    performance: 3,
    totalSend: 208,
    totalRead: 189,
    totalReplies: 58,
    totalOptOuts: 9,
    totalVisits: 38,
    revenue: '2,403',
    costs: '502',
  },
  {
    name: 'Provide upsell recommendations',
    date: '14/09/2024',
    status: Active,
    variants: 4,
    performance: 2,
    totalSend: 189,
    totalRead: 101,
    totalReplies: 22,
    totalOptOuts: 11,
    totalVisits: 7,
    revenue: '1,204',
    costs: '532',
  },
];

const ENGAGEMENT_RESULTS_MAX_ROWS = 5;

interface EngagementsTable {
  selectedStatus?: EngagementStatus,
};

function EngagementResultsTable({ selectedStatus = All } : Readonly<EngagementsTable>) {
  const navigate = useNavigate();
  const [cursor, setCursor] = useState(0);
  const [textToDisplay, setTextToDisplay] = useState<string | null>(null);
  const displayText = (text: string) => () => setTextToDisplay(text);
  const rows = DUMMY_TABLE_DATA
    .filter((({ status }) => statusFilter(selectedStatus, status)))
    .map(({ name, date, status, variants, performance, totalSend, totalRead, totalReplies, totalOptOuts, totalVisits, revenue, costs }) => {
      const nameNode = <section className={'two-cell center short-text'} onClick={displayText(name)}>{name}</section>
      const dateNode = <section className={'one-cell'}>{date}</section>;
      const statusNode = <GreenTag content={status} additionalClassNames={['tag-cell']} />;
      const viewVariants = () => {
        // TODO: Add in engagement that is being viewed to this pathway
        navigate('/engagements/results/variants');
      }
      const viewVariantsNode = <PrimaryButton content={`See all variants (${variants})`} onClick={viewVariants} additionalClassNames={['button-cell']} />;
      const performanceNode = <section className={'one-cell center'}><EngagementPerformance performance={performance} showTooltip={false} /></section>;
      const totalSendNode = <section className={'one-cell'}>{totalSend}</section>;
      const totalReadNode = <section className={'one-cell'}>{`${totalRead}/${totalSend}`}</section>;
      const totalRepliesNode = <section className={'one-cell'}>{`${totalReplies}/${totalSend}`}</section>;
      const totalOptOutsNode = <section className={'one-cell'}>{`${totalOptOuts}/${totalSend}`}</section>;
      const totalVisitsNode = <section className={'one-cell'}>{`${totalVisits}/${totalSend}`}</section>;
      const revenueNode = <section className={'one-cell'}>{`$${revenue}`}</section>;
      const costsNode = <section className={'one-cell'}>{`$${costs}`}</section>;
      const exportResultsNode = <OutlineButton content={'Export'} additionalClassNames={['half-button-cell']} />;
      return {
        cells: [
          { key: 'name', value: nameNode },
          { key: 'date', value: dateNode },
          { key: 'status', value: statusNode },
          { key: 'viewVariants', value: viewVariantsNode },
          { key: 'performance', value: performanceNode },
          { key: 'totalSend', value: totalSendNode },
          { key: 'totalRead', value: totalReadNode },
          { key: 'totalReplies', value: totalRepliesNode },
          { key: 'totalOptOuts', value: totalOptOutsNode },
          { key: 'totalVisits', value: totalVisitsNode },
          { key: 'revenue', value: revenueNode },
          { key: 'costs', value: costsNode },
          { key: 'exportResults', value: exportResultsNode },
        ]
      }
    });
  if (rows.length === 0) {
    return null;
  }
  const showingText = textToDisplay !== null;
  const closeTextToDisplayModal = () => setTextToDisplay(null);
  return (
    <>
      { showingText && <TextViewerModal text={textToDisplay} close={closeTextToDisplayModal} /> }
      <section className={'engagement-results-table-container'}>
        <Table
          additionalClassNames={['engagement-results-table']}
          headers={ENGAGEMENT_RESULTS_TABLE_HEADERS}
          rows={rows}
          cursor={cursor}
          maxRows={ENGAGEMENT_RESULTS_MAX_ROWS}
        />
        <section className={'pagination-bar-container'}>
          <PaginationBar
            cursor={cursor}
            setCursor={setCursor}
            maxRowsPerPage={ENGAGEMENT_RESULTS_MAX_ROWS}
            totalRows={DUMMY_TABLE_DATA.length}
          />
        </section>
      </section>
    </>
  )
}

export default EngagementResultsTable;

