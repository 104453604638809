import './AccordionButton.scss';

import { ReactNode } from 'react';

import Button from './Button';

interface AccordionButtonProps {
  additionalClassNames?: string[],
  buttonContent: ReactNode | string,
  children: ReactNode,
  onClick: () => void,
  open: boolean,
}

function AccordionButton({ additionalClassNames = [], buttonContent, children, onClick, open } : Readonly<AccordionButtonProps>) {
  const classNames = ['accordion',  open ? 'open' : '', ...additionalClassNames].join(' ');
  return (
    <section className={classNames}>
      <Button
        additionalClassNames={['accordion-button']}
        content={buttonContent}
        onClick={onClick}
      />
      <section className={'accordion-content'}>
        {children}
      </section>
    </section>
  )
}

export default AccordionButton;