import './RecipientRowInformation.scss';

import React from 'react';

import PERSON_ICON from '../../assets/person-icon.svg';

interface RecipientRowInformationProps {
  name: string,
  emailAddress: string,
  mobileNumber: string,
};

function RecipientRowInformation({ name, emailAddress, mobileNumber } : Readonly<RecipientRowInformationProps>) {
  return (
    <section className={'recipient-row-information'}>
      <img className={'person-icon'} src={PERSON_ICON} alt={'person'} /> 
      <span className={'name'}>{name}</span>
      <span className={'email-address'}>{emailAddress}</span>
      <span className={'phone-number'}>{mobileNumber}</span>
    </section>
  );
}

export default RecipientRowInformation;