import './SelectRecipientsPage.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router';

import PrimaryButton from '../../common/buttons/PrimaryButton';
import SelectRecipientsTable from '../tables/SelectRecipientsTable';
import OutlineButton from '../../common/buttons/OutlineButton';
import { useLocalStorage, LocalStorageKey } from '../../../hooks/LocalStorageHooks';
import { Engagement, Variant } from '../EngagementsTypes';
import useAuth from '../../../context/AuthContext';
import { postSaveDraftEngagement } from '../../../apis/EngagementApi';
import { User } from '../../../types/UserTypes';
import SubtleButton from '../../common/buttons/SubtleButton';
import { getEmptyEngagement } from '../helpers/EngagementHelpers';

const { CreateEngagementKey } = LocalStorageKey;

function SelectRecipientsPage() {
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const emptyEngagement = getEmptyEngagement(user?.firstName, user?.business?.name);
  const [loadingDraftButton, setLoadingDraftButton] = useState(false);
  const [engagement, setEngagement, clearEngagement] = useLocalStorage<Engagement>(CreateEngagementKey, emptyEngagement);
  const { variants } = engagement;
  const setVariants = (variants: Variant[]) => setEngagement({ ...engagement, variants });
  const next = () => {
    navigate('/engagements/delivery');
  }
  const saveDraft = async () => {
    setLoadingDraftButton(true);
    const { engagements } = await postSaveDraftEngagement(engagement);
    setUser({ ...user as User, engagements });
    clearEngagement();
    setLoadingDraftButton(false);
    navigate('/engagements');
  };
  const back = () => {
    navigate('/engagements/create');
  }
  const noRecipientsSelected = variants.flatMap(({ recipients }) => recipients).length === 0;
  return (
    <article className={'select-recipients-page'}>
      <section className={'page'}>
        <header className={'select-recipients-page-header'}>
          <h2 className={'title'}>{'Select your recipients'}</h2>
          <h3 className={'subtitle'}>{'Choose the recipients you want each of your engagement variations to be sent out to'}</h3>
        </header>
        <section className={'select-recipients-page-content'}>
          <SelectRecipientsTable variants={variants} setVariants={setVariants} />
        </section>
      </section>
      <footer className={'footer'}>
        <section className={'footer-left'}>
          <OutlineButton content={'Back'} onClick={back} />
          <SubtleButton content={'Save as draft'} onClick={saveDraft} loading={loadingDraftButton} />
          <PrimaryButton content={'Next'} onClick={next} disabled={noRecipientsSelected} />
        </section>
        <section className={'footer-right'}>{'2/4 steps'}</section>
      </footer>
    </article>
  );
}

export default SelectRecipientsPage;