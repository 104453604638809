import './AIPromptModal.scss';

import { useState, useRef } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';

import Modal from '../../common/modals/Modal';
import OutlineButton from '../../common/buttons/OutlineButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import OutlineTag from '../../common/tags/OutlineTag';
import CrossIconButton from '../../common/buttons/composite/CrossIconButton';
import MultiselectDropdown from '../../common/dropdowns/MultiselectDropdown';
import { getPersonalizationText, PersonalizationVariable, PersonalizationVariableOptions } from '../helpers/AIPromptHelpers';

interface AIPromptModalProps {
  prompt: string,
  setPrompt: (prompt: string) => void,
  closeModal: () => void,
  savePrompt: (prompt: string, personalizationVariables: PersonalizationVariable[]) => void;
  initialSelectedVariables: PersonalizationVariable[],
};

// TODO: Migrate to using Tip Tap

function AIPromptModal({ prompt, setPrompt, closeModal, savePrompt, initialSelectedVariables }: AIPromptModalProps) {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [selectedVariables, setSelectedVariables] = useState<PersonalizationVariable[]>(initialSelectedVariables);
  const promptRef = useRef(prompt);
  const handlePromptChange = (event: ContentEditableEvent) => {
    const prompt = event.target.value;
    const customPrompt = prompt;
    promptRef.current = customPrompt;
  };
  const edit = () => setEditing(true);
  const save = () => {
    setPrompt(promptRef.current);
    setEditing(false);
  };
  const applyChanges = async () => {
    setLoading(true);
    await savePrompt(prompt, selectedVariables);
    setLoading(false);
    // Re-generate templates
    closeModal();
  }
  const content = editing
    ? <ContentEditable html={promptRef.current} onChange={handlePromptChange} className={'prompt-content'} />
    : <div className={'prompt-content'} dangerouslySetInnerHTML={{ __html: promptRef.current }} />
  const editButton = editing
    ? <PrimaryButton onClick={save} content={'Save changes'} />
    : <OutlineButton onClick={edit} content={'Edit prompt'} />;
  
  const deletePersonalizationVariable = (variable: PersonalizationVariable) => () => {
    setSelectedVariables(selectedVariables.filter((selectedVariable) => selectedVariable !== variable));
  }
  const tags = selectedVariables.map((variable) => <OutlineTag key={variable} content={<>{variable}<CrossIconButton onClick={deletePersonalizationVariable(variable)} /></>} />);
  const personalizationText = getPersonalizationText(selectedVariables);
  return (
    <Modal additionalClassNames={['ai-prompt-modal']}>
      <section className={'modal-container'}>
        <header className={'header'}>
          <h5 className={'title'}>{'Your AI prompt'}</h5>
          <p className={'subtitle'}>{'When you make a change here, it will apply across all variations.'}</p>
        </header>
        <section className={'ai-content-container'}>
          <section className={'prompt-container'}>
            {content}
            <section className={'personalization-variables'} dangerouslySetInnerHTML={{ __html: personalizationText }} />
          </section>
          <section className={'edit-button-container'}>
            {editButton}
          </section>
        </section>
        <header className={'header'}>
          <h5 className={'title'}>{'Hyper-personalisation'}</h5>
          <p className={'subtitle'}>{'Edit the variables you’d like to hyper-personalise your message with it. '}</p>
        </header>
        <section className={'hyperpersonalisation'}>
          <MultiselectDropdown defaultDisplayText={'Select variables'} options={PersonalizationVariableOptions} select={setSelectedVariables} selected={selectedVariables} />
          {tags}
        </section>
        <footer className={'modal-footer-buttons'}>
          <OutlineButton content={'Cancel'} onClick={closeModal} />
          <PrimaryButton content={'Apply changes'} onClick={applyChanges} loading={loading} />
        </footer>
      </section>
    </Modal>
  )
}

export default AIPromptModal;