import { EngagementStatus } from '../EngagementsTypes';
import { EMPTY_ENGAGEMENT } from '../EngagementsConstants';
import { getDefaultSmsPrompt } from './AIPromptHelpers';

const { All, Archived } = EngagementStatus;

export function statusFilter(selectedStatus: EngagementStatus, status: EngagementStatus) {
  if (selectedStatus === All) {
    return status !== Archived;
  }
  return status === selectedStatus;
};

export function getEmptyEngagement(userFirstName?: string, userBusinessName?: string) {
  const firstName = userFirstName ? userFirstName : '[Name]';
  const businessName = userBusinessName ? userBusinessName : '[Business]';
  return {
    ...EMPTY_ENGAGEMENT,
    date: Date.now(),
    prompt: getDefaultSmsPrompt(),
    signature: `Sent by ${firstName} from ${businessName}`
  }
};