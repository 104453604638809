import SubtleButton from '../SubtleButton';

import DARK_PLUS_ICON from './assets/black-plus-icon.svg';

import { ButtonProps } from '../ButtonConstants';

function SubtlePlusButton({ additionalClassNames = [], onClick, disabled, content, loading, loadingContent } : Readonly<ButtonProps>) {
  const classNames = ['subtle-plus-button', ...additionalClassNames];
  const buttonContent = (
    <span className={'button-content'}>
      <img className={'icon'} src={DARK_PLUS_ICON} alt={''} />
      {content}
    </span>
  )
  return <SubtleButton
    additionalClassNames={classNames}
    onClick={onClick}
    content={buttonContent}
    disabled={disabled}
    loading={loading}
    loadingContent={loadingContent}
  />
}

export default SubtlePlusButton;