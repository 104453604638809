import './GreyButton.scss';

import OutlineButton from '../OutlineButton';
import { ButtonProps } from '../ButtonConstants';

function GreyButton({ additionalClassNames = [], onClick, content, disabled, loading, loadingContent } : Readonly<ButtonProps>) {
  const classNames = ['grey-button', ...additionalClassNames];
  return <OutlineButton
    additionalClassNames={classNames}
    onClick={onClick}
    content={content}
    disabled={disabled}
    loading={loading}
    loadingContent={loadingContent}
  />
}

export default GreyButton;