import { useEffect, RefObject } from 'react';

/**
 * Hook to set up a mousedown/touchstart listener to fire a callback when user mouse clicks/touches outside a component.
 * @param {node} ref - component that is used to define "inside". Outside refers to everything outside this component. 
 * @param {*} callback - callback fired when a mouse click or touch is fired from outside the component.
 */
export const useOutsideClick = (ref: RefObject<HTMLInputElement>, callback: any = () => {}) => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };  
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [ref, callback]);
};