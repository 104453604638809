import './VariantResultsPage.scss';

import React from 'react';
import { useNavigate } from 'react-router';

import VariantResultsTable from '../tables/VariantResultsTable';
import OutlineButton from '../../common/buttons/OutlineButton';

function VariantResultsPage() {
  const navigate = useNavigate();
  const back = () => navigate('/engagements/results');
  return (
    <article className={'variant-results-page'}>
      <section className={'variant-results-page-content'}>
        <header className={'variant-results-page-header'}>
          <h2 className={'title'}>{'Engagement variants'}</h2>
          <h3 className={'subtitle'}>{'These are the results and impacts of all variants for this engagement'}</h3>
        </header>
        <VariantResultsTable />
      </section>
      <footer className={'variant-results-page-footer'}>
        <OutlineButton content={'Back'} onClick={back} />
      </footer>
    </article>
  );
}

export default VariantResultsPage;