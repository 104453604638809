import { useState } from 'react';

import LoginInput from '../common/LoginInput';
import { postSubmitEmailLogin } from '../../../apis/LoginApi';

interface LoginInputContainerProps {
  loginDetail: string,
  setLoginDetail: React.Dispatch<React.SetStateAction<string>>,
  setShowingVerifyPage: React.Dispatch<React.SetStateAction<boolean>>,
}

function EmailLoginInput({ loginDetail, setLoginDetail, setShowingVerifyPage } : LoginInputContainerProps) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const hasError = error.length > 0;
  const hasInput = loginDetail.length > 0;

  const proceedToVerify = async () => {
    if (loading) {
      return;
    }
    if (loginDetail.length < 8) {
      setError('Please enter a valid Email Address.')
      setLoading(false);
      return;
    }
    const success = await postSubmitEmailLogin(loginDetail);
    if (!success) {
      setError('Unable to find an account.');
      setLoading(false);
      return;
    }
    setShowingVerifyPage(true);
  }

  return (
    <LoginInput error={error} inputLabel={'Email Address'} onSubmit={proceedToVerify}>
      <input
        className={`login-input ${hasInput ? 'has-input' : ''} ${hasError ? 'has-error' : ''}`}
        onChange={({ target }) => setLoginDetail(target.value)}
        type={'text'}
        value={loginDetail} />
    </LoginInput>
  )
}

export default EmailLoginInput;